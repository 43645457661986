import React, {useEffect, useState} from "react";
import {server} from "../../const";
import {toast} from "react-toastify";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretUp} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import {displayBadgePrefixes, extractCodesPrefixes, getOrderStatusBadge} from "../utils";
import OrderModal from "../OrderModal/OrderModal";

export default function Search() {

    const token = localStorage.getItem('token')

    const [search, setSearch] = useState('')
    const [orders, setOrders] = useState([])

    const [currentOrder, setCurrentOrder] = useState({})
    const [showModal, setShowModal] = useState(false)

    const getOrders = () => {
        fetch(server + '/client/orders' + (search.length > 0 ? '?search=' + search : ''), {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            if (json) {
                setOrders(json['orders'])
            } else {
                toast.warning('A aparut o eroare!');
            }

        }).catch(e => {
            //toast.warning('A aparut o eroare!');
        });
    }


    useEffect(() => {
        getOrders()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search])

    return (
        <>

            <input type="text"
                   className="form-control input-search"
                   placeholder="Caută comandă"
                   value={search}
                   onChange={(e) => {
                       setSearch(e.target.value)
                   }
                   }
            />

            {
                orders.length > 0 ?
                    <>
                        <div className={"search-overlay"} onClick={() => setSearch('')}></div>

                    <div className={"search-results-cont"}>
                        <table className="table table-hover">
                            <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Client</th>
                                <th scope="col">Telefon</th>
                                <th scope="col">Adresa</th>
                                <th scope="col">Ridicare <FontAwesomeIcon icon={faCaretUp}/></th>
                                <th scope="col">Livrare</th>
                                <th scope="col">Covoare</th>
                                <th scope="col">Etichetă</th>
                                <th scope="col">Status</th>

                            </tr>
                            </thead>
                            <tbody>
                            {
                                orders ? orders.map((order, i) => (
                                        <tr key={i} className={"tr-link"}
                                            onClick={() => {

                                                setCurrentOrder(order)
                                                setShowModal(true)
                                            }}
                                        >
                                            <th scope="row">{order.number}</th>
                                            <td>{order.name}</td>
                                            <td>{order.phone}</td>
                                            <td>{order.address}</td>
                                            <td>{order.datePickup ? moment(order.datePickup).format("DD/MM/YYYY HH:mm") : ''}</td>
                                            <td>{order.dateDelivery ? moment(order.dateDelivery).format("DD/MM/YYYY HH:mm") : ''}</td>
                                            <td>{order.itemsCount ? order.itemsCount : ''}</td>
                                            <td>{order.status >= 2 ? displayBadgePrefixes(extractCodesPrefixes(order.carpets)) : ''}</td>
                                            <td>{getOrderStatusBadge(order.status)}</td>
                                        </tr>
                                    ))
                                    : null
                            }

                            </tbody>
                        </table>
                    </div>
                    </>
                    : null
            }

            <OrderModal
                currentOrder={currentOrder}
                showModal={showModal}
                setShowModal={setShowModal}
                />
        </>
    );
}
