import ReactToPrint from "react-to-print";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPrint} from "@fortawesome/free-solid-svg-icons";
import {useRef} from "react";
import {BonPrint} from "./bonPrint";

export default function BonPrintComp({order, company}) {

    const bonPrintRef = useRef()

    return (
        <>
            <ReactToPrint
                trigger={() => {
                    return  <button
                        type={"button"}
                        className={"btn btn-secondary w-100"}>
                        <FontAwesomeIcon icon={faPrint}/> Bon comandă
                    </button>;
                }}
                content={() => bonPrintRef.current}
            />

            <div style={{display:'none'}}>
                <BonPrint
                    ref={bonPrintRef}
                    order={order}
                    company={company}
                />
            </div>
        </>
    );
}