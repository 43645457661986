import {useEffect, useState} from "react";
import {server} from "../../const"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCaretDown, faCaretUp, faSort} from '@fortawesome/free-solid-svg-icons'
import {toast} from "react-toastify";
import moment from "moment";
import {
    displayBadgePrefixes,
    extractCodesPrefixes,
    getOrderStatusBadge,
    truncate
} from "../../components/utils"
import LoadingComp from "../../components/loaders/LoadingComp";
import OrderModal from "../../components/OrderModal/OrderModal";

export default function OrdersNotPaid() {

    //const colorOrderSource1 = localStorage.getItem('color_order_source_1')

    const token = localStorage.getItem('token')
    const [orders, setOrders] = useState([])
    const [page, setPage] = useState(1)
    const [totalOrders, setTotalOrders] = useState(0)
    const [sortParam, setSortParam] = useState('datePickup')
    const [sortOrder, setSortOrder] = useState('ascending')

    const [loading, setLoading] = useState(true)

    const [modalIsOpen, setIsOpen] = useState(false);
    const [currentOrder, setCurrentOrder] = useState({})

    const [search, setSearch] = useState('')

    const getOrders = (requestedPage) => {
        fetch(server + '/client/orders?type=not-paid&sortParam='+ sortParam +'&sortOrder='+ sortOrder + (search.length > 0 ? '&search='+search : '') + '&page='+requestedPage, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            if (json) {
                if(requestedPage > 1) {
                    setOrders([...orders, ...json['orders']])
                } else {
                    setOrders(json['orders'])
                }
                setTotalOrders(json['total'])
                setPage(requestedPage)
                setLoading(false)
            } else {
                toast.warning('A aparut o eroare!');
            }

        }).catch(e => {
            //toast.warning('A aparut o eroare!');
        });
    }

    useEffect(() => {
        getOrders(1)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        getOrders(1)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, sortOrder, sortParam])

    return (
        <div className={"container"}>

            {loading ?
                <LoadingComp/>
                :
                <>
                    <div className="float-end">
                        <input type="text"
                               className="form-control input-search"
                               placeholder="Caută comandă"
                               value={search}
                               onChange={(e) => {
                                   setSearch(e.target.value)
                               }
                               }
                        />
                    </div>

                    <h2 className={"list-page-title"}>Comenzi neîncasate</h2>

                    <div className={"row list-page-table"}>
                        <div className={"col-12"}>
                            <div className={"table-responsive"}>
                                <table className="table table-hover">
                                    <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Client</th>
                                        <th scope="col">Telefon</th>
                                        <th scope="col">Eticheta</th>
                                        <th scope="col">Adresa</th>
                                        <th scope="col">
                                            {
                                                sortParam === 'datePickup' ?
                                                    <div className={"cursor-pointer"} onClick={() => {
                                                        setSortParam('datePickup')
                                                        if(sortOrder === 'ascending') {
                                                            setSortOrder('descending')
                                                        } else {
                                                            setSortOrder('ascending')
                                                        }
                                                    }
                                                    }>
                                                    <span className={"text-decoration-underline"}>Ridicare {
                                                        sortOrder === 'ascending' ?
                                                            <FontAwesomeIcon icon={faCaretUp}/>
                                                            :
                                                            <FontAwesomeIcon icon={faCaretDown}/>
                                                    }
                                                    </span>
                                                    </div>
                                                    :
                                                    <div className={"cursor-pointer"} onClick={() => setSortParam('datePickup')}>
                                                        <span>Ridicare <FontAwesomeIcon icon={faSort}/></span>
                                                    </div>
                                            }
                                        </th>
                                        <th scope="col" style={{minWidth:'85px'}}>
                                            {
                                                sortParam === 'dateDelivery' ?
                                                    <div className={"cursor-pointer"} onClick={() => {
                                                        setSortParam('dateDelivery')
                                                        if(sortOrder === 'ascending') {
                                                            setSortOrder('descending')
                                                        } else {
                                                            setSortOrder('ascending')
                                                        }
                                                    }
                                                    }>
                                                    <span className={"text-decoration-underline"}>Livrare {
                                                        sortOrder === 'ascending' ?
                                                            <FontAwesomeIcon icon={faCaretUp}/>
                                                            :
                                                            <FontAwesomeIcon icon={faCaretDown}/>
                                                    }
                                                    </span>
                                                    </div>
                                                    :
                                                    <div className={"cursor-pointer"} onClick={() => setSortParam('dateDelivery')}>
                                                        <span>Livrare <FontAwesomeIcon icon={faSort}/></span>
                                                    </div>
                                            }
                                        </th>
                                        <th scope="col">Covoare</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Mențiuni</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        orders ? orders.map((order, i) => (
                                                <tr key={i}
                                                    className={"tr-link "}
                                                    onClick={() => {
                                                        setCurrentOrder(order)
                                                        setIsOpen(true)
                                                    }}
                                                >
                                                    <th scope="row">{order.number}</th>
                                                    <td>{order.name}</td>
                                                    <td>{order.phone}</td>
                                                    <td>{displayBadgePrefixes(extractCodesPrefixes(order.carpets))}</td>
                                                    <td>{order.address}</td>
                                                    <td>{order.datePickup ? moment(order.datePickup).format("DD/MM/YYYY HH:mm") : ''}</td>
                                                    <td>{order.dateDelivery ? moment(order.dateDelivery).format("DD/MM/YYYY") + ' ' + order.deliveryInterval : ''}</td>
                                                    <td>{order.itemsCount ? order.itemsCount : ''}</td>
                                                    <td>{getOrderStatusBadge(order.status)}
                                                        {
                                                            order.delayedPayment ?
                                                                <span className={"badge bg-warning"}>Amânată</span>
                                                                :null
                                                        }
                                                    </td>
                                                    <td>{truncate(order.notes, 30)}</td>
                                                </tr>
                                            ))
                                            : null
                                    }

                                    </tbody>
                                </table>

                                {
                                    orders.length < totalOrders ?
                                        <div className={"mb-5 w-100 text-center"}>
                                            <button type={"button"} className={"btn btn-secondary"} onClick={() => getOrders(page+1)}>Vezi mai multe</button>
                                        </div>
                                        :null
                                }
                            </div>
                        </div>
                    </div>

                </>
            }

            <OrderModal
                currentOrder={currentOrder}
                showModal={modalIsOpen}
                setShowModal={setIsOpen}
                onFinish={getOrders}
            />

        </div>
    );
}
