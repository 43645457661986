import {NavLink} from "react-router-dom";
import {useState} from "react";

export default function HeaderAdmin({children}) {

    const [menuVisible, setMenuVisible] = useState(false)
    const logout = () => {
        localStorage.clear()
        window.location.href = "/"

    }
    return (
        <div>

            <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
                <div className="container-fluid">

                    <button className="navbar-toggler float-end" type="button" data-toggle="collapse" onClick={() => setMenuVisible(!menuVisible)}
                            data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarColor01" style={{display: (menuVisible ? 'block' : 'none')}}
                         onClick={() => setMenuVisible(false)}>
                        <ul className="navbar-nav me-auto">
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/" exact>Dashboard</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/clients">Clienti</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/add-client">Adauga client</NavLink>
                            </li>
                            <li className="nav-item">
                                <div className="nav-link" onClick={() =>logout()}>Ieșire</div>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <div>
                {children}
            </div>
        </div>
    );
}



