import React, {useState} from "react";
import {customModalStyles, server} from "../../const"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTimes} from '@fortawesome/free-solid-svg-icons'
import {getOrderStatusBadge, roundNumber} from "../utils"
import Modal from "react-modal";
import DeleteOrderButton from "../DeleteOrderButton/DeleteOrderButton";
import {toast} from "react-toastify";

export default function OrderModalInvoiced({currentOrder, showModal, setShowModal, onFinish}) {

    const token = localStorage.getItem('token')
    const [modalBtnLoading, setModalBtnLoading] = useState(false)

    const markPayment = async (type) => {

        setModalBtnLoading(true)
        fetch(server + '/client/orders/' + currentOrder._id, {
            method: 'PUT',
            body: JSON.stringify({
                status: 8,
                paymentMethod: type
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            if (json) {
                onFinish()
                setShowModal(false)
                setModalBtnLoading(false)
            } else {
                toast.warning('A aparut o eroare!');
            }

        }).catch(e => {
            //toast.warning('A aparut o eroare!');
        });
    }

    const delayPayment = async () => {

        setModalBtnLoading(true)
        fetch(server + '/client/orders/' + currentOrder._id, {
            method: 'PUT',
            body: JSON.stringify({
                status: 7,
                delayedPayment: true
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            if (json) {
                onFinish()
                setShowModal(false)
                setModalBtnLoading(false)
            } else {
                toast.warning('A aparut o eroare!');
            }

        }).catch(e => {
            //toast.warning('A aparut o eroare!');
        });
    }

    return (<>
            <Modal
                isOpen={showModal}
                onAfterOpen={() => {
                }}
                onRequestClose={() => setShowModal(false)}
                shouldCloseOnOverlayClick={false}
                style={customModalStyles}
                contentLabel="Example Modal"
            >
                <button className={"btn-close-modal"} onClick={() => setShowModal(false)}>
                    <FontAwesomeIcon icon={faTimes}/>
                </button>

                <h2>Comandă neîncasată</h2>

                <div className={"row"}>
                    <div className={"col"}>
                        <strong>Client</strong><br/>
                        {currentOrder.name}<br/>
                        {currentOrder.phone}
                    </div>
                    <div className={"col"}>
                        <strong>Adresa</strong><br/>
                        {currentOrder.address}
                    </div>
                    <div className={"col"}>
                        {getOrderStatusBadge(currentOrder.status)}
                    </div>
                    {
                        currentOrder.notes ?
                            <div className={"col-12 mt-2"}>
                                <strong>Mențiuni</strong><br/>
                                {currentOrder.notes}
                            </div>
                            :null
                    }
                </div>

                <div className={"form-group mt-4"}>
                    <strong>Dimensiuni</strong>
                </div>

                <div className={"row border-bottom mb-3 pb-1"}>
                    <div className={"col-1"}>
                        #
                    </div>
                    <div className={"col"}>
                        Cod
                    </div>
                    <div className={"col"}>
                        Lungime
                    </div>
                    <div className={"col"}>
                        Lățime
                    </div>
                </div>
                {
                    currentOrder && currentOrder.carpets && currentOrder.carpets.length > 0 && currentOrder.carpets.map((carpet, i) => (
                        <div className={"row mb-1"} key={i}>
                            <div className={"col-1"}>
                                {i+1}.
                            </div>
                            <div className={"col"}>
                                {carpet.code}
                            </div>
                            <div className={"col"}>
                                {carpet.width}
                            </div>
                            <div className={"col"}>
                                {carpet.height}
                            </div>
                        </div>
                    ))
                }

                <div className={"form-group mt-4"}>
                    <strong>Preț</strong>
                </div>

                {
                    currentOrder.services && currentOrder.services.length > 0 ?
                        <>
                            <div className={"form-group mt-4"}>
                                <strong>Servicii adiționale</strong>
                            </div>


                            {
                                currentOrder.services.map((service, i) => (
                                    <div className={"row"} key={i}>
                                        <div className={"col-6"}>
                                            {service.title}
                                        </div>
                                        <div className={"col-3"}>
                                            {service.quantity} x {service.price} RON
                                        </div>
                                        <div className={"col-3 text-end"}>
                                            {roundNumber(service.quantity * service.price)} RON
                                        </div>
                                    </div>
                                ))
                            }
                            <br/>
                        </>
                        :null
                }

                <div className={"row"}>
                    <div className={"col-6"}>
                        Total măsurători
                    </div>
                    <div className={"col-6 text-end"}>
                        {roundNumber(currentOrder.total - currentOrder.transport - (currentOrder.servicesPrice ? currentOrder.servicesPrice : 0))} RON
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-6"}>
                        Transport
                    </div>
                    <div className={"col-6 text-end"}>
                        {currentOrder.transport} RON
                    </div>
                </div>

                <div className={"row mb-2"}>
                    <div className={"col-6"}>
                        Avans
                    </div>
                    <div className={"col-6 text-end"}>
                        {roundNumber(currentOrder.advance ? - currentOrder.advance : 0)} RON
                    </div>
                </div>

                <div className={"row border-top pt-2"}>
                    <div className={"col-6"}>
                        <strong>Total</strong>
                    </div>
                    <div className={"col-6 text-end"}>
                        {roundNumber(currentOrder.total - currentOrder.advance)} RON
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-12 mt-4"}>
                        <strong>Înregistrare plată</strong>
                    </div>
                    <div className={"col-12 col-md"}>
                        <button type={"button"} className={"btn btn-success w-100 mt-3"}
                                disabled={modalBtnLoading}
                                onClick={() => markPayment(0)}>
                            Cash
                        </button>
                    </div>
                    <div className={"col-12 col-md"}>
                        <button type={"button"} className={"btn btn-info w-100 mt-3"}
                                disabled={modalBtnLoading}
                                onClick={() => markPayment(1)}>
                            POS
                        </button>
                    </div>
                    <div className={"col-12 col-md"}>
                        <button type={"button"} className={"btn btn-secondary w-100 mt-3"}
                                disabled={modalBtnLoading}
                                onClick={() => markPayment(2)}>
                            O.P.
                        </button>
                    </div>
                    <div className={"col-12 col-md"}>
                        <button type={"button"} className={"btn btn-danger w-100 mt-3"}
                                disabled={modalBtnLoading}
                                onClick={() => delayPayment()}>
                            Amânată
                        </button>
                    </div>
                </div>



                <DeleteOrderButton
                    order={currentOrder}
                    afterDelete={() => {
                        setShowModal(false)
                        onFinish()
                    }}
                />

            </Modal>

        </>
    );
}
