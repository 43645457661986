import React from "react";
import DryRoomComp from "../../components/DryRoom/DryRoomComp";

export default function DryRoom() {


    return (
        <div className={"container pb-5"}>

            <h2 className={"list-page-title"}>Uscare</h2>

            <DryRoomComp/>

        </div>
    );
}
