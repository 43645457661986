import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {server} from "../../const";
import {toast} from "react-toastify";
import {useState} from "react";
import {confirmAlert} from "react-confirm-alert";

export default function DeleteOrderButton({order, afterDelete}) {

    const token = localStorage.getItem('token')
    const role = localStorage.getItem('user_role')

    const [loading, setLoading] = useState(false)

    const deleteOrder = async () => {
        setLoading(true)
        fetch(server + '/client/orders/'+order._id, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            if (json) {
                afterDelete()
            } else {
                toast.warning('A aparut o eroare!');
            }

        }).catch(e => {
            //toast.warning('A aparut o eroare!');
        });
    }

    return (

            role === 'client' ?

            <div className={"mt-4"}>
                <button
                    type={"button"}
                    onClick={() =>  confirmAlert({
                        title: 'Confirmare ștergere',
                        message: 'Sunteți sigur că doriți să ștergeți această comandă?',
                        buttons: [
                            {
                                label: 'Da',
                                onClick: () => deleteOrder()
                            },
                            {
                                label: 'Nu',
                                onClick: () => {}
                            }
                        ]
                    })}
                    loading={loading.toString()}
                    disabled={loading}
                    className={"btn btn-danger "}>
                    <FontAwesomeIcon icon={faTrash}/> Șterge comanda
                </button>
            </div>
            : null

    );
}
