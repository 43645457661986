import React, {useEffect, useState} from "react";
import {customModalStyles, server} from "../../const"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTimes, faEnvelope} from '@fortawesome/free-solid-svg-icons'
import {getCarpetPrice, getMobileOperatingSystem, getOrderStatusBadge, roundNumber} from "../utils"
import Modal from "react-modal";
import DeleteOrderButton from "../DeleteOrderButton/DeleteOrderButton";
import SmallLoadingComp from "../loaders/SmallLoadingComp";
import {toast} from "react-toastify";
import moment from "moment/moment";


export default function OrderModalCompleted({currentOrder, showModal, setShowModal, onFinish}) {

    const token = localStorage.getItem('token')
    const [company, setCompany] = useState({})
    const [modalBtnLoading, setModalBtnLoading] = useState(false)

    const [secondModalIsOpen, setSecondModalIsOpen] = useState(false)
    const [deliveryDate, setDeliveryDate] = useState(currentOrder.dateDelivery ? moment(new Date(currentOrder.dateDelivery)).format('YYYY-MM-DD') : '')
    const [deliveryInterval, setDeliveryInterval] = useState(currentOrder.deliveryInterval ? currentOrder.deliveryInterval : '08:00 - 10:00')


    const getClient = () => {
        fetch(server + '/client/company', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            if (json) {
                setCompany(json)
            } else {
                toast.warning('A aparut o eroare!');
            }

        }).catch(e => {
            //toast.warning('A aparut o eroare!');
        });

    }

    useEffect(() => {
        getClient()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const moveToInvoiced = async () => {
        setModalBtnLoading(true)
        fetch(server + '/client/orders/' + currentOrder._id, {
            method: 'PUT',
            body: JSON.stringify({
                status: 7,
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            if (json) {
                onFinish()
                setShowModal(false)
                setModalBtnLoading(false)
            } else {
                toast.warning('A aparut o eroare!');
            }

        }).catch(e => {
            //toast.warning('A aparut o eroare!');
        });
    }
    const markPayment = async (type) => {

        setModalBtnLoading(true)
        fetch(server + '/client/orders/' + currentOrder._id, {
            method: 'PUT',
            body: JSON.stringify({
                status: 8,
                paymentMethod: type
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            if (json) {
                onFinish()
                setShowModal(false)
                setModalBtnLoading(false)
            } else {
                toast.warning('A aparut o eroare!');
            }

        }).catch(e => {
            //toast.warning('A aparut o eroare!');
        });
    }
    const delayPayment = async () => {

        setModalBtnLoading(true)
        fetch(server + '/client/orders/' + currentOrder._id, {
            method: 'PUT',
            body: JSON.stringify({
                status: 7,
                delayedPayment: true
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            if (json) {
                onFinish()
                setShowModal(false)
                setModalBtnLoading(false)
            } else {
                toast.warning('A aparut o eroare!');
            }

        }).catch(e => {
            //toast.warning('A aparut o eroare!');
        });
    }

    return (<>
            <Modal
                isOpen={showModal}
                onAfterOpen={() => {
                }}
                onRequestClose={() => setShowModal(false)}
                shouldCloseOnOverlayClick={false}
                style={customModalStyles}
                contentLabel="Example Modal"
            >
                <button className={"btn-close-modal"} onClick={() => setShowModal(false)}>
                    <FontAwesomeIcon icon={faTimes}/>
                </button>

                <h2>Comandă finalizată</h2>

                <div className={"row"}>
                    <div className={"col"}>
                        <strong>Client</strong><br/>
                        {currentOrder.name}<br/>
                        {currentOrder.phone}
                    </div>
                    <div className={"col"}>
                        <strong>Adresa</strong><br/>
                        {currentOrder.address}
                    </div>
                    <div className={"col"}>
                        {getOrderStatusBadge(currentOrder.status)}
                    </div>
                    {
                        currentOrder.notes ?
                            <div className={"col-12 mt-2"}>
                                <strong>Mențiuni</strong><br/>
                                {currentOrder.notes}
                            </div>
                            :null
                    }
                </div>

                <div className={"form-group mt-4"}>
                    <strong>Dimensiuni</strong>
                </div>

                <div className={"row border-bottom mb-3 pb-1"}>
                    <div className={"col-1"}>
                        #
                    </div>
                    <div className={"col"}>
                        Cod
                    </div>
                    <div className={"col"}>
                        Preț/mp
                    </div>
                    <div className={"col"}>
                        Lungime
                    </div>
                    <div className={"col"}>
                        Lățime
                    </div>
                </div>
                {
                    currentOrder && currentOrder.carpets && currentOrder.carpets.length > 0 && currentOrder.carpets.map((carpet, i) => (
                        <div className={"row mb-1"} key={i}>
                            <div className={"col-1"}>
                                {i+1}.
                            </div>
                            <div className={"col"}>
                                {carpet.code}
                            </div>
                            <div className={"col"}>
                                {carpet.pricing && carpet.pricing.price ?
                                    carpet.pricing.title +' ('+carpet.pricing.price+' lei)' :

                                    getCarpetPrice(carpet, company)
                                } RON
                            </div>
                            <div className={"col"}>
                                {carpet.width}
                            </div>
                            <div className={"col"}>
                                {carpet.height}
                            </div>
                        </div>
                    ))
                }

                <div className={"form-group mt-4"}>
                    <strong>Preț</strong>
                </div>

                {
                    currentOrder.services && currentOrder.services.length > 0 ?
                        <>
                            <div className={"form-group mt-4"}>
                                <strong>Servicii adiționale</strong>
                            </div>


                            {
                                currentOrder.services.map((service, i) => (
                                    <div className={"row"} key={i}>
                                        <div className={"col-6"}>
                                            {service.title}
                                        </div>
                                        <div className={"col-3"}>
                                            {service.quantity} x {service.price} RON
                                        </div>
                                        <div className={"col-3 text-end"}>
                                            {roundNumber(service.quantity * service.price)} RON
                                        </div>
                                    </div>
                                ))
                            }
                            <br/>
                        </>
                        :null
                }

                <div className={"row"}>
                    <div className={"col-6"}>
                        Total măsurători
                    </div>
                    <div className={"col-6 text-end"}>
                        {roundNumber(currentOrder.total - currentOrder.transport - (currentOrder.servicesPrice ? currentOrder.servicesPrice : 0))} RON
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-6"}>
                        Transport
                    </div>
                    <div className={"col-6 text-end"}>
                        {currentOrder.transport} RON
                    </div>
                </div>

                <div className={"row mb-2"}>
                    <div className={"col-6"}>
                        Avans
                    </div>
                    <div className={"col-6 text-end"}>
                        {roundNumber(currentOrder.advance ? - currentOrder.advance : 0)} RON
                    </div>
                </div>

                <div className={"row border-top pt-2"}>
                    <div className={"col-6"}>
                        <strong>Total</strong>
                    </div>
                    <div className={"col-6 text-end"}>
                        {roundNumber(currentOrder.total - currentOrder.advance)} RON
                    </div>
                </div>

                <button type={"button"} className={"btn btn-success w-100 mt-5"}
                        disabled={modalBtnLoading}
                        onClick={moveToInvoiced}>
                    {
                        modalBtnLoading ?
                            <SmallLoadingComp/>
                            :
                            'Facturează'
                    }
                </button>



                {
                    company && company.activateInvoicing ?
                        <div className={"row"}>
                            <div className={"col-12 mt-4"}>
                                <strong>Înregistrare plată</strong>
                            </div>
                            <div className={"col-12 col-md"}>
                                <button type={"button"} className={"btn btn-success w-100 mt-3"}
                                        disabled={modalBtnLoading}
                                        onClick={() => markPayment(0)}>
                                    Cash
                                </button>
                            </div>
                            <div className={"col-12 col-md"}>
                                <button type={"button"} className={"btn btn-info w-100 mt-3"}
                                        disabled={modalBtnLoading}
                                        onClick={() => markPayment(1)}>
                                    POS
                                </button>
                            </div>
                            <div className={"col-12 col-md"}>
                                <button type={"button"} className={"btn btn-secondary w-100 mt-3"}
                                        disabled={modalBtnLoading}
                                        onClick={() => markPayment(2)}>
                                    O.P.
                                </button>
                            </div>
                            <div className={"col-12 col-md"}>
                                <button type={"button"} className={"btn btn-danger w-100 mt-3"}
                                        disabled={modalBtnLoading}
                                        onClick={() => delayPayment()}>
                                    Amânată
                                </button>
                            </div>
                        </div>
                        :null
                }


                {
                    company && company.templateSms1 ?

                        <a href={
                            getMobileOperatingSystem() === 'Android' ?
                                encodeURI("sms:" + currentOrder.phone + "?body=" + company.templateSms1.replace('[pret]', (roundNumber(currentOrder.total - currentOrder.advance))).replace('[nr]',(currentOrder.carpets[0] && currentOrder.carpets[0].code ? currentOrder.carpets[0].code.split('-')[0]:'')))
                                :
                                encodeURI("sms:" + currentOrder.phone + "&body=" + company.templateSms1.replace('[pret]', (roundNumber(currentOrder.total - currentOrder.advance))).replace('[nr]',(currentOrder.carpets[0] && currentOrder.carpets[0].code ? currentOrder.carpets[0].code.split('-')[0]:'')))
                        }>
                            <button
                                type={"button"}
                                className={"btn btn-secondary w-100 mt-3"}>
                                <FontAwesomeIcon icon={faEnvelope}/> Trimite sms ( comandă măsurată )
                            </button>
                        </a>
                        : null
                }
                <button
                    type={"button"}
                    className={"btn btn-secondary w-100 mt-3"} onClick={() => setSecondModalIsOpen(true)}>
                    <FontAwesomeIcon icon={faEnvelope}/> Trimite sms ( comandă gata de livrat )
                </button>
                {
                    company && company.templateSms3 ?

                        <a href={
                            getMobileOperatingSystem() === 'Android' ?
                                encodeURI("sms:" + currentOrder.phone + "?body=" + company.templateSms3.replace('[interval]', currentOrder.deliveryInterval ? currentOrder.deliveryInterval : '08-20'))
                                :
                                encodeURI("sms:" + currentOrder.phone + "&body=" + company.templateSms3.replace('[interval]',  currentOrder.deliveryInterval ? currentOrder.deliveryInterval : '08-20'))
                        }>
                            <button
                                type={"button"}
                                className={"btn btn-secondary w-100 mt-3"}>
                                <FontAwesomeIcon icon={faEnvelope}/> Trimite sms ( livrare astăzi )
                            </button>
                        </a>
                        : null
                }


                <DeleteOrderButton
                    order={currentOrder}
                    afterDelete={() => {
                        setShowModal(false)
                        onFinish()
                    }}
                />

            </Modal>

            <Modal
                isOpen={secondModalIsOpen}
                onAfterOpen={() => {
                }}
                onRequestClose={() => setSecondModalIsOpen(false)}
                shouldCloseOnOverlayClick={false}
                style={customModalStyles}
                contentLabel="Example Modal"
            >
                <button className={"btn-close-modal"} onClick={() => setSecondModalIsOpen(false)}>
                    <FontAwesomeIcon icon={faTimes}/>
                </button>

                <h2>Trimite sms</h2>

                {
                    currentOrder.status !== 1 ?
                        <div className={"row"}>
                            <div className={"col-12"}>
                                <label>Data livrare
                                    <input type="date" className="form-control no-width"
                                           value={deliveryDate}
                                           onChange={(e) => setDeliveryDate(e.target.value)}/>
                                </label>
                            </div>
                            {
                                deliveryDate ?
                                    <div className={"col-12"}>
                                        <label>Interval livrare
                                            <select value={deliveryInterval} className={"form-control"}
                                                    onChange={(e) => setDeliveryInterval(e.target.value)}>
                                                <option value={"08:00 - 10:00"}>08:00 - 10:00</option>
                                                <option value={"10:00 - 12:00"}>10:00 - 12:00</option>
                                                <option value={"12:00 - 15:00"}>12:00 - 15:00</option>
                                                <option value={"15:00 - 18:00"}>15:00 - 18:00</option>
                                                <option value={"18:00 - 20:00"}>18:00 - 20:00</option>
                                            </select>
                                        </label>
                                    </div>
                                    : null
                            }
                        </div>
                        :null
                }

                <div className={"row mt-2 mb-2"}>
                    <div className={"col"}>

                        <div className={"small-modal-btns"}>
                            {
                                company && company.templateSms2 ?

                                    <a href={
                                        getMobileOperatingSystem() === 'Android' ?
                                            encodeURI("sms:" + currentOrder.phone + "?body=" + company.templateSms2.replace('[data]', deliveryDate ? moment(new Date(deliveryDate)).format("DD/MM/YYYY") + ' ' + deliveryInterval : '').replace('[link]', 'https://app.totalcarpet.ro/changeDeliveryDate/'+company._id + '/'+currentOrder._id))
                                            :
                                            encodeURI("sms:" + currentOrder.phone + "&body=" + company.templateSms2.replace('[data]', deliveryDate ? moment(new Date(deliveryDate)).format("DD/MM/YYYY") + ' ' + deliveryInterval : '').replace('[link]', 'https://app.totalcarpet.ro/changeDeliveryDate/'+company._id + '/'+currentOrder._id))
                                    }>
                                        <button
                                            type={"button"}
                                            className={"btn btn-secondary w-100"}>
                                            <FontAwesomeIcon icon={faEnvelope}/> Trimite sms
                                        </button>
                                    </a>
                                    : null
                            }

                            <button type={"button"} className={"btn btn-primary w-100 mt-5 small-modal-close-btn"} onClick={() => setSecondModalIsOpen(false)}>
                                Finalizare
                            </button>
                        </div>

                    </div>
                </div>

            </Modal>


        </>
    );
}
