import "./style.css"
import {server} from "../const";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Login() {
    const login = (e) => {

        if (e !== '') {
            e.preventDefault()
        }

               fetch(server + '/auth/login', {
                   method: 'POST',
                   body: JSON.stringify({
                       'email': e.target.email.value,
                       'password': e.target.password.value
                   }),
                   headers: {
                       'Accept': 'application/json',
                       'Content-Type': 'application/json',
                   },
                   strictErrors: true
               }).then(response => {

                   if (response.ok) {
                       return response.json();
                   } else {
                       return response.status;
                   }
                   }).then(json => {
                   if (json.token) {
                       localStorage.setItem('token', json.token)
                       localStorage.setItem('user_role', json.role)
                       localStorage.setItem('user_id', json.id)
                       if(json.company) {
                           localStorage.setItem('color_order_source_1', json.company.ordersReceivedColor)
                           localStorage.setItem('activateInvoicing', !!json.company.activateInvoicing)
                       }
                       window.location.reload()
                   }else {
                       toast.warning('Adresa de email sau parola este incorectă!');
                   }
               }).catch(e => {
                   console.log(e)
                   toast.warning('Eroare!');
               });

    }
    return (
        <div className={"container"}>
            <div className={"row"}>
                <div className={"col-12"}>
                    <ToastContainer />
                    <form className="form-signin" onSubmit={(e) => login(e)}>
                        <h1 className="h3 mb-3 font-weight-normal">Autentificare</h1>
                        <label htmlFor="inputEmail" className="sr-only">Adresa de email</label>
                        <input type="email" name="email" id="inputEmail" className="form-control mb-2" placeholder="Introduceți adresa de email"
                               required autoFocus/>
                        <label htmlFor="inputPassword" className="sr-only">Parola</label>
                        <input type="password" name="password" id="inputPassword" className="form-control mb-4"
                               placeholder="Introduceți parola" required/>

                        <button className="btn btn-lg btn-primary btn-block w-100" type="submit">Autentificare</button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Login;
