import {server} from "../../const";
import {ToastContainer, toast} from "react-toastify";
import {useState} from "react";
import SmallLoadingComp from "../../components/loaders/SmallLoadingComp";

export default function ClientRegistration() {
    const token = localStorage.getItem('token')

    const [loading, setLoading] = useState(false)

    const addClient = (e) => {

        if (e !== '') {
            e.preventDefault()
        }

        setLoading(true)
        fetch(server + '/admin/clients/register', {
            method: 'POST',
            body: JSON.stringify({
                'name': e.target.name.value,
                'companyName': e.target.companyName.value,
                'address': e.target.address.value,
                'email': e.target.email.value,
                'password': e.target.password.value
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {

            if (json.email) {
                toast.success('Success!');
            }else {
                toast.success('A aparut o eroare!');
            }
            setLoading(false)
        }).catch(e => {
            setLoading(false)
            toast.success('A aparut o eroare!');
        });

    }
    return (
        <div className={"container"}>
            <div className={"row"}>
                <div className={"col-12"}>
                    <form className="form-signin" onSubmit={(e) => addClient(e)}>
                        <h1 className="h3 mb-3 font-weight-normal">Adauga client</h1>
                        <label htmlFor="inputName" className="sr-only">Name</label>
                        <input type="text" name="name" id="inputName" className="form-control mb-2" placeholder="Name"
                               required autoFocus/>
                        <label htmlFor="inputCompanyName" className="sr-only">Company name</label>
                        <input type="text" name="companyName" id="inputCompanyName" className="form-control mb-2" placeholder="Company name"
                               required autoFocus/>
                        <label htmlFor="inputAddress" className="sr-only">Address</label>
                        <input type="text" name="address" id="inputAddress" className="form-control mb-2" placeholder="Address"
                               required autoFocus/>
                        <label htmlFor="inputEmail" className="sr-only">Email address</label>
                        <input type="email" name="email" id="inputEmail" className="form-control mb-2" placeholder="Email address"
                               required autoFocus/>
                        <label htmlFor="inputPassword" className="sr-only">Password</label>
                        <input type="password" name="password" id="inputPassword" className="form-control mb-4"
                               placeholder="Password" required/>

                        <button className="btn btn-lg btn-primary btn-block w-100" type="submit" disabled={loading}>
                            {
                                loading ?
                                    <SmallLoadingComp/>
                                    :
                                    'Adaugă'
                            }
                        </button>

                        <ToastContainer />
                    </form>
                </div>
            </div>
        </div>
    );
}

