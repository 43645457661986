import React, {useEffect, useState} from "react";
import {server} from "../../const"
import {toast} from "react-toastify";
import LoadingComp from "../../components/loaders/LoadingComp";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretDown} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import SmallLoadingComp from "../../components/loaders/SmallLoadingComp";

export default function Incomes() {


    var todayDate = new Date(), y = todayDate.getFullYear(), m = todayDate.getMonth();
    var firstDay = new Date(y, m, 1);
    var lastDay = new Date(y, m + 1, 0);


    const token = localStorage.getItem('token')
    const [incomes, setIncomes] = useState([])

    const [filterStartDate, setFilterStartDate] = useState(moment(firstDay).format("YYYY-MM-DD"))
    const [filterEndDate, setFilterEndDate] = useState(moment(lastDay).format("YYYY-MM-DD"))


    // const [date, setDate] = useState(moment().format("YYYY-MM-DD"))
    // const [category, setCategory] = useState('Salariați')
    // const [amount, setAmount] = useState('')
    // const [info, setInfo] = useState('')
    //
    // const [btnLoading, setBtnLoading] = useState(false)
    // const [modalIsOpen, setIsOpen] = useState(false);

    const [total, setTotal] = useState(0)

    const [loading, setLoading] = useState(true)
    const [exportLoading, setExportLoading] = useState(false)

    const getIncomes = () => {
        fetch(server + '/client/incomes/list', {
            method: 'POST',
            body: JSON.stringify({
                dateStart: filterStartDate,
                dateEnd: filterEndDate
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            if (json) {
                setIncomes(json)

                let total = 0
                json.forEach((income, i) => {
                    if(income.total > 0) {
                        total = total + income.total
                    }
                })
                setTotal(total)

                setLoading(false)
            } else {
                toast.warning('A aparut o eroare!');
            }

        }).catch(e => {
            //toast.warning('A aparut o eroare!');
        });
    }

    useEffect(() => {
        getIncomes()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        getIncomes()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterStartDate, filterEndDate])

    const exportExcel = async () => {
        setExportLoading(true)
        await fetch(server + '/client/incomes/list/export', {
            method: 'POST',
            body: JSON.stringify({
                dateStart: filterStartDate,
                dateEnd: filterEndDate
            }),
            headers: {
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'Authorization': 'Bearer ' + token,
            }})
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `export-venituri.xlsx`,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            })
        setExportLoading(false)
    }

    return (
        <div className={"container"}>

            {loading ?
                <LoadingComp/>
                :
                <>

                    <div className={"float-end"}>
                        <button type={"button"} className={"btn btn-info"} onClick={() => exportExcel()} disabled={exportLoading}>
                            {
                                exportLoading ?
                                    <SmallLoadingComp/>:
                                    'Export excel'
                            }
                        </button>
                    </div>
                    <h2 className={"list-page-title"}>Venituri</h2>

                    <div className={"expenses-input-group"}>
                        <div className={"row"}>
                            <div className="col-12 col-md-4">
                                <label>Data de început
                                    <input type="date" className="form-control"
                                           value={filterStartDate}
                                           onChange={(e) => setFilterStartDate(e.target.value)}/>
                                </label>
                            </div>
                            <div className="col-12 col-md-4">
                                <label>Data de sfârșit
                                    <input type="date" className="form-control"
                                           value={filterEndDate}
                                           onChange={(e) => setFilterEndDate(e.target.value)}/>
                                </label>
                            </div>
                            <div className="col-12 col-md-4">
                                <p>Total<br/>
                                    <span className={"h5 mt-1"}><strong>{Math.round((total + Number.EPSILON) * 100) / 100} RON</strong></span>
                                </p>
                            </div>
                        </div>
                    </div>


                    <div className={"row list-page-table mb-5"}>
                        <div className={"col-12"}>
                            <div className={"table-responsive"}>
                            <table className="table table-hover">
                                <thead>
                                <tr>
                                    <th scope="col">Comanda</th>
                                    <th scope="col">Client</th>
                                    <th scope="col">Telefon</th>
                                    <th scope="col">Valoare</th>
                                    <th scope="col">Data <FontAwesomeIcon icon={faCaretDown}/></th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    incomes ? incomes.map((income, i) => (
                                            <tr key={i}>
                                                <td>{income.number}</td>
                                                <td>{income.name}</td>
                                                <td>{income.phone}</td>
                                                <td>{income.total} RON</td>
                                                <td>{moment(income.date).format("DD/MM/YYYY")}</td>

                                            </tr>
                                        ))
                                        : null
                                }

                                </tbody>
                            </table>
                            </div>
                        </div>
                    </div>


                </>
            }



        </div>
    );
}
