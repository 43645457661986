import moment from "moment";

export function getOrderStatusBadge(status) {

    switch (status) {
        case 0:
            return <span className={"badge bg-light"}>Primită în sediu</span>
        case 1:
            return <span className={"badge bg-warning"}>De colectat</span>
        case 2:
            return <span className={"badge bg-info"}>De măsurat</span>
        case 3:
            return <span className={"badge bg-warning"}>De splălat</span>
        case 4:
            return <span className={"badge bg-warning"}>La uscat</span>
        case 5:
            return <span className={"badge bg-success"}>De livrat</span>
        case 6:
            return <span className={"badge bg-success"}>Finalizată</span>
        case 7:
            return <span className={"badge bg-danger"}>Neîncasată</span>
        case 8:
            return <span className={"badge bg-success"}>Încasată</span>
        default:
            return null
    }
}

export function getSquareMeters(height, width) {

    let surface = (width/100) * (height/100)

    if(surface < 1) {
        surface = 1
    }

    return surface
}

export function extractCodesPrefixes(carpets) {

    let prefixes = []

    carpets.forEach((carpet,i) => {
        if(carpet.code) {
            let prefix = carpet.code.split('-')[0]
            if (!prefixes.includes(prefix)) {
                prefixes.push(prefix)
            }
        }
    })

    return prefixes

}

export function displayBadgePrefixes(prefixes) {

    let render = []

    if(prefixes[0]) {
        render.push(<span key={0} className={"badge bg-light"}>{prefixes[0]}</span>)
    }

    if(prefixes[1]) {
        render.push(<span key={1} className={"badge bg-info"}>{prefixes[1]}</span>)
    }

    if(prefixes[2]) {
        render.push(<span key={2} className={"badge bg-warning"}>{prefixes[2]}</span>)
    }

    if(prefixes[3]) {
        render.push(<span key={3} className={"badge bg-danger"}>{prefixes[3]}</span>)
    }

    if(prefixes[4]) {
        render.push(<span key={4} className={"badge bg-success"}>{prefixes[4]}</span>)
    }

    return render

}

export function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }

    return "unknown";
}

export function isDatePassed(date) {

    if(!date) {
        return false;
    }

    if(moment(date) <= moment()) {
        return true;
    }

    return false;
}

export function truncate(str, limit) {
    var bits, i;

    if(!str) {
        return ''
    }

    bits = str.split('');
    if (bits.length > limit) {
        for (i = bits.length - 1; i > -1; --i) {
            if (i > limit) {
                bits.length = i;
            }
            else if (' ' === bits[i]) {
                bits.length = i;
                break;
            }
        }
        bits.push('...');
    }
    return bits.join('');
}

export function roundNumber(value, precision = 1) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
}

export function getOrderPaymentMethod(paymentMethod) {

    switch (paymentMethod) {
        case 0:
            return <span className={"badge bg-success"}>Cash</span>
        case 1:
            return <span className={"badge bg-info"}>POS</span>
        case 2:
            return <span className={"badge bg-secondary"}>O.P.</span>
        default:
            return null
    }
}

export function getDefaultCarpetPricing(company) {

    if(company.pricing && company.pricing.length > 0) {

        let defaultPricing = false

        company.pricing.forEach((pricingItem,i) => {
            if(pricingItem.default) {
                defaultPricing = {
                    id: i,
                    ...pricingItem
                }
            }
        })

        if(!defaultPricing) {
            defaultPricing = {
                id: 0,
                ...company.pricing[0]
            }
        }

        return defaultPricing

    } else {
        return {
            'id': 0,
            'price': 0,
            'default': true
        }
    }

}

export function getCarpetPrice(carpet, company) {

    if(carpet.pricing && parseInt(carpet.pricing) > 0) {
        return carpet.pricing
    }

    return getDefaultCarpetPricing(company).price
}
