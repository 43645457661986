import React, {useEffect, useRef, useState} from "react";
import {customModalStyles, GOOGLE_API_KEY, server} from "../../const"
import Modal from "react-modal"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPlus, faPrint, faTimes, faTrash} from '@fortawesome/free-solid-svg-icons'
import {toast} from "react-toastify";
import moment from "moment";
import SmallLoadingComp from "../../components/loaders/SmallLoadingComp";
import {getDefaultCarpetPricing, getOrderStatusBadge} from "../utils";
import ReactToPrint from 'react-to-print';
import {PvPrint} from "../prints/pvPrint";
import QrReader from "react-qr-reader";
import ReactGoogleMapLoader from "react-google-maps-loader"
import ReactGooglePlacesSuggest from "react-google-places-suggest"
import ErrorParser from "../ErrorParser/ErrorParser"
import DatePicker from "react-datepicker";

export default function AddOrder({client = false, laundry = false, dashboardItem = false, opened = false, onClose = () => {}}) {
    const token = localStorage.getItem('token')

    const [modalIsOpen, setIsOpen] = useState(opened);
    const [secondModalIsOpen, setSecondModalIsOpen] = useState(false);

    const pvPrintRef = useRef(null)

    const [phone, setPhone] = useState(client ? client.phone : '')
    const [name, setName] = useState(client ? client.name : '')
    const [address, setAddress] = useState(client ? client.address : '')
    const [searchAddress, setSearchAddress] = useState('')
    const [vatRate, setVatRate] = useState(0)
    const [advance, setAdvance] = useState(0)
    const [discount, setDiscount] = useState(0)
    const [transport, setTransport] = useState(0)
    const [notes, setNotes] = useState('')
    const [itemsCount, setItemsCount] = useState(0)
    const [interval, setInterval] = useState("08:00 - 10:00")
    const [coordinates, setCoordinates] = useState({})
    const [zone, setZone] = useState(0)

    const [datePickup, setDatePickup] = useState(moment().format("YYYY-MM-DDTHH:mm"))
    const [dateDelivery, setDateDelivery] = useState('')

    const [transportType, setTransportType] = useState(0)

    const [company, setCompany] = useState({})

    const [addLoading, setAddLoading] = useState(false)

    const [currentOrder, setCurrentOrder] = useState({})
    const [currentCarpets, setCurrentCarpets] = useState([])
    const [carpetsCodePrefix, setCarpetsCodePrefix] = useState('')
    const [carpetsCodePrefixValid, setCarpetsCodePrefixValid] = useState(true)

    const [startQrScan, setStartQrScan] = useState(false)

    const [searchResults, setSearchResults] = useState([])
    const [driver, setDriver] = useState(0)
    const [drivers, setDrivers] = useState([])

    const [additionalServices, setAdditionalServices] = useState([])

    const [activeDefaultPricing, setActiveDefaultPricing] = useState(null)

    const maxItemsCount = 6
    const handleFocus = (event) => event.target.select();

    const addOrder = async () => {
        if (
            !name ||
            !phone ||
            !datePickup
        ) {
            return;
        }

        let valid = true;

        if (transportType === 0 || transportType === 2) {
            currentCarpets.forEach((carpet, i) => {
                if (carpet.code === null || carpet.code.length === 0 || carpetsCodePrefixValid === false) {
                    valid = false
                }
            })
        }

        if (!valid) {
            return;
        }

        setAddLoading(true)

        let status = 0

        switch (transportType) {
            case 0:
                status = 2
                break;
            case 1:
                status = 1
                break;
            case 2:
                status = 2
                break;
            default:
                break;
        }

        fetch(server + '/client/orders', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify({
                name: name,
                phone: phone,
                driver: driver,
                address: address,
                vatRate: vatRate,
                advance: advance,
                discount: discount,
                transport: transport,
                transportType: transportType,
                datePickup: moment(datePickup).toISOString(),
                dateDelivery: moment(dateDelivery).toISOString(),
                deliveryInterval: interval,
                notes: notes,
                itemsCount: parseInt(itemsCount + ""),
                status: status,
                company: company._id,
                carpets: currentCarpets,
                source: transportType === 0 ? 1 : 0,
                services: additionalServices,
                coordinates: coordinates,
            })
        }).then(response => {
            return response.json()
        }).then(json => {
            if (json) {

                setName('')
                setPhone('')
                setAddress('')
                setCoordinates({})
                setDriver(0)
                setTransport(0)
                setTransportType(0)
                setDatePickup(moment().format("YYYY-MM-DDTHH:mm"))
                setDateDelivery('')
                setNotes('')
                setItemsCount(1)

                setCurrentOrder(json)
                if (json.status === 2) {
                    setSecondModalIsOpen(true)
                } else {
                    onClose()
                }

                getDrivers()
                setIsOpen(false)
            } else {
                toast.warning('A aparut o eroare!');
            }
            setAddLoading(false)

        }).catch(e => {
            setAddLoading(false)
            toast.warning('A aparut o eroare!');
        });
    }

    const getClient = () => {
        fetch(server + '/client/company', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            if (json) {
                setCompany(json)
                if(company.vatRates && company.vatRates.length > 0) {
                    setVatRate(company.vatRates[0].rate)
                }
            } else {
                toast.warning('A aparut o eroare!');
            }

        }).catch(e => {
            ErrorParser(e)
        });

    }

    const searchClients = (phone) => {
        fetch(server + '/client/orders/clients' + (phone.length > 0 ? '?search=' + phone : ''), {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            if (json) {
                setSearchResults(json['clients'])
            } else {
                toast.warning('A aparut o eroare!');
            }

        }).catch(e => {
            //toast.warning('A aparut o eroare!');
        });

    }

    useEffect(() => {
        getClient()
        getDrivers()
        setCurrentCarpets(getEmptyCarpetsArray(0))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        getNextCarpetCode()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalIsOpen])

    const getDrivers = () => {
        fetch(server + '/client/employees?count_orders=1&role=driver', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            if (json) {
                setDrivers(json.filter((item) => item.role === 'driver'))
            } else {
                toast.warning('A aparut o eroare!');
            }

        }).catch(e => {
            //toast.warning('A aparut o eroare!');
        });
    }

    const getNextCarpetCode = () => {
        fetch(server + '/client/orders/getNextCarpetCode', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            if (json) {
                setCarpetsPrefix(json['data'])

            } else {
                toast.warning('A aparut o eroare!');
            }

        }).catch(e => {
            //toast.warning('A aparut o eroare!');
        });

    }

    const checkCodePrefix = (carpetsCodePrefix) => {

        fetch(server + '/client/orders/checkPrefix?prefix=' + carpetsCodePrefix, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            if (json) {
                setCarpetsCodePrefixValid(json['result'])
            } else {
                toast.warning('A aparut o eroare!');
            }

        }).catch(e => {
            //toast.warning('A aparut o eroare!');
        });

    }

    const getItemsCountFields = () => {

        let render = []

        for (let i = 0; i <= maxItemsCount - 1; i++) {
            render.push(<label key={i} className={"items-count-label"}>
                <input type={"radio"} name={"itemsCount"} value={i}
                       onChange={(e) => {
                           setItemsCount(parseInt(e.target.value))
                           setCurrentCarpets(getEmptyCarpetsArray(e.target.value))
                       }}
                       checked={i === itemsCount}
                /> {i}
            </label>)
        }

        return render
    }

    const getEmptyCarpetsArray = (count) => {

        let carpets = []

        for (let i = 0; i < count; i++) {

            let code = false;
            if(carpetsCodePrefix) {
                code = carpetsCodePrefix + '-' + i
            }

            carpets.push({
                code: code ? code:'',
                width: null,
                height: null,
                pricing: activeDefaultPricing ? activeDefaultPricing : getDefaultCarpetPricing(company)
            })
        }

        return carpets

    }

    const setCarpetValue = (i, param, value) => {

        let newCarpetsValues = [...currentCarpets]

        newCarpetsValues[i][param] = value

        setCurrentCarpets(newCarpetsValues)

    }

    const setCarpetsPrefix = (value) => {

        if (!value) {
            setCarpetsCodePrefix('')
            return;
        }

        let newCarpetsValues = [...currentCarpets]

        newCarpetsValues.forEach((carpet, i) => {
            newCarpetsValues[i]['code'] = value + '-' + i
        })

        setCurrentCarpets(newCarpetsValues)
        setCarpetsCodePrefix(value)
        checkCodePrefix(value)

    }

    const addAdditionalService = (i) => {

        let newServices = [...additionalServices]
        newServices.push({
            'title': company.services[i].title,
            'quantity': 1,
            'price': company.services[i].price,
        })

        setAdditionalServices(newServices)
    }

    /*
    const selectAdditionalService = (i, serviceIndex) => {

        let newServices = [...additionalServices]

        newServices[i] = {
            title: company.services[serviceIndex].title,
            price: parseFloat(company.services[serviceIndex].price),
            quantity: 1,
        }

        setAdditionalServices(newServices)
    }
     */

    const updateAdditionalService = (i, parameter, value) => {

        let newServices = [...additionalServices]

        newServices[i][parameter] = parseFloat(value)

        setAdditionalServices(newServices)

    }

    const deleteAdditionalService = (i) => {
        let newServices = [...additionalServices]
        delete newServices[i]

        newServices = newServices.filter(function (item) {
            return item !== undefined;
        });

        setAdditionalServices(newServices)
    }

    return (
        <div>
            <div>

                {
                    dashboardItem ?
                        <div className={"dashboard-big-btn-cont  bg-success"} onClick={() => setIsOpen(true)}>
                            <div className={"dashboard-big-btn-cont-icon"}>
                                <FontAwesomeIcon icon={faPlus}/>
                            </div>
                            <div className={"dashboard-big-btn-cont-title"}>Adaugă comandă</div>
                        </div>
                        :
                        <button type={"button"} className={"btn btn-primary"} onClick={() => setIsOpen(true)}>Adaugă
                            comandă
                        </button>
                }
            </div>


            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={() => {
                }}
                onRequestClose={() => setIsOpen(false)}
                shouldCloseOnOverlayClick={false}
                style={customModalStyles}
                contentLabel="Example Modal"
            >
                <button className={"btn-close-modal"} onClick={() => {
                    setIsOpen(false)
                    onClose()
                }}>
                    <FontAwesomeIcon icon={faTimes}/>
                </button>

                <h2>Adaugă comandă</h2>

                <div className={"row mt-4"}>
                    <div className={"col"}>
                        <div className="form-group">
                            <label>Telefon client <span className={"red-tick"}>*</span>
                                <input type="number" className="form-control" value={phone}
                                       onChange={(e) => {
                                           setPhone(e.target.value)
                                           searchClients(e.target.value)
                                       }}/>
                            </label>
                        </div>

                        {
                            searchResults.length > 0 ?
                                <>
                                    <div className={"search-overlay"} onClick={() => setSearchResults([])}></div>
                                <div className={"search-results-cont search-result-add-form"}>
                                    <table className="table table-hover">
                                        <thead>
                                        <tr>
                                            <th>Nume</th>
                                            <th>Telefon</th>
                                            <th>Adresă</th>
                                            <th>Nr. comenzi</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            searchResults ? searchResults.map((order, i) => (
                                                    <tr key={i} className={"tr-link"}
                                                        onClick={() => {
                                                            setPhone(order.phone)
                                                            setName(order.name)
                                                            setAddress(order.address)
                                                            setSearchResults([])
                                                        }}
                                                    >

                                                        <td>{order.name}</td>
                                                        <td>{order.phone}</td>
                                                        <td>{order.address}</td>
                                                        <td className={"text-center"}>{order.countOrders}</td>

                                                    </tr>
                                                ))
                                                : null
                                        }

                                        </tbody>
                                    </table>
                                </div>
                                </>
                                : null
                        }
                    </div>
                    <div className={"col"}>
                        <div className="form-group">
                            <label>Nume client <span className={"red-tick"}>*</span>
                                <input type="text" className="form-control" value={name}
                                       onChange={(e) => {
                                           setName(e.target.value)
                                           searchClients(e.target.value)
                                       }}/>
                            </label>
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <label>Adresă client

                        <ReactGoogleMapLoader
                            params={{
                                key: GOOGLE_API_KEY,
                                libraries: "places,geocode",
                            }}
                            render={googleMaps =>
                                googleMaps && (
                                    <ReactGooglePlacesSuggest
                                        googleMaps={googleMaps}
                                        autocompletionRequest={{
                                            input: searchAddress,
                                            // Optional options
                                            // https://developers.google.com/maps/documentation/javascript/reference?hl=fr#AutocompletionRequest
                                        }}
                                        // Optional props
                                        onNoResult={() => {}}
                                        onSelectSuggest={(geocodedPrediction, originalPrediction) => {
                                            setSearchAddress('')
                                            setAddress(geocodedPrediction.formatted_address)
                                            setCoordinates({
                                                lat: geocodedPrediction.geometry.location.lat(),
                                                lng: geocodedPrediction.geometry.location.lng()
                                            })
                                        }}
                                        onStatusUpdate={(e) => {}}
                                        textNoResults="Nu am găsit rezultate" // null or "" if you want to disable the no results item
                                        customRender={prediction => (
                                            <div className="customWrapper">
                                                {prediction
                                                    ? prediction.description
                                                    : "Nu am găsit rezultate"}
                                            </div>
                                        )}
                                    >
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={address}
                                            onChange={(e) => {
                                                setAddress(e.target.value)
                                                setSearchAddress(e.target.value)
                                            }}
                                        />
                                    </ReactGooglePlacesSuggest>)
                            }
                        />

                    </label>


                </div>

                {
                    company && company.zones && company.zones.length > 0 ?
                        <div className={"row"}>
                            <div className={"col-12 col-md-6"}>
                                <div className="form-group">
                                    <label>Alegeți zona <span className={"red-tick"}>*</span>
                                        <select value={zone} className={"form-control"}
                                                onChange={(e) => {
                                                    if(e.target.value > 0) {
                                                        setDatePickup(moment()
                                                            .set('hour', parseInt(moment(company.zones.find((item) => item.id === parseInt(e.target.value + '')).start).format('HH')))
                                                            .set('minute',parseInt(moment(company.zones.find((item) => item.id === parseInt(e.target.value + '')).start).format('mm')))
                                                            .format('YYYY-MM-DDTHH:mm'))

                                                    }
                                                    setZone(parseInt(e.target.value+''))
                                                }}>
                                            <option value={0}>Fără zonă</option>

                                            {
                                                company.zones.map((zoneItem,i) => (
                                                    <option key={i} value={zoneItem.id}>{zoneItem.title}</option>
                                                ))
                                            }

                                        </select>
                                    </label>
                                </div>
                            </div>
                        </div>
                        :
                        null
                }


                {
                    zone && zone > 0 ?
                        <div className={"row"}>
                            <div className={"col-12 col-md-6"}>
                                <div className="form-group">
                                    <label>Data ridicare <span className={"red-tick"}>*</span>
                                        <DatePicker
                                            className={"form-control"}
                                            selected={new Date(datePickup)}
                                            onChange={(date) => {
                                                setDatePickup(moment(date).format('YYYY-MM-DDTHH:mm'))
                                            }}
                                            minDate={moment(new Date()).format("YYYY-MM-DDT23:59")}
                                            dateFormat="dd/MM/yyyy"
                                            timeFormat="HH:mm"
                                            minuteInterval={10}
                                        />
                                    </label>

                                    <label>Interval ridicare
                                        <select value={'1'} className={"form-control"} disabled={true}
                                                onChange={(e) => setInterval(e.target.value)}>
                                            <option value={"1"}>{moment(company.zones.find((item) => item.id === zone).start).format('HH:mm')} - {moment(company.zones.find((item) => item.id === zone).end).format('HH:mm')}</option>
                                        </select>
                                    </label>
                                </div>

                            </div>
                            <div className={"col-12 col-md-6"}>
                                <div className={"row"}>
                                    <div className={"col-12"}>
                                        <div className="form-group">
                                            <label onClick={e => e.preventDefault()}>Data livrare
                                                <DatePicker
                                                    className={"form-control"}
                                                    selected={dateDelivery ? moment(dateDelivery).toDate() : ''}
                                                    onChange={(date) => {
                                                        setDateDelivery(moment(date).format("YYYY-MM-DD"))
                                                    }}
                                                    minDate={moment(new Date()).add(1, 'days').toDate()}
                                                    dateFormat="dd/MM/yyyy"
                                                />
                                            </label>
                                        </div>
                                    </div>
                                    {
                                        dateDelivery ?
                                            <div className={"col-12"}>
                                                <label>Interval livrare
                                                    <select value={interval} className={"form-control"}
                                                            onChange={(e) => setInterval(e.target.value)}>
                                                        <option value={"08:00 - 10:00"}>08:00 - 10:00</option>
                                                        <option value={"10:00 - 12:00"}>10:00 - 12:00</option>
                                                        <option value={"12:00 - 15:00"}>12:00 - 15:00</option>
                                                        <option value={"15:00 - 18:00"}>15:00 - 18:00</option>
                                                        <option value={"18:00 - 20:00"}>18:00 - 20:00</option>
                                                    </select>
                                                </label>
                                            </div>
                                            : null
                                    }
                                </div>
                            </div>
                        </div>
                        :
                        <div className={"row"}>
                            <div className={"col-12 col-md-6"}>
                                <div className="form-group">
                                    <label>Data ridicare <span className={"red-tick"}>*</span>
                                        <DatePicker
                                            showTimeSelect
                                            className={"form-control"}
                                            selected={new Date(datePickup)}
                                            onChange={(date) => {
                                                setDatePickup(moment(date).format('YYYY-MM-DDTHH:mm'))
                                            }}
                                            minDate={moment(new Date()).format("YYYY-MM-DDT23:59")}
                                            dateFormat="dd/MM/yyyy HH:mm"
                                            timeFormat="HH:mm"
                                            minuteInterval={10}
                                        />
                                    </label>
                                </div>
                            </div>
                            <div className={"col-12 col-md-6"}>
                                <div className={"row"}>
                                    <div className={"col-12"}>
                                        <div className="form-group">
                                            <label  onClick={e => e.preventDefault()}>Data livrare
                                                <DatePicker
                                                    className={"form-control"}
                                                    selected={dateDelivery ? moment(dateDelivery).toDate() : ''}
                                                    onChange={(date) => {
                                                        setDateDelivery(moment(date).format("YYYY-MM-DD"))
                                                    }}
                                                    minDate={moment(new Date()).add(1, 'days').toDate()}
                                                    dateFormat="dd/MM/yyyy"
                                                />
                                            </label>
                                        </div>
                                    </div>
                                    {
                                        dateDelivery ?
                                            <div className={"col-12"}>
                                                <label>Interval livrare
                                                    <select value={interval} className={"form-control"}
                                                            onChange={(e) => setInterval(e.target.value)}>
                                                        <option value={"08:00 - 10:00"}>08:00 - 10:00</option>
                                                        <option value={"10:00 - 12:00"}>10:00 - 12:00</option>
                                                        <option value={"12:00 - 15:00"}>12:00 - 15:00</option>
                                                        <option value={"15:00 - 18:00"}>15:00 - 18:00</option>
                                                        <option value={"18:00 - 20:00"}>18:00 - 20:00</option>
                                                    </select>
                                                </label>
                                            </div>
                                            : null
                                    }
                                </div>
                            </div>
                        </div>
                }


                {
                    !laundry ?
                        <div className="form-group">
                            <div className={"row"}>
                                <div className={"col-12 col-md-6"}>



                                    {
                                        company && company.vatRates && company.vatRates.length > 0 ?
                                            <> <label>TVA <span className={"red-tick"}>*</span></label>
                                                <label className={"mt-2"}>
                                                    <select value={vatRate} className={"form-control"}
                                                            onChange={(e) => setVatRate(parseInt(e.target.value))}>
                                                        {
                                                            company.vatRates.map((vatRateItem,i) => (
                                                                <option key={i} value={vatRateItem.rate}>{vatRateItem.rate}%</option>
                                                            ))
                                                        }
                                                        <option value={0}>Fără TVA</option>

                                                    </select>
                                                </label>
                                            </>
                                            :null
                                    }


                                </div>
                                <div className={"col-12 col-md-6"}>
                                    <label className={"mt-2"}>Avans
                                        <input type={"number"} className="form-control" value={advance}
                                               onFocus={handleFocus}
                                               onChange={(e) => setAdvance(parseInt(e.target.value) < 0 ? parseInt(e.target.value)*-1 : parseInt(e.target.value))}/>
                                    </label>
                                    <br/>
                                    <label className={"mt-2"}>Discount (%)
                                        <input type={"number"} className="form-control" value={discount}
                                               min={0}
                                               step={1}
                                               onFocus={handleFocus}
                                               onChange={(e) => setDiscount(parseInt(e.target.value) < 0 ? parseInt(e.target.value)*-1 : parseInt(e.target.value))}/>
                                    </label>
                                </div>
                            </div>
                        </div>
                        : null
                }
                <div className={"form-group"}>

                    <label>Tip comandă</label>

                    <label className={"transport-type-label"}>
                        <input type={"radio"} name={"transportType"} value={0}
                               onChange={(e) => setTransportType(0)}
                               checked={transportType === 0}
                        /> Primite la sediu
                    </label>

                    <label className={"transport-type-label"}>
                        <input type={"radio"} name={"transportType"} value={1}
                               onChange={(e) => setTransportType(1)}
                               checked={transportType === 1}
                        /> Programate de ridicat
                    </label>

                    <label className={"transport-type-label"}>
                        <input type={"radio"} name={"transportType"} value={2}
                               onChange={(e) => setTransportType(2)}
                               checked={transportType === 2}
                        /> În curs de ridicare
                    </label>
                </div>

                {
                    transportType === 1 || transportType === 2 ?
                        <div className={"form-group"}>
                            <label className={"mt-2"}>Cost transport
                                <input type={"number"} className="form-control" value={transport} onFocus={handleFocus}
                                       onChange={(e) => setTransport(parseInt(e.target.value) < 0 ? parseInt(e.target.value)*-1 : parseInt(e.target.value))}/>
                            </label>
                        </div>
                        : null
                }
                {
                    transportType === 1 || transportType === 2 ?
                        <div className={"form-group"}>
                            <label className={"mt-2"}>Șofer
                                <select value={driver} className={"form-control"}
                                        onChange={(e) => {
                                            setDriver(e.target.value)
                                        }}>
                                    <option value={0}>Fără șofer</option>

                                    {
                                        drivers.map((driver,i) => (
                                            <option key={i} value={driver._id} disabled={driver.orders >= 23}>{driver.name} {driver.orders >= 23 ? '(A atins numărul maxim de comenzi)' : ''}</option>
                                        ))
                                    }

                                </select>
                            </label>
                        </div>
                        : null
                }

                <div className={"form-group"}>
                    <label>Număr covoare</label>
                    {getItemsCountFields()}

                    <input type={"number"} className="form-control items-count-label field"
                           value={itemsCount} onFocus={handleFocus}
                           onChange={(e) => {

                               if(e.target.value < 0) {
                                   setItemsCount(0)
                                   setCurrentCarpets([])
                               } else {
                                   setItemsCount(parseInt(e.target.value))
                                   setCurrentCarpets(getEmptyCarpetsArray(e.target.value))
                               }

                           }}
                           placeholder={"..."}
                    />

                </div>

                {
                    (transportType === 0 || transportType === 2) && itemsCount > 0 ?
                        <>
                            <div className={"form-group mt-4"}>
                                <strong>Etichetare</strong> <span className={"red-tick"}>*</span>
                            </div>

                            <div className={"row"}>

                                <div className={"col-12"}>

                                    <div className={"form-group"}>
                                        <label>Categorie de preț
                                        <select className={"form-control"} value={activeDefaultPricing ? activeDefaultPricing.id : getDefaultCarpetPricing(company).id}
                                                onChange={(e) => {

                                                    const newActiveDefaultPricing = {...company.pricing[parseInt(e.target.value)], id: parseInt(e.target.value)}

                                                    //Set active default pricing
                                                    setActiveDefaultPricing(newActiveDefaultPricing)

                                                    //Update current carpets
                                                    currentCarpets.forEach((currentCarpetItem,i) => {
                                                        setCarpetValue(i,'pricing',newActiveDefaultPricing)
                                                    })

                                                }}>
                                            {
                                                company && company.pricing && company.pricing.map((pricingItem,i) => (
                                                    <option key={i} value={i}>{pricingItem.title} ({pricingItem.price} Lei)</option>
                                                ))
                                            }
                                        </select></label>
                                    </div>


                                    <div className={"form-group"}>
                                        <div className={"row"}>
                                            <div className={"col-3"}>
                                                Prefix
                                            </div>
                                            <div className={"col"}>
                                                <input type={"number"} className="form-control"
                                                       value={carpetsCodePrefix}
                                                       onChange={(e) => setCarpetsPrefix(e.target.value)}
                                                       placeholder={"ex. 372"}
                                                />
                                            </div>
                                        </div>
                                        {
                                            carpetsCodePrefixValid ?
                                                null
                                                :
                                                <div className={"alert alert-danger mt-1"}>Codul este deja folosit
                                                    !</div>
                                        }
                                    </div>

                                    {
                                        currentCarpets.length > 0 && currentCarpets.map((carpet, i) => (
                                            <div className={"form-group"} key={i}>
                                                <div className={"row"}>
                                                    <div className={"col-1"}>
                                                        {i + 1}.
                                                    </div>
                                                    <div className={"col"}>
                                                        <input type={"text"} className="form-control"
                                                               value={carpet.code}
                                                               onChange={(e) => setCarpetValue(i, 'code', e.target.value)}
                                                               placeholder={"ex. 3721"}
                                                        />
                                                    </div>
                                                    <div className={"col"}>
                                                        <select className={"form-control"} value={carpet.pricing ? carpet.pricing.id : 0}
                                                                onChange={(e) =>
                                                                    setCarpetValue(i, 'pricing', {...company.pricing[parseInt(e.target.value)], id: parseInt(e.target.value)})}>
                                                            {
                                                                company && company.pricing && company.pricing.map((pricingItem,i) => (
                                                                    <option key={i} value={i}>{pricingItem.title} ({pricingItem.price} Lei)</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                                <div className={"col border-start"}>

                                    {
                                        startQrScan ?
                                            <QrReader
                                                key={"dsa"}
                                                delay={100}
                                                style={{'width': '100%'}}
                                                onError={(err) => console.log(err)}
                                                onScan={(data) => {
                                                    if (data) {
                                                        setCarpetsPrefix(data.split('-')[0])
                                                        setStartQrScan(false)
                                                    }
                                                }}
                                            />
                                            :
                                            <div
                                                className={"text-center d-flex align-items-center justify-content-center h-100"}>
                                                <button
                                                    className={"btn btn-primary"}
                                                    type={"button"}
                                                    onClick={() => setStartQrScan(true)}
                                                >Scanează cod
                                                </button>
                                            </div>
                                    }

                                </div>
                            </div>

                        </>
                        : null
                }


                {
                    company.services && company.services.length > 0 ?

                        <div className={"form-group mt-3"}>
                            <div className={"row mb-3"}>
                                <div className={"col-12"}>
                                    <select className={"form-control btn btn-warning btn-add-service float-end"} value={""}
                                            onChange={(e) => addAdditionalService(e.target.value)}>

                                        <option value={""}>+ Adaugă serviciu</option>
                                        {
                                            company.services.map((service, j) => (
                                                <option key={j}
                                                    value={j}>{service.title} - {service.price} RON</option>
                                            ))
                                        }
                                    </select>

                                    <strong>Servicii adiționale</strong>
                                </div>
                            </div>

                            {
                                additionalServices.map((additionalService, i) => (
                                    <div className={"row mt-1"} key={i}>
                                        <div className={"col-6"}>
                                            <label>Denumire<br/>
                                                <strong className={"mt-1"}>{additionalService.title}</strong>
                                            </label>
                                        </div>
                                        <div className={"col-3"}>
                                            <label>Cantitate
                                                <input type={"number"} className="form-control"
                                                       value={additionalService.quantity} onFocus={handleFocus}
                                                       onChange={(e) => updateAdditionalService(i, 'quantity', e.target.value)}/>
                                            </label>
                                        </div>
                                        <div className={"col-3"}>
                                            <label></label>
                                            <button type={"button"} className={"btn btn-danger"}
                                                    onClick={() => deleteAdditionalService(i)}><FontAwesomeIcon
                                                icon={faTrash}/></button>
                                        </div>
                                    </div>
                                ))
                            }

                        </div>
                        : null
                }


                <div className="form-group">
                    <label>Mențiuni
                        <textarea className="form-control"
                                  onChange={(e) => setNotes(e.target.value)} value={notes}/>
                    </label>
                </div>

                <button type={"button"} className={"btn btn-success w-100 mt-5"}
                        disabled={addLoading}
                        onClick={addOrder}>
                    {
                        addLoading ?
                            <SmallLoadingComp/>
                            :
                            'Adaugă comanda'
                    }
                </button>
            </Modal>

            <Modal
                isOpen={secondModalIsOpen}
                onAfterOpen={() => {
                }}
                onRequestClose={() => setSecondModalIsOpen(false)}
                shouldCloseOnOverlayClick={false}
                style={customModalStyles}
                contentLabel="Example Modal"
            >
                <button className={"btn-close-modal"} onClick={() => {
                    setSecondModalIsOpen(false)
                    onClose()
                }}>
                    <FontAwesomeIcon icon={faTimes}/>
                </button>

                <h2>Printare proces verbal</h2>

                <div className={"row mt-3"}>
                    <div className={"col"}>
                        <strong>Comanda</strong><br/>
                        {currentOrder.number}<br/>
                    </div>
                    <div className={"col"}>
                        <strong>Status</strong><br/>
                        {getOrderStatusBadge(currentOrder.status)}<br/>
                    </div>
                    <div className={"col"}>
                        <strong>Client</strong><br/>
                        {currentOrder.name}<br/>
                        {currentOrder.phone}
                    </div>
                    <div className={"col"}>
                        <strong>Adresa</strong><br/>
                        {currentOrder.address}
                    </div>
                </div>

                <div className={"small-modal-btns"}>
                    <div className={"row mt-5"}>
                        <div className={"col-6"}>
                            <ReactToPrint
                                trigger={() => {
                                    return <button type={"button"} className={"btn btn-success w-100"}>
                                        <FontAwesomeIcon icon={faPrint}/> Printare PV A4
                                    </button>;
                                }}
                                content={() => pvPrintRef.current}
                            />
                        </div>
                        <div className={"col-6"}>
                            <a href={"my.bluetoothprint.scheme://https://api.totalcarpet.ro/api/print-pv/" + company._id + "/" + currentOrder._id}>
                                <button
                                    type={"button"}
                                    className={"btn btn-secondary w-100"}>
                                    <FontAwesomeIcon icon={faPrint}/> Printare PV termic
                                </button>
                            </a>
                        </div>
                    </div>


                    <button type={"button"} className={"btn btn-primary w-100 mt-5 small-modal-close-btn"} onClick={() => {
                        setSecondModalIsOpen(false)
                        onClose()
                    }}>
                        Finalizare
                    </button>

                </div>


                <div style={{display: 'none'}}>
                    <PvPrint
                        ref={pvPrintRef}
                        order={currentOrder}
                        company={company}
                    />
                </div>

            </Modal>

        </div>
    );
}
