import React, {useEffect, useState} from "react";
import {customModalStyles, server} from "../../const"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCaretDown, faEnvelope, faTimes} from '@fortawesome/free-solid-svg-icons'
import {toast} from "react-toastify";
import {getCarpetPrice, getMobileOperatingSystem, getSquareMeters, roundNumber} from "../utils"
import Modal from "react-modal";
import SmallLoadingComp from "../../components/loaders/SmallLoadingComp";
import DeleteOrderButton from "../DeleteOrderButton/DeleteOrderButton";

export default function OrderModalMeasure({currentOrder, showModal, setShowModal, onFinish}) {

    const token = localStorage.getItem('token')

    const [measureFormLoading, setMeasureFormLoading] = useState(false)
    const [currentCarpets, setCurrentCarpets] = useState(currentOrder.carpets)
    const [name, setName] = useState(currentOrder.name)
    const [phone, setPhone] = useState(currentOrder.phone)
    const [address, setAddress] = useState(currentOrder.address)
    const [notes, setNotes] = useState(currentOrder.notes)

    //const [customOrderPrice, setCustomOrderPrice] = useState('')
    const [services, setServices] = useState(currentOrder.services ? currentOrder.services : [])

    const [totalPrice, setTotalPrice] = useState(0)
    const [servicesPrice, setServicesPrice] = useState(0)
    const [transport, setTransport] = useState(currentOrder.transport)
    const [advance, setAdvance] = useState(currentOrder.advance)

    const [showMoreActions, setShowMoreActions] = useState(false)

    const [secondModalIsOpen, setSecondModalIsOpen] = useState(false);

    const [company, setCompany] = useState({})

    const getClient = () => {
        fetch(server + '/client/company', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            if (json) {
                setCompany(json)
            } else {
                toast.warning('A aparut o eroare!');
            }

        }).catch(e => {
            //toast.warning('A aparut o eroare!');
        });

    }

    useEffect(() => {
        getClient()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setName(currentOrder.name)
        setPhone(currentOrder.phone)
        setAddress(currentOrder.address)
        setNotes(currentOrder.notes)
        setCurrentCarpets(currentOrder.carpets)
        setTransport(currentOrder.transport)
        setServices(currentOrder.services)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[currentOrder])

    const saveMeasureForm = (status = 3) => {

        let valid = true;
        console.log(currentCarpets)

        currentCarpets.forEach((carpet,i) => {
            if(carpet.width === null || carpet.width.length === 0 || carpet.height === null || carpet.height.length === 0 || carpet.pricing === undefined) {
                valid = false
            }
        })

        setMeasureFormLoading(true)


        let data = {
            name: name,
            phone: phone,
            address: address,
            notes: notes,
            carpets: currentCarpets,
            transport: transport ? transport : 0,
            total: totalPrice + (servicesPrice ? servicesPrice : 0) + (transport ? transport : 0),
            servicesPrice: servicesPrice ? servicesPrice : 0,
            advance: advance ? advance : 0,
            services: services
        }

        if(valid) {
            data['status'] = status
        }

        fetch(server + '/client/orders/' + currentOrder._id, {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            if (json) {
                onFinish()
                setShowModal(false)
                if(valid) {
                    setSecondModalIsOpen(true)
                }
                setMeasureFormLoading(false)
            } else {
                toast.warning('A aparut o eroare!');
            }

        }).catch(e => {
            //toast.warning('A aparut o eroare!');
        });
    }

    const setCarpetValue = (i, param, value) => {

        let newCarpetsValues = [...currentCarpets]

        newCarpetsValues[i][param] = value

        setCurrentCarpets(newCarpetsValues)

    }

    const setServiceValue = (i, param, value) => {

        let newServices = [...services]

        if(value < 0) {
            value = 0
        }

        newServices[i][param] = value

        setServices(newServices)

    }

    const computeTotal = () => {

        if(!currentCarpets) {
            return;
        }

        let carpetsPrice = 0

        currentCarpets.length > 0 && currentCarpets.forEach((carpet, i) => {
            if(carpet.height && carpet.width) {
                carpetsPrice += getSquareMeters(carpet.height, carpet.width) * (
                    carpet.pricing && carpet.pricing.price ?
                        carpet.pricing.price:
                        getCarpetPrice(carpet, company)
                )
            }
        })

        let servicesPrice = 0
        if(services && services.length > 0) {
            services.forEach((service, i) => {
                servicesPrice  += service.price * service.quantity
            })
        }

        setServicesPrice(servicesPrice)
        setTotalPrice(roundNumber(carpetsPrice))
    }

    useEffect(() => {
        computeTotal()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentCarpets, services])

    return (<>

            <Modal
                isOpen={showModal}
                onAfterOpen={() => {
                }}
                onRequestClose={() => setShowModal(false)}
                shouldCloseOnOverlayClick={false}
                style={customModalStyles}
                contentLabel="Example Modal"
            >
                <button className={"btn-close-modal"} onClick={() => setShowModal(false)}>
                    <FontAwesomeIcon icon={faTimes}/>
                </button>

                <h2>Măsurare covoare</h2>

                <div className={"row"}>
                    <div className={"col-12 col-md-6"}>
                        <label>Nume</label>
                            <input type={"text"} className={"form-control"}
                                   value={name}
                                   onChange={(e) => setName(e.target.value)}
                                   />
                    </div>
                    <div className={"col-12 col-md-6"}>
                        <label>Telefon</label>
                            <input type={"number"} className={"form-control"}
                                   value={phone}
                                   onChange={(e) => setPhone(e.target.value)}
                            />
                    </div>
                    <div className={"col-12"}>
                        <label>Adresă</label>
                            <input type={"text"} className={"form-control"}
                                   value={address}
                                   onChange={(e) => setAddress(e.target.value)}
                            />

                    </div>
                </div>

                <div className={"row"}>

                    <div className={"col-12 "}>
                        <label>Mențiuni</label>
                        <textarea className={"form-control"}
                                  onChange={(e) => setNotes(e.target.value)}
                                  >{notes}</textarea>
                    </div>

                </div>


                <div className={"row"}>
                    <div className={"col-12 col-md-6"}>
                        <div className={"form-group mt-4"}>
                            <label className={"mt-2"}>Cost transport
                                <input type={"number"} className="form-control" value={transport}
                                       onChange={(e) => setTransport(parseInt(e.target.value))}/>
                            </label>
                        </div>
                    </div>
                    <div className={"col-12 col-md-6"}>
                        <div className={"form-group mt-4"}>
                            <label className={"mt-2"}>Avans
                                <input type={"number"} className="form-control" value={advance}
                                       onChange={(e) => setAdvance(parseInt(e.target.value))}/>
                            </label>
                        </div>
                    </div>
                </div>

                {
                    currentOrder.carpets && currentOrder.carpets.length > 0 ?
                        <>

                            <div className={"form-group mt-4"}>
                                <strong>Dimensiuni</strong>
                            </div>

                            <div className={"table-responsive"}>
                                <table className="table table-hover table-measure">
                                <thead>
                                <tr>
                                    <th scope="col"></th>
                                    <th scope="col" style={{minWidth:'60px'}}>Cod</th>
                                    <th scope="col" style={{minWidth:'60px'}}>Lungime</th>
                                    <th scope="col" style={{minWidth:'60px'}}>Lățime</th>
                                    <th scope="col" >Preț</th>
                                </tr>
                                </thead>
                                    <tbody>

                            {
                                currentCarpets.length > 0 && currentCarpets.map((carpet, i) => (
                                    <tr key={i}>
                                        <td>
                                            {i + 1}.
                                        </td>
                                        <td>
                                            <input type={"text"} className="form-control"
                                                   value={carpet.code}
                                                   onChange={(e) => setCarpetValue(i, 'code', e.target.value)}
                                                   placeholder={"etichetă"}
                                            />
                                        </td>
                                        <td>
                                            <input type={"number"} className="form-control"
                                                   value={carpet.width}
                                                   onChange={(e) => setCarpetValue(i, 'width', e.target.value < 0 ? 0 : e.target.value)}
                                                   placeholder={"lungime (cm)"}
                                            />
                                        </td>
                                        <td>
                                            <input type={"number"} className="form-control"
                                                   value={carpet.height}
                                                   onChange={(e) => setCarpetValue(i, 'height', e.target.value < 0 ? 0 : e.target.value)}
                                                   placeholder={"lățime (cm)"}
                                            />
                                        </td>
                                        <td>
                                            <select
                                                className={"form-control"}
                                                value={carpet.pricing ? carpet.pricing.id : 'default'}
                                                onChange={(e) => setCarpetValue(i, 'pricing', {...company.pricing[parseInt(e.target.value)], id: parseInt(e.target.value)})}>

                                                <option key={i} disabled value={'default'}></option>
                                                {
                                                    company && company.pricing && company.pricing.map((pricingItem,i) => (
                                                        <option key={i} value={i}>{pricingItem.title} ({pricingItem.price} Lei)</option>
                                                    ))
                                                }
                                            </select>
                                        </td>
                                    </tr>
                                ))
                            }
                                    </tbody>
                                </table>
                            </div>


                            <div className={"row"}>
                                <div className={"col-6"}>

                                        <div className={"btn btn-danger w-100"} onClick={() => {
                                            let newCarpets = [...currentCarpets]
                                            newCarpets.pop()
                                            setCurrentCarpets(newCarpets)
                                        }
                                        }>- Elimină covor</div>

                                </div>

                                <div className={"col-6"}>
                                    <div className={"btn btn-success w-100"} onClick={() => {
                                        let newCarpets = [...currentCarpets]
                                        newCarpets.push({
                                            'code': '',
                                            'width': '',
                                            'height': '',
                                            'pricing': 0
                                        })
                                        setCurrentCarpets(newCarpets)
                                    }
                                    }>+ Adaugă covor</div>

                                </div>
                            </div>

                        </>
                        : null
                }

                {
                    currentOrder.services && currentOrder.services.length > 0 ?
                        <>
                            <div className={"form-group mt-4"}>
                                <strong>Servicii adiționale</strong>
                            </div>


                            {
                                currentOrder.services.map((service, i) => (
                                    <div className={"row"} key={i}>
                                        <div className={"col-5"}>
                                            {service.title}
                                        </div>
                                        <div className={"col-4"}>
                                            <div className={"row"}>
                                                <div className={"col-3"}>
                                                    {service.quantity} x
                                                </div>
                                                <div className={"col"}>
                                                    <input type={"number"} className="form-control"
                                                           value={service.price}
                                                           onChange={(e) => setServiceValue(i, 'price', e.target.value)}
                                                           />
                                                </div>
                                                <div className={"col-1"}>
                                                    RON
                                                </div>
                                            </div>

                                        </div>
                                        <div className={"col-3 text-end"}>
                                            {service.quantity * service.price} RON
                                        </div>
                                    </div>
                                ))
                            }

                        </>
                        :null
                }

                <div className={"row mt-4"}>
                    <div className={"col-6"}>
                        Cost spălare covoare
                    </div>
                    <div className={"col-6 text-end"}>
                        {totalPrice} RON
                    </div>
                </div>


                <div className={"row"}>
                    <div className={"col-6"}>
                        Transport
                    </div>
                    <div className={"col-6 text-end"}>
                        {transport ? transport : 0} RON
                    </div>
                </div>

                <div className={"row mb-2"}>
                    <div className={"col-6"}>
                        Avans
                    </div>
                    <div className={"col-6 text-end"}>
                        {roundNumber(advance ? - advance : 0)} RON
                    </div>
                </div>

                <div className={"row border-top pt-2"}>
                    <div className={"col-6"}>
                        <strong>Total</strong>
                    </div>
                    <div className={"col-6 text-end"}>
                        {roundNumber(totalPrice + servicesPrice + (transport ? transport : 0) - (advance ? advance : 0))} RON
                    </div>
                </div>

                <button type={"button"} className={"btn btn-success w-100 mt-5"}
                        disabled={measureFormLoading}
                        onClick={() => saveMeasureForm()}>
                    {
                        measureFormLoading ?
                            <SmallLoadingComp/>
                            :
                            'Salvează'
                    }
                </button>


                <div className={"show-more-cont "} onClick={() => setShowMoreActions(!showMoreActions)}>
                    Vezi mai mult <FontAwesomeIcon icon={faCaretDown}/>
                </div>


                {
                    showMoreActions ?
                        <>
                            <button type={"button"} className={"btn btn-warning w-100 mt-3"}
                                    disabled={measureFormLoading}
                                    onClick={() => saveMeasureForm(3)}>
                                {
                                    measureFormLoading ?
                                        <SmallLoadingComp/>
                                        :
                                        'Mută la spălat'
                                }
                            </button>
                            <button type={"button"} className={"btn btn-warning w-100 mt-3"}
                                    disabled={measureFormLoading}
                                    onClick={() => saveMeasureForm(4)}>
                                {
                                    measureFormLoading ?
                                        <SmallLoadingComp/>
                                        :
                                        'Mută la uscat'
                                }
                            </button>
                            <button type={"button"} className={"btn btn-warning w-100 mt-3"}
                                    disabled={measureFormLoading}
                                    onClick={() => saveMeasureForm(5)}>
                                {
                                    measureFormLoading ?
                                        <SmallLoadingComp/>
                                        :
                                        'Mută la de livrat'
                                }
                            </button>
                            <button type={"button"} className={"btn btn-info w-100 mt-2"}
                                    disabled={measureFormLoading}
                                    onClick={() => saveMeasureForm(6)}>
                                {
                                    measureFormLoading ?
                                        <SmallLoadingComp/>
                                        :
                                        'Mută la finalizate'
                                }
                            </button>

                            <DeleteOrderButton
                                order={currentOrder}
                                afterDelete={() => {
                                    setShowModal(false)
                                    onFinish()
                                }}
                            />
                        </>
                        : null
                }


            </Modal>

            <Modal
                isOpen={secondModalIsOpen}
                onAfterOpen={() => {
                }}
                onRequestClose={() => setSecondModalIsOpen(false)}
                shouldCloseOnOverlayClick={false}
                style={customModalStyles}
                contentLabel="Example Modal"
            >
                <button className={"btn-close-modal"} onClick={() => setSecondModalIsOpen(false)}>
                    <FontAwesomeIcon icon={faTimes}/>
                </button>

                <h2>Trimite sms</h2>

                <div className={"row mt-4 mb-2"}>
                    <div className={"col"}>

                        <div className={"small-modal-btns"}>
                        {
                            company && company.templateSms1 ?

                                <a href={
                                    getMobileOperatingSystem() === 'Android' ?
                                        encodeURI("sms:" + currentOrder.phone + "?body=" + company.templateSms1.replace('[pret]', (roundNumber(totalPrice + servicesPrice + transport - currentOrder.advance))).replace('[nr]',(currentCarpets[0] && currentCarpets[0].code ? currentCarpets[0].code.split('-')[0]:'')))
                                        :
                                        encodeURI("sms:" + currentOrder.phone + "&body=" + company.templateSms1.replace('[pret]', (roundNumber(totalPrice + servicesPrice + transport - currentOrder.advance))).replace('[nr]',(currentCarpets[0] && currentCarpets[0].code ? currentCarpets[0].code.split('-')[0]:'')))
                                }>
                                    <button
                                        type={"button"}
                                        className={"btn btn-secondary w-100 mt-3"}>
                                        <FontAwesomeIcon icon={faEnvelope}/> Trimite sms
                                    </button>
                                </a>
                                : null
                        }

                            <button type={"button"} className={"btn btn-primary w-100 mt-5 small-modal-close-btn"} onClick={() => setSecondModalIsOpen(false)}>
                                Finalizare
                            </button>

                        </div>
                    </div>
                </div>

            </Modal>

        </>
    );
}
