import React, {useEffect, useState} from "react";
import {customModalStyles, server} from "../../../const"
import Modal from "react-modal"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSms,faTimes} from '@fortawesome/free-solid-svg-icons'
import {toast} from "react-toastify";

export default function SendSms() {
    const token = localStorage.getItem('token')

    const [showModal, setShowModal] = useState(false)

    const [company, setCompany] = useState(null)
    const [clients, setClients] = useState([])
    const [page, setPage] = useState(1)
    const [totalClients, setTotalClients] = useState(0)
    const [search, setSearch] = useState('')

    const [selectedClients, setSelectedClients] = useState([])
    const [sendToAll, setSendToAll] = useState(false)
    const [message, setMessage] = useState('')

    //const [clientsLoading, setClientsLoading] = useState(false)
    const [sendLoading, setSendLoading] = useState(false)

    const searchClients = (requestedPage) => {
        fetch(server + '/client/orders/clients?page=' + requestedPage + (search.length>0 ? '&search='+search : ''), {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            if (json) {
                if(requestedPage > 1) {
                    setClients([...clients, ...json['clients']])
                } else {
                    setClients(json['clients'])
                }
                setPage(requestedPage)
                setTotalClients(json['total'])
            } else {
                toast.warning('A aparut o eroare!');
            }
        }).catch(e => {
            //toast.warning('A aparut o eroare!');
        });

    }

    const getCompany = async () => {
        fetch(server + '/client/company', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            setCompany(json)

        }).catch(e => {
            //toast.warning('A aparut o eroare!');
        });
    }


    useEffect(() => {
        getCompany()
        searchClients(1)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        searchClients(1)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search])

    const toogleSelectedClient = (id) => {
        let newSelectedClients = [...selectedClients]

        if(newSelectedClients.includes(id)) {
            //Remove from list
            newSelectedClients = newSelectedClients.filter((item) => item !== id)
        } else {
            newSelectedClients.push(id)
        }
        setSelectedClients(newSelectedClients)
    }

    const sendSms = () => {

        //Validation
        if(!message || message.length === 0) {
           toast.error('Vă rugăm să introduceți un mesaj!')
            return
        }

        if(!sendToAll && selectedClients.length === 0) {
            toast.error('Vă rugăm să alegeți cel puțin un client!')
            return
        }
        setSendLoading(true)


        fetch(server + '/client/company/sendSms', {
            method: 'POST',
            body: JSON.stringify({
                message: message,
                sendToAll: sendToAll,
                selectedClients: selectedClients
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            if (json) {
                setMessage('')
                setSelectedClients([])
                setSendToAll(false)
               toast.success('Procesul de trimitere al sms-urilor a început!')
            } else {
                toast.warning('A aparut o eroare!');
            }
            setSendLoading(false)
        }).catch(e => {
            setSendLoading(false)
            //toast.warning('A aparut o eroare!');
        });

    }

        return (
        <div>
            <div className={"dashboard-big-btn-cont bg-info"}  onClick={() => {
                setShowModal(true)
            }}>
                <div className={"dashboard-big-btn-cont-icon"}>
                    <FontAwesomeIcon icon={faSms}/>
                </div>
                <div className={"dashboard-big-btn-cont-title"}>Campanie Marketing</div>
            </div>


            <Modal
                isOpen={showModal}
                onAfterOpen={() => {
                }}
                onRequestClose={() => setShowModal(false)}
                shouldCloseOnOverlayClick={false}
                style={customModalStyles}
                contentLabel="Example Modal"
            >
                <button className={"btn-close-modal"} onClick={() => setShowModal(false)}>
                    <FontAwesomeIcon icon={faTimes}/>
                </button>

                <h2>Campanie Marketing</h2>

                <div >
                    <label>Introduceți mesajul</label>
                    <textarea className={"form-control"}
                              onChange={(e) => setMessage(e.target.value)} value={message}/>

                    <br/>
                    <div className={"row"}>
                        <div className={"col-6"}>
                            <input type="text"
                                   className="form-control"
                                   placeholder="Caută client"
                                   value={search}
                                   onChange={(e) => {
                                       setSearch(e.target.value)
                                   }
                                   }
                            />
                        </div>
                        <div className={"col-6"}>
                            <label>
                                <input type={"checkbox"} value={"1"} checked={sendToAll} onChange={() => setSendToAll(!sendToAll)}/>      Trimite tuturor clienților ({totalClients})
                            </label>
                        </div>
                        <div className={"col-12"}>
                            <button className="btn btn-lg btn-primary mt-2 btn-block float-end" type="button" onClick={() => sendSms()}
                                    disabled={sendLoading}>Trimite ({sendToAll ? totalClients : selectedClients.length})</button>
                        </div>

                        {
                            company && company.name !== 'S.C. LYRA WASHING SRL' ?
                                <div className={"col-12 pt-2"}>
                                <p style={{color:"red"}}>Această funcționalitate nu este disponibilă pentru acest cont!</p>
                                </div>
                                :null
                        }
                    </div>


                </div>

                <div className={"row list-page-table mb-5 mt-0"}>
                    <div className={"col-12"}>
                        <div className={"table-responsive"}>
                            <table className="table table-hover">
                                <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col"></th>
                                    <th scope="col">Nume</th>
                                    <th scope="col">Telefon</th>
                                    <th scope="col">Adresă</th>
                                    <th scope="col">Nr. comenzi</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    clients ? clients.map((client, i) => (
                                            <tr key={i} onClick={() => toogleSelectedClient(client.phone)} className={"cursor-pointer"}>
                                                <th scope="row">{i + 1}</th>
                                                <td>
                                                    <input type={"checkbox"} value={"1"} checked={sendToAll || selectedClients.includes(client.phone)} onChange={() => {}}/>
                                                </td>
                                                <td>{client.name}</td>
                                                <td>{client.phone}</td>
                                                <td>{client.address}</td>
                                                <td className={"text-center"}>{client.countOrders}</td>
                                            </tr>
                                        ))
                                        : null
                                }

                                </tbody>
                            </table>

                            {
                                clients.length < totalClients ?
                                    <div className={"mb-5 w-100 text-center"}>
                                        <button type={"button"} className={"btn btn-secondary"} onClick={() => searchClients(page+1)}>Vezi mai mulți</button>
                                    </div>
                                    :null
                            }
                        </div>
                    </div>
                </div>


            </Modal>

        </div>
    );
}
