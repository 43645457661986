import {NavLink} from "react-router-dom";
import React, {useState} from "react";

export default function HeaderDriver({children}) {

    const logout = () => {
        localStorage.clear()
        window.location.href = "/"

    }

    const [menuVisible, setMenuVisible] = useState(false)

    return (
        <div>

            <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
                <div className="container-fluid">

                    <button className="navbar-toggler float-end" type="button" data-toggle="collapse" onClick={() => setMenuVisible(!menuVisible)}
                            data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarColor01" style={{display: (menuVisible ? 'block' : 'none')}}
                         onClick={() => setMenuVisible(false)}>
                        <ul className="navbar-nav me-auto">
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/" exact>Acasă</NavLink>
                            </li>

                            <li className="nav-item">
                                <NavLink className="nav-link" to="/orders-today" exact>Comenzi de ridicat/livrat astăzi</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/orders-pickup" exact>Comenzi de ridicat</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/orders-delivery" exact>Comenzi de livrat</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/orders-all" exact>Toate comenzile</NavLink>
                            </li>
                            {
                                localStorage.getItem('activateInvoicing') === 'true' ?
                                    <>
                                        <li className="nav-item">
                                            <NavLink className="nav-link" to="/balance" exact>Sold</NavLink>
                                        </li>
                                    </>
                                    : null
                            }
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/clients">Clienți</NavLink>
                            </li>
                        </ul>
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <div className="nav-link" onClick={() => logout()}>Ieșire</div>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <div>
                {children}
            </div>
        </div>
    );
}



