import {useEffect, useState} from "react";
import {customModalStyles, server} from "../../const"
import {toast, ToastContainer} from "react-toastify";
import LoadingComp from "../../components/loaders/LoadingComp";
import Modal from "react-modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes, faTrash} from "@fortawesome/free-solid-svg-icons";
import SmallLoadingComp from "../../components/loaders/SmallLoadingComp";
import {confirmAlert} from "react-confirm-alert";

export default function Employees() {

    const token = localStorage.getItem('token')

    const [employeeId, setEmployeeId] = useState(0)
    const [employees, setEmployees] = useState([])
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [role, setRole] = useState('driver')


    const [addLoading, setAddLoading] = useState(false)
    const [modalIsOpen, setIsOpen] = useState(false);
    const [editModalIsOpen, setEditModalIsOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const [deleteLoading, setDeleteLoading] = useState(false)

    const getEmployees = () => {
        fetch(server + '/client/employees', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            if (json) {
                setEmployees(json)
                setLoading(false)
            } else {
                toast.warning('A aparut o eroare!');
            }

        }).catch(e => {
            //toast.warning('A aparut o eroare!');
        });
    }
    useEffect(() => {

        getEmployees()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const addEmployee = async (e) => {
        e.preventDefault()
        setAddLoading(true)


        fetch(server + '/client/employees', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify({
                name: name,
                role: role,
                email: email,
                password: password
            })
        }).then(response => {
            return response.json()
        }).then(json => {
            if (json) {

                setName('')
                setEmail('')
                setPassword('')
                setRole('driver')
                getEmployees()
                setIsOpen(false)
            } else {
                toast.warning('A aparut o eroare!');
            }
            setAddLoading(false)

        }).catch(e => {
            setAddLoading(false)
            toast.warning('A aparut o eroare!');
        });
    }

    const editEmployee = async (e) => {
        e.preventDefault()
        setAddLoading(true)


        fetch(server + '/client/employees/'+employeeId, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify({
                name: name,
                role: role,
                email: email,
                password: password
            })
        }).then(response => {
            return response.json()
        }).then(json => {
            if (json) {

                setName('')
                setEmail('')
                setPassword('')
                setRole('driver')
                getEmployees()
                setEditModalIsOpen(false)
            }
            setAddLoading(false)

        }).catch(e => {
            setAddLoading(false)
        });
    }

    const deleteEmployee = async (e) => {
        setDeleteLoading(true)

        fetch(server + '/client/employees/'+employeeId, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        }).then(response => {
            return response.json()
        }).then(json => {
            setDeleteLoading(false)
            setEditModalIsOpen(false)
            getEmployees()
        }).catch(e => {
            setDeleteLoading(false)
            toast.warning('A aparut o eroare!');
        });
    }


    return (
        <div className={"container"}>

            {loading ?
                <LoadingComp/>
                :
                <>
                    <div className={"float-end"}>
                        <button type={"button"} className={"btn btn-primary"} onClick={() => setIsOpen(true)}>Adaugă
                            angajat
                        </button>
                    </div>
                    <h2 className={"list-page-title"}>Angajați</h2>

                    <div className={"row list-page-table"}>
                        <div className={"col-12"}>
                            <div className={"table-responsive"}>
                            <table className="table table-hover">
                                <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Tip</th>
                                    <th scope="col">Nume</th>
                                    <th scope="col">Email</th>
                                    <th scope="col"></th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    employees ? employees.map((employee, i) => (
                                            <tr key={i}>
                                                <th scope="row">{i + 1}</th>
                                                <td>{
                                                    employee.role === 'driver' ?
                                                        'Șofer'
                                                        :
                                                        employee.role === 'laundry' ?
                                                            'Spălător'
                                                            :
                                                            employee.role === 'client' ?
                                                                'Administrator'
                                                                :
                                                                ''
                                                }</td>
                                                <td>{employee.name}</td>
                                                <td>{employee.email}</td>
                                                <td>
                                                    <button type={"button"} className={"btn btn-primary"}
                                                    onClick={() => {
                                                        setName(employee.name)
                                                        setEmail(employee.email)
                                                        setRole(employee.role)
                                                        setEditModalIsOpen(true)
                                                        setEmployeeId(employee._id)
                                                        setPassword('')
                                                    }}>
                                                        Editare
                                                    </button>
                                                </td>
                                            </tr>
                                        ))
                                        : null
                                }

                                </tbody>
                            </table>
                            </div>
                        </div>
                    </div>

                </>
            }

            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={() => {
                }}
                onRequestClose={() => setIsOpen(false)}
                shouldCloseOnOverlayClick={false}
                style={customModalStyles}
                contentLabel="Example Modal"
            >
                <button className={"btn-close-modal"} onClick={() => setIsOpen(false)}>
                    <FontAwesomeIcon icon={faTimes}/>
                </button>

                <h2>Adaugă angajat</h2>

                <form onSubmit={(e) => addEmployee(e)}>

                    <div className={"row mt-4"}>
                        <div className={"col"}>
                            <div className="form-group">
                                <label>Tip

                                    <select name="role" id="role" className="form-control" required value={role}
                                            onChange={(e) => setRole(e.target.value)}>
                                        <option value='driver'>Șofer</option>
                                        <option value='laundry'>Operator</option>
                                        <option value='client'>Administrator</option>
                                    </select>

                                </label>
                            </div>

                        </div>
                    </div>
                    <div className={"row mt-2"}>
                        <div className={"col"}>
                            <div className="form-group">
                                <label>Nume
                                    <input type="text" className="form-control" value={name} required placeholder={"Introduceți numele"}
                                           onChange={(e) => setName(e.target.value)}/>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className={"row mt-2"}>
                        <div className={"col"}>
                            <div className="form-group">
                                <label>Email
                                    <input type="email" name="email" id="inputEmail" className="form-control mb-2" placeholder={"Introduceți adresa de email"}
                                           required autoFocus value={email} onChange={(e) => setEmail(e.target.value)}/>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className={"row mt-2"}>
                        <div className={"col"}>
                            <div className="form-group">
                                <label>Parolă
                                    <input type="password" name="password" id="inputPassword" className="form-control mb-4" placeholder={"Introduceți parola"}
                                           required value={password} onChange={(e) => setPassword(e.target.value)}/>
                                </label>
                            </div>

                        </div>
                    </div>


                    <button type={"submit"} className={"btn btn-success w-100 mt-5"}
                            disabled={addLoading}>
                        {
                            addLoading ?
                                <SmallLoadingComp/>
                                :
                                'Adaugă angajat'
                        }
                    </button>
                    <ToastContainer />
                </form>
            </Modal>

            <Modal
                isOpen={editModalIsOpen}
                onAfterOpen={() => {
                }}
                onRequestClose={() => setEditModalIsOpen(false)}
                shouldCloseOnOverlayClick={false}
                style={customModalStyles}
                contentLabel="Example Modal"
            >
                <button className={"btn-close-modal"} onClick={() => setEditModalIsOpen(false)}>
                    <FontAwesomeIcon icon={faTimes}/>
                </button>

                <h2>Modificare cont </h2>

                <form autoComplete={"off"} onSubmit={(e) => editEmployee(e)}>

                    <div className={"row mt-4"}>
                        <div className={"col"}>

                            <button
                                type={"button"}
                                onClick={() =>  confirmAlert({
                                    title: 'Confirmare ștergere',
                                    message: 'Sunteți sigur că doriți să ștergeți acest cont?',
                                    buttons: [
                                        {
                                            label: 'Da',
                                            onClick: () => deleteEmployee()
                                        },
                                        {
                                            label: 'Nu',
                                            onClick: () => {}
                                        }
                                    ]
                                })}
                                disabled={deleteLoading}
                                className={"btn btn-danger "}>
                                <FontAwesomeIcon icon={faTrash}/> Șterge contul
                            </button>

                            <div className="form-group">
                                <label>Tip

                                    <select name="role" id="role" className="form-control" required value={role}
                                            onChange={(e) => setRole(e.target.value)}>
                                        <option value='driver'>Șofer</option>
                                        <option value='laundry'>Operator</option>
                                    </select>

                                </label>
                            </div>

                        </div>
                    </div>
                    <div className={"row mt-2"}>
                        <div className={"col"}>
                            <div className="form-group">
                                <label>Nume
                                    <input type="text" className="form-control" value={name} required placeholder={"Introduceți numele"}
                                           onChange={(e) => setName(e.target.value)}/>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className={"row mt-2"}>
                        <div className={"col"}>
                            <div className="form-group">
                                <label>Email
                                    <input type="email" name="email" id="inputEmail" className="form-control mb-2" placeholder={"Introduceți adresa de email"}
                                           required autoFocus value={email} onChange={(e) => setEmail(e.target.value)}/>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className={"row mt-2"}>
                        <div className={"col"}>
                            <div className="form-group">
                                <label>Schimbare parolă
                                    <input type="password" autoComplete={"off"} name="changePassword2021312" id="changePassword2021312" className="form-control mb-4" placeholder={"Schimbare parola"}
                                            value={password} onChange={(e) => setPassword(e.target.value)}/>
                                </label>
                            </div>

                        </div>
                    </div>


                    <button type={"submit"} className={"btn btn-success w-100 mt-5"}
                            disabled={addLoading}>
                        {
                            addLoading ?
                                <SmallLoadingComp/>
                                :
                                'Salvează'
                        }
                    </button>
                    <ToastContainer />
                </form>
            </Modal>

        </div>
    );
}
