import React, {useState, useEffect} from 'react'
import {server} from "../../../const";
import {toast} from "react-toastify";
import MediumLoadingComp from "../../../components/loaders/MediumLoadingComp";
import {NavLink} from "react-router-dom";
import {pulse} from 'react-animations'
import Radium, {StyleRoot} from 'radium';

export default function Today() {

    const ordersWebsite = localStorage.getItem('ordersWebsite') ? parseInt(localStorage.getItem('ordersWebsite')) : 0

    const styles = {
        pulse: {
            animation: 'x 1s',
            animationDelay: 1,
            animationIterationCount: 20,
            animationName: Radium.keyframes(pulse, 'pulse')
        }
    }

    const token = localStorage.getItem('token')

    const [orders, setOrders] = useState(0)
    const [websiteOrders, setWebsiteOrders] = useState(0)

    const [loading, setLoading] = useState(true)

    const getData = () => {
        fetch(server + '/client/charts/today', {
            method: 'POST',
            body: JSON.stringify({}),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            if (json) {
                setOrders(json['orders'])
                setWebsiteOrders(json['websiteOrders'])
                setLoading(false)
            } else {
                toast.warning('A aparut o eroare!');
            }

        }).catch(e => {
            //toast.warning('A aparut o eroare!');
        });
    }

    useEffect(() => {
        getData()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <>
            <div>
                {
                    loading ?
                        <MediumLoadingComp/>
                        :

                        <div className={"row pt-2"}>


                            <div className={"col-6"}>
                                <NavLink to="/orders-today" exact>
                                    <div className={"text-center"}>
                                        <div className={"dashboard-badge badge bg-success mb-2"}>{orders}</div>
                                        <br/>
                                        <h4>Comenzi noi astăzi</h4>
                                    </div>
                                </NavLink>
                            </div>


                            <div className={"col-6"}>
                                <NavLink to="/orders-from-website" exact>
                                    <StyleRoot>
                                        <div className={"text-center position-relative"} style={websiteOrders > 0 && ordersWebsite !== websiteOrders ? styles.pulse : {}}>
                                            {
                                                websiteOrders > 0 && ordersWebsite !== websiteOrders ?
                                                <div className={"badge bg-danger badge-chart-website-orders"}>NOU</div>
                                                :null
                                            }
                                            <div
                                                className={"dashboard-badge badge mb-2 " + (websiteOrders > 0 && ordersWebsite !== websiteOrders ? 'bg-danger' : 'bg-success')}>{websiteOrders}</div>
                                            <br/>
                                            <h4 style={(websiteOrders > 0 && ordersWebsite !== websiteOrders ? {color:'red'} : {})}>Comenzi website</h4>
                                        </div>
                                    </StyleRoot>
                                </NavLink>
                            </div>


                        </div>
                }
            </div>
        </>
    )
}