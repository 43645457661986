import React, {useEffect, useState} from "react";
import {customModalStyles, server} from "../../const"
import Modal from "react-modal"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTimes} from '@fortawesome/free-solid-svg-icons'
import {toast} from "react-toastify";
import SmallLoadingComp from "../../components/loaders/SmallLoadingComp";

export default function EditDriver({modalIsOpen,currentOrder,setShowModal,onFinish}) {
    const token = localStorage.getItem('token')

    const [addLoading, setAddLoading] = useState(false)

    const [driver, setDriver] = useState(currentOrder.driver ? currentOrder.driver : 0)
    const [drivers, setDrivers] = useState([])


    const editDriver = async () => {
        setAddLoading(true)

        fetch(server + '/client/orders/updateDriver', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify({
                driver: driver,
                order: currentOrder._id
            })
        }).then(response => {
            return response.json()
        }).then(json => {
            if (json) {
                setDriver(0)
                setShowModal(false)
                onFinish()
                getDrivers()
            } else {
                toast.warning('A aparut o eroare!');
            }
            setAddLoading(false)

        }).catch(e => {
            setAddLoading(false)
            toast.warning('A aparut o eroare!');
        });
    }


    const getDrivers = () => {
        fetch(server + '/client/employees?count_orders=1&role=driver', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            if (json) {
                setDrivers(json.filter((item) => item.role === 'driver'))
            } else {
                toast.warning('A aparut o eroare!');
            }

        }).catch(e => {
            //toast.warning('A aparut o eroare!');
        });
    }
    useEffect(() => {
        getDrivers()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={() => {
                }}
                style={customModalStyles}
                onRequestClose={() => setShowModal(false)}
                shouldCloseOnOverlayClick={false}
                contentLabel="Example Modal"
            >
                <button className={"btn-close-modal"} onClick={() => {
                    setShowModal(false)
                   // onClose()
                }}>
                    <FontAwesomeIcon icon={faTimes}/>
                </button>

                <h2>Modifică soferul</h2>

                        <div className={"form-group"}>
                            <label className={"mt-2"}>Șofer
                                <select value={driver} className={"form-control"}
                                        onChange={(e) => {
                                            setDriver(e.target.value)
                                        }}>
                                    <option value={0}>Fără șofer</option>

                                    {
                                        drivers.map((driver,i) => (
                                            <option key={i} value={driver._id} disabled={driver.orders >= 23}>{driver.name} {driver.orders >= 23 ? '(A atins numărul maxim de comenzi)' : ''}</option>
                                        ))
                                    }

                                </select>
                            </label>
                        </div>

                <button type={"button"} className={"btn btn-success w-100 mt-5"}
                        disabled={addLoading}
                        onClick={() => editDriver()}>
                    {
                        addLoading ?
                            <SmallLoadingComp/>
                            :
                            'Salvează'
                    }
                </button>
            </Modal>

        </>
    );
}
