import React from "react";
import './style.css'
import moment from "moment";

export class PvPrint extends React.PureComponent {

    render() {
        return (
            <div className={"pv-container"}>

                <div className={"row"}>
                    <div className={"col text-center"}>
                        <h2 className={"text-center font-weight-bold"}>PROCES - VERBAL<br/> DE PREDARE PRIMIRE COVOR</h2>

                        <br/><br/>

                        <div className={"pv-date"}>Încheiat astăzi {moment().format("DD/MM/YYYY")}</div>
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col"}>
                        <br/>
                        <p>
                            Între <strong>{this.props.company.name}</strong>, având punctul de lucru
                            în <strong>{this.props.company.address}</strong> și
                            Domnul/Doamna <strong>{this.props.order.name}</strong> în calitate de beneficiar,
                            telefon <strong>{this.props.order.phone}</strong> prin prezentul proces verbal se atestă că au fost predate
                            un număr de <strong>{this.props.order.itemsCount}</strong> covoare pentru a fi curățate și
                            spălate având următoarele caracteristici:
                        </p>
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col"}>

                        <table className="table">
                            <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Etichetă</th>
                                <th scope="col">Observații</th>
                            </tr>
                            </thead>
                            <tbody>

                            {
                                this.props.order.carpets.map((carpet,i) => (
                                    <tr>
                                        <th scope="row">{i+1}</th>
                                        <td>{carpet.code}</td>
                                        <td></td>
                                    </tr>
                                ))
                            }

                            </tbody>
                        </table>

                        {
                            this.props.order.services && this.props.order.services.length > 0 ?
                                <>
                                    <strong>Servicii adiționale</strong>
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Denumire</th>
                                        <th scope="col">Cantitate</th>
                                        <th scope="col">Preț unitar</th>
                                        <th scope="col">Preț total</th>
                                    </tr>
                                    </thead>
                                    <tbody>

                                    {
                                        this.props.order.services.map((service,i) => (
                                            <tr>
                                                <th scope="row">{i+1}</th>
                                                <td>{service.title}</td>
                                                <td>{service.quantity}</td>
                                                <td>{service.price}</td>
                                                <td>{service.quantity * service.price}</td>
                                                <td></td>
                                            </tr>
                                        ))
                                    }

                                    </tbody>
                                </table>
                                </>
                                :null
                        }

                        <br/>

                        {
                            this.props.order.advance > 0 ?
                                <>
                                    <p>
                                        La predare s-a achitat suma de <strong>{this.props.order.advance}</strong> Lei, reprezentând contravaloare totală/parțială
                                        a serviciului prestat, urmând ca la ridicare să se achite diferența rămasă de plată.
                                    </p>

                                
                                </>

                                :null
                        }

                        <p>{this.props.company.pvMentions}</p>

                    </div>
                </div>





            </div>
        );
    }
}