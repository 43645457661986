import AddOrder from "../../components/forms/AddOrder";
import QrScanner from "../../components/forms/QrScanner/QrScanner";
import React from "react";
import Search from "../../components/forms/Search";

export default function DashboardDriver() {


    return (
        <div className={"container pb-5"}>

            <div className={"row"}>
                <div className={"col-12 col-md-4"}>
                    <AddOrder
                        laundry={true}
                        dashboardItem={true}
                    />
                </div>
                <div className={"col-12 col-md-2"}>
                    <QrScanner/>
                </div>
            </div>

            <div className={"row"}>
                <div className={"col-12 dashboard-search-cont"}>
                    <div className={"dashboard-item-cont"}>
                        <h2>Căutare comandă</h2>
                        <Search/>
                    </div>
                </div>
            </div>

        </div>
    );
}
