import React, {useEffect, useState} from "react";
import {server} from "../../const"
import {toast} from "react-toastify";
import LoadingComp from "../../components/loaders/LoadingComp";
import AddOrder from "../../components/forms/AddOrder";

export default function Clients({laundry= false, driver = false}) {


    const token = localStorage.getItem('token')


    const [clients, setClients] = useState([])
    const [page, setPage] = useState(1)
    const [totalClients, setTotalClients] = useState(0)
    const [search, setSearch] = useState('')

    const [selectedClient, setSelectedClient] = useState(false)

    const [loading, setLoading] = useState(true)

    const searchClients = (requestedPage) => {
        fetch(server + '/client/orders/clients?page=' + requestedPage + (search.length>0 ? '&search='+search : ''), {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            if (json) {
                if(requestedPage > 1) {
                    setClients([...clients, ...json['clients']])
                } else {
                    setClients(json['clients'])
                }
                setPage(requestedPage)
                setTotalClients(json['total'])
            } else {
                toast.warning('A aparut o eroare!');
            }
            setLoading(false)
        }).catch(e => {
            //toast.warning('A aparut o eroare!');
        });

    }


    useEffect(() => {
        searchClients(1)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        searchClients(1)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search])

    return (
        <div className={"container"}>

            {loading ?
                <LoadingComp/>
                :
                <>

                    <div className="float-end">
                        <input type="text"
                               className="form-control input-search"
                               placeholder="Caută client"
                               value={search}
                               onChange={(e) => {
                                   setSearch(e.target.value)
                               }
                               }
                        />
                    </div>

                    <h2 className={"list-page-title"}>Clienți</h2>

                    <div className={"row list-page-table mb-5"}>
                        <div className={"col-12"}>
                            <div className={"table-responsive"}>
                            <table className="table table-hover">
                                <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col"></th>
                                    <th scope="col">Nume</th>
                                    <th scope="col">Telefon</th>
                                    <th scope="col">Adresă</th>
                                    <th scope="col">Nr. comenzi</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    clients ? clients.map((client, i) => (
                                            <tr key={i}>
                                                <th scope="row">{i + 1}</th>
                                                <td>
                                                    {
                                                        driver ?
                                                            null
                                                            :
                                                            <button type={"button"} className={"btn btn-primary"} onClick={() => setSelectedClient(client)}>Adaugă
                                                                comandă
                                                            </button>
                                                    }
                                                </td>
                                                <td>{client.name}</td>
                                                <td>{client.phone}</td>
                                                <td>{client.address}</td>
                                                <td className={"text-center"}>{client.countOrders}</td>
                                            </tr>
                                        ))
                                        : null
                                }

                                </tbody>
                            </table>

                                {
                                    clients.length < totalClients ?
                                        <div className={"mb-5 w-100 text-center"}>
                                            <button type={"button"} className={"btn btn-secondary"} onClick={() => searchClients(page+1)}>Vezi mai mulți</button>
                                        </div>
                                        :null
                                }
                            </div>
                        </div>
                    </div>


                </>
            }

            {
                selectedClient ?
                    <AddOrder
                        client={selectedClient}
                        laundry={laundry}
                        opened={true}
                        onClose={() => setSelectedClient(false)}
                    />
                    : null
            }



        </div>
    );
}
