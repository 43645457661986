import AddOrder from "../../components/forms/AddOrder";
import QrScanner from "../../components/forms/QrScanner/QrScanner";
import ExpensesChart from "./Charts/Expenses";
import ExpensesIncomeChart from "./Charts/ExpensesIncomeChart";
import Today from "./Charts/Today";
import React from "react";
import Search from "../../components/forms/Search";
import IncomesChart from "./Charts/Incomes";
import SendSms from "../../components/marketing/SendSms/SendSms";

export default function DashboardClient() {


    return (
        <div className={"container pb-5"}>

            <div className={"row"}>
                <div className={"col-12 col-md-4"}>
                        <AddOrder dashboardItem={true} />
                </div>
                <div className={"col-12 col-md-6"}>
                    <div className={"dashboard-item-cont"}>
                        <Today/>
                    </div>
                </div>

                <div className={"col-12 col-md-2"}>
                    <QrScanner/>
                </div>
            </div>

            <div className={"row"}>
                <div className={"col-12 col-md-8 dashboard-search-cont"}>
                    <div className={"dashboard-item-cont"}>
                        <h2>Căutare comandă</h2>
                       <Search/>

                    </div>
                </div>
                <div className={"col-12 col-md-4"}>
                    <SendSms/>
                </div>
            </div>

            <div className={"row"}>

                <div className={"col-12"}>
                    <div className={"dashboard-item-cont"}>
                        <IncomesChart/>
                    </div>
                </div>
                <div className={"col-12"}>
                    <div className={"dashboard-item-cont"}>
                        <ExpensesChart/>
                    </div>
                </div>
                <div className={"col-12"}>
                    <div className={"dashboard-item-cont"}>
                        <ExpensesIncomeChart/>
                    </div>
                </div>
            </div>


        </div>
    );
}
