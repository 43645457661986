import './App.css';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import Login from "./Login/Login";
import Dashboard from "./Pages/admin/Dashboard";
import HeaderAdmin from "./components/headers/HeaderAdmin";
import ClientsList from "./Pages/admin/ClientsList";
import ClientRegistration from "./Pages/admin/ClientRegistration";
import HeaderClient from "./components/headers/HeaderClient";
import DashboardClient from "./Pages/client/Dashboard";
import CompanyEdit from "./Pages/client/CompanyEdit";
import Modal from "react-modal";
import OrdersPickup from "./Pages/client/OrdersPickup";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import OrdersMeasure from "./Pages/client/OrdersMeasure";
import 'react-toastify/dist/ReactToastify.css';
import OrdersWash from "./Pages/client/OrdersWash";
import Employees from "./Pages/client/Employees";
import HeaderDriver from "./components/headers/HeaderDriver";
import HeaderLaundry from "./components/headers/HeaderLaundry";
import OrdersDry from "./Pages/client/OrdersDry";
import OrdersDelivery from "./Pages/client/OrdersDelivery";
import OrdersCompleted from "./Pages/client/OrdersCompleted";
import Expenses from "./Pages/client/Expenses";
import Clients from "./Pages/client/Clients";
import DashboardLaundry from "./Pages/laundry/Dashboard";
import DryRoom from "./Pages/laundry/DryRoom";
import OrdersToday from "./Pages/client/OrdersToday";
import Incomes from "./Pages/client/Incomes";
import DashboardDriver from "./Pages/driver/DashboardDriver";
import OrdersWebsite from "./Pages/client/OrdersWebsite";
import 'react-confirm-alert/src/react-confirm-alert.css';
import ChangeDeliveryDate from "./Pages/public/ChangeDeliveryDate";
import OrdersAll from "./Pages/client/OrdersAll";
import "react-datepicker/dist/react-datepicker.css";
import OrdersNotPaid from "./Pages/client/OrdersNotPaid";
import DriversBalance from "./Pages/client/DriversBalance";
import Balance from "./Pages/driver/Balance";
import OrdersTodayDriver from "./Pages/driver/OrdersToday";

Modal.setAppElement('body');

function App() {

    const token = localStorage.getItem('token')
    const role = localStorage.getItem('user_role')

    // If user
    if (token) {
        if (role === 'admin') {
            return (
                <BrowserRouter>
                    <HeaderAdmin>
                        <Switch>
                            <Route exact path="/">
                                <Dashboard/>
                            </Route>
                            <Route exact path="/clients">
                                <ClientsList/>
                            </Route>
                            <Route exact path="/add-client">
                                <ClientRegistration/>
                            </Route>
                        </Switch>
                    </HeaderAdmin>
                </BrowserRouter>
            );
        } else if (role === 'client') {
            return (
                <BrowserRouter>
                    <HeaderClient>
                        <Switch>
                            <Route exact path="/">
                                <DashboardClient/>
                            </Route>
                            <Route exact path="/orders-today">
                                <OrdersToday/>
                            </Route>
                            <Route exact path="/orders-from-website">
                                <OrdersWebsite/>
                            </Route>
                            <Route exact path="/orders-pickup">
                                <OrdersPickup/>
                            </Route>
                            <Route exact path="/orders-measure">
                                <OrdersMeasure/>
                            </Route>
                            <Route exact path="/orders-wash">
                                <OrdersWash/>
                            </Route>
                            <Route exact path="/orders-dry">
                                <OrdersDry/>
                            </Route>
                            <Route exact path="/orders-delivery">
                                <OrdersDelivery/>
                            </Route>
                            <Route exact path="/orders-completed">
                                <OrdersCompleted/>
                            </Route>
                            <Route exact path="/orders-not-paid">
                                <OrdersNotPaid/>
                            </Route>
                            <Route exact path="/drivers-balance">
                                <DriversBalance/>
                            </Route>
                            <Route exact path="/orders-all">
                                <OrdersAll/>
                            </Route>
                            <Route exact path="/dry-room">
                                <DryRoom/>
                            </Route>
                            <Route exact path="/expenses">
                                <Expenses/>
                            </Route>
                            <Route exact path="/incomes">
                                <Incomes/>
                            </Route>
                            <Route exact path="/clients">
                                <Clients/>
                            </Route>
                            <Route exact path="/company">
                                <CompanyEdit/>
                            </Route>
                            <Route exact path="/employees">
                                <Employees/>
                            </Route>
                        </Switch>
                    </HeaderClient>
                </BrowserRouter>
            );
        } else if (role === 'driver'){
            return (
                <BrowserRouter>
                    <HeaderDriver>
                        <Switch>
                            <Route exact path="/">
                                <DashboardDriver/>
                            </Route>

                            <Route exact path="/orders-today">
                                <OrdersTodayDriver/>
                            </Route>
                            <Route exact path="/orders-pickup">
                                <OrdersPickup/>
                            </Route>
                            <Route exact path="/orders-delivery">
                                <OrdersDelivery/>
                            </Route>
                            <Route exact path="/orders-all">
                                <OrdersAll/>
                            </Route>
                            <Route exact path="/balance">
                                <Balance/>
                            </Route>
                            <Route exact path="/clients">
                                <Clients driver={true}/>
                            </Route>
                        </Switch>
                    </HeaderDriver>

                </BrowserRouter>
            );
        } else if (role === 'laundry'){
            return (
                <BrowserRouter>
                    <HeaderLaundry>
                        <Switch>
                            <Route exact path="/">
                                <DashboardLaundry/>
                            </Route>
                            <Route exact path="/orders-today">
                                <OrdersToday/>
                            </Route>
                            <Route exact path="/orders-from-website">
                                <OrdersWebsite/>
                            </Route>
                            <Route exact path="/orders-pickup">
                                <OrdersPickup/>
                            </Route>
                            <Route exact path="/orders-measure">
                                <OrdersMeasure/>
                            </Route>
                            <Route exact path="/orders-wash">
                                <OrdersWash/>
                            </Route>
                            <Route exact path="/orders-dry">
                                <OrdersDry/>
                            </Route>
                            <Route exact path="/orders-delivery">
                                <OrdersDelivery/>
                            </Route>
                            <Route exact path="/orders-completed">
                                <OrdersCompleted/>
                            </Route>
                            <Route exact path="/orders-not-paid">
                                <OrdersNotPaid/>
                            </Route>
                            <Route exact path="/dry-room">
                                <DryRoom/>
                            </Route>
                            <Route exact path="/orders-all">
                                <OrdersAll/>
                            </Route>
                            <Route exact path="/clients">
                                <Clients laundry={true} />
                            </Route>
                        </Switch>
                    </HeaderLaundry>
                </BrowserRouter>
            );

        }
    } else {

        return (
            <BrowserRouter>
                <Switch>
                    <Route exact path="/">
                        <Login/>
                    </Route>
                    <Route exact path="/changeDeliveryDate/:companyId/:orderId" component={ChangeDeliveryDate}/>
                </Switch>
            </BrowserRouter>
        );

    }
}

export default App;
