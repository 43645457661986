import React, {useEffect, useRef, useState} from "react";
import {customModalStyles, server} from "../../const"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEnvelope, faPrint, faTimes} from '@fortawesome/free-solid-svg-icons'
import {toast} from "react-toastify";
import {getMobileOperatingSystem, getOrderStatusBadge, roundNumber} from "../utils"
import Modal from "react-modal";
import SmallLoadingComp from "../../components/loaders/SmallLoadingComp";
import QrReader from "react-qr-reader";
import ReactToPrint from "react-to-print";
import {PvPrint} from "../prints/pvPrint";
import EditDriver from "./EditDriver";
import EditAddress from "./EditAddress";

export default function OrderModalPickup({currentOrder, showModal, setShowModal, onFinish}) {

    const token = localStorage.getItem('token')
    const [currentCarpets, setCurrentCarpets] = useState([])
    const [showModalDriver, setShowModalDriver] = useState(false)
    const [showModalAddress, setShowModalAddress] = useState(false)

    const [carpetsLoading, setCarpetsLoading] = useState(false)

    const [carpetsCodePrefix, setCarpetsCodePrefix] = useState('')
    const [carpetsCodePrefixValid, setCarpetsCodePrefixValid] = useState(true)

    const [startQrScan, setStartQrScan] = useState(false)

    const [secondModalIsOpen, setSecondModalIsOpen] = useState(false);

    const [bufferOrder, setBufferOrder] = useState(currentOrder)
    const pvPrintRef = useRef()

    const [advance, setAdvance] = useState(currentOrder.advance && currentOrder.advance > 0 ? currentOrder.advance : '')
    const [discount, setDiscount] = useState(currentOrder.discount && currentOrder.discount > 0 ? currentOrder.discount : '')

    const [company, setCompany] = useState({})

    const handleFocus = (event) => event.target.select();
    const getClient = () => {
        fetch(server + '/client/company', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            if (json) {
                setCompany(json)

            } else {
                toast.warning('A aparut o eroare!');
            }

        }).catch(e => {
            //toast.warning('A aparut o eroare!');
        });

    }

    const getNextCarpetCode = () => {
        fetch(server + '/client/orders/getNextCarpetCode', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            if (json) {
                setCarpetsPrefix(json['data'])
                setCurrentCarpets(getEmptyCarpetsArray(currentOrder.itemsCount, json['data']))

            } else {
                toast.warning('A aparut o eroare!');
            }

        }).catch(e => {
            //toast.warning('A aparut o eroare!');
        });

    }


    useEffect(() => {
        getClient()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        getNextCarpetCode()
        setAdvance(currentOrder.advance)
        setCarpetsCodePrefixValid(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentOrder])

    const pickupOrder = async () => {

        let valid = true;

        currentCarpets.forEach((carpet, i) => {
            if (carpet.code === null || carpet.code.length === 0) {
                valid = false
            }
        })

        if (!valid) {
            return;
        }

        setCarpetsLoading(true)
        fetch(server + '/client/orders/' + currentOrder._id, {
            method: 'PUT',
            body: JSON.stringify({
                status: 2,
                carpets: currentCarpets,
                advance: advance,
                discount: discount,
                saveLastOrder: true
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            if (json) {
                setBufferOrder(json)
                setSecondModalIsOpen(true)
                setShowModal(false)
                setCarpetsLoading(false)
                onFinish()
            } else {
                toast.warning('A aparut o eroare!');
            }

        }).catch(e => {
            //toast.warning('A aparut o eroare!');
        });
    }

    const setCarpetValue = (i, param, value) => {

        let newCarpetsValues = [...currentCarpets]

        newCarpetsValues[i][param] = value

        setCurrentCarpets(newCarpetsValues)

    }

    const checkCodePrefix = (carpetsCodePrefix) => {

        fetch(server + '/client/orders/checkPrefix?prefix=' + carpetsCodePrefix, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            if (json) {
                setCarpetsCodePrefixValid(json['result'])
            } else {
                toast.warning('A aparut o eroare!');
            }

        }).catch(e => {
            //toast.warning('A aparut o eroare!');
        });

    }

    const setCarpetsPrefix = (value) => {

        if (!value) {
            setCarpetsCodePrefix('')
            return;
        }

        let newCarpetsValues = [...currentCarpets]

        newCarpetsValues.forEach((carpet, i) => {
            newCarpetsValues[i]['code'] = value + '-' + i
        })

        setCurrentCarpets(newCarpetsValues)
        setCarpetsCodePrefix(value)
        checkCodePrefix(value)
    }

    const getEmptyCarpetsArray = (count, carpetsCodePrefix) => {

        let carpets = []

        for (let i = 0; i < count; i++) {
            let code = false;
            if (carpetsCodePrefix) {
                code = carpetsCodePrefix + '-' + i
            }

            carpets.push({
                code: code ? code : '',
                width: null,
                height: null
            })
        }

        return carpets

    }

    return (<>


            <Modal
                isOpen={showModal}
                onAfterOpen={() => {
                }}
                onRequestClose={() => setShowModal(false)}
                shouldCloseOnOverlayClick={false}
                style={customModalStyles}
                contentLabel="Example Modal"
            >
                <button className={"btn-close-modal"} onClick={() => setShowModal(false)}>
                    <FontAwesomeIcon icon={faTimes}/>
                </button>

                <h2>Colectare covoare</h2>
                <div style={{marginBottom: '20px'}}>
                    <button
                        className={"btn btn-primary"}
                        type={"button"}
                        onClick={() => {
                            setShowModal(false)
                            setShowModalDriver(true)
                        }}
                    >Modifică soferul
                    </button>
                    {!currentOrder.coordinates &&
                        <button
                            className={"btn btn-primary"}
                            style={{marginLeft: '20px'}}
                            type={"button"}
                            onClick={() => {
                                setShowModal(false)
                                setShowModalAddress(true)
                            }}
                        >Modifică adresa
                        </button>
                    }
                </div>


                <div className={"row"}>
                    <div className={"col"}>
                        <strong>Client</strong><br/>
                        {currentOrder.name}<br/>
                        {currentOrder.phone}
                    </div>
                    <div className={"col"}>
                        <strong>Adresa</strong><br/>
                        {currentOrder.address}
                    </div>
                    <div className={"col"}>
                        {getOrderStatusBadge(currentOrder.status)}
                    </div>
                    {
                        currentOrder.notes ?
                            <div className={"col-12 mt-2"}>
                                <strong>Mențiuni</strong><br/>
                                {currentOrder.notes}
                            </div>
                            : null
                    }
                </div>


                <div className={"row"}>
                    <div className={"col"}>
                    </div>
                    <div className={"col"}>
                        <label className={"mt-2"}>Avans
                            <input type={"number"} className="form-control" value={advance} onFocus={handleFocus}
                                   onChange={(e) => setAdvance(e.target.value)}/>
                        </label>
                        <br/>
                        <label className={"mt-2"}>Discount (%)
                            <input type={"number"} className="form-control" value={discount}
                                   min={0}
                                   step={1}
                                   onFocus={handleFocus}
                                   onChange={(e) => setDiscount(parseInt(e.target.value) < 0 ? parseInt(e.target.value) * -1 : parseInt(e.target.value))}/>
                        </label>
                    </div>
                </div>


                {
                    currentCarpets.length > 0 ?
                        <>
                            <div className={"form-group mt-4"}>
                                <strong>Etichetare</strong>
                            </div>
                            <div className={"row"}>
                                <div className={"col"}>

                                    <div className={"form-group"}>
                                        <div className={"row"}>
                                            <div className={"col-3"}>
                                                Prefix
                                            </div>
                                            <div className={"col"}>
                                                <input type={"number"} className="form-control"
                                                       value={carpetsCodePrefix}
                                                       onChange={(e) => setCarpetsPrefix(e.target.value)}
                                                       placeholder={"ex. 372"}
                                                />
                                            </div>
                                        </div>
                                        {
                                            carpetsCodePrefixValid ?
                                                null
                                                :
                                                <div className={"alert alert-danger mt-1"}>Codul este deja folosit
                                                    !</div>
                                        }
                                    </div>

                                    {
                                        currentCarpets.length > 0 && currentCarpets.map((carpet, i) => (
                                            <div className={"form-group"} key={i}>
                                                <div className={"row"}>
                                                    <div className={"col-1"}>
                                                        {i + 1}.
                                                    </div>
                                                    <div className={"col"}>
                                                        <input type={"text"} className="form-control"
                                                               value={carpet.code}
                                                               onChange={(e) => setCarpetValue(i, 'code', e.target.value)}
                                                               placeholder={"ex. 3721"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>


                                <div className={"col border-start"}>

                                    {
                                        startQrScan ?
                                            <QrReader
                                                key={"dsa"}
                                                delay={30}
                                                style={{'width': '100%'}}
                                                onError={(err) => console.log(err)}
                                                onScan={(data) => {
                                                    if (data) {
                                                        setCarpetsPrefix(data.split('-')[0])
                                                        setStartQrScan(false)
                                                    }
                                                }}
                                            />
                                            :
                                            <div
                                                className={"text-center d-flex align-items-center justify-content-center h-100"}>
                                                <button
                                                    className={"btn btn-primary"}
                                                    type={"button"}
                                                    onClick={() => setStartQrScan(true)}
                                                >Scanează cod
                                                </button>
                                            </div>
                                    }

                                </div>
                            </div>
                        </>
                        : null
                }

                {
                    currentOrder.services && currentOrder.services.length > 0 ?
                        <>
                            <div className={"form-group mt-4"}>
                                <strong>Servicii adiționale</strong>
                            </div>


                            {
                                currentOrder.services.map((service, i) => (
                                    <div className={"row"} key={i}>
                                        <div className={"col-6"}>
                                            {service.title}
                                        </div>
                                        <div className={"col-3"}>
                                            {service.quantity} x {service.price} RON
                                        </div>
                                        <div className={"col-3 text-end"}>
                                            {roundNumber(service.quantity * service.price)} RON
                                        </div>
                                    </div>
                                ))
                            }

                        </>
                        : null
                }

                 {
                        company && company.templateSms4 ?

                            <a href={
                                getMobileOperatingSystem() === 'Android' ?
                                    encodeURI("sms:" + currentOrder.phone + "?body=" + company.templateSms4.replace('[interval]', '08-20'))
                                    :
                                    encodeURI("sms:" + currentOrder.phone + "&body=" + company.templateSms4.replace('[interval]', '08-20'))
                            }>
                                <button
                                    style={{marginTop: '20px'}}
                                    type={"button"}
                                    className={"btn btn-secondary w-100"}>
                                    <FontAwesomeIcon icon={faEnvelope}/> Trimite sms ridicare
                                </button>
                            </a>
                            : null
                    }


                <button type={"button"} className={"btn btn-success w-100 mt-5"}
                        disabled={carpetsLoading}
                        onClick={pickupOrder}>
                    {
                        carpetsLoading ?
                            <SmallLoadingComp/>
                            :
                            'Salvează'
                    }
                </button>


            </Modal>

            <Modal
                isOpen={secondModalIsOpen}
                onAfterOpen={() => {
                }}
                onRequestClose={() => setSecondModalIsOpen(false)}
                shouldCloseOnOverlayClick={false}
                style={customModalStyles}
                contentLabel="Example Modal"
            >
                <button className={"btn-close-modal"} onClick={() => setSecondModalIsOpen(false)}>
                    <FontAwesomeIcon icon={faTimes}/>
                </button>

                <h2>Printare proces verbal</h2>

                <div className={"row mt-3"}>
                    <div className={"col"}>
                        <strong>Comanda</strong><br/>
                        {currentOrder.number}<br/>
                    </div>
                    <div className={"col"}>
                        <strong>Client</strong><br/>
                        {currentOrder.name}<br/>
                        {currentOrder.phone}
                    </div>
                    <div className={"col"}>
                        <strong>Adresa</strong><br/>
                        {currentOrder.address}
                    </div>
                    <div className={"col"}>
                        {getOrderStatusBadge(currentOrder.status)}
                    </div>
                </div>

                <div className={"small-modal-btns"}>
                    <div className={"row"}>
                        <div className={"col-6"}>
                            <ReactToPrint
                                trigger={() => {
                                    return <button type={"button"} className={"btn btn-success w-100 mt-5"}>
                                        <FontAwesomeIcon icon={faPrint}/> Printare PV A4
                                    </button>;
                                }}
                                content={() => pvPrintRef.current}
                            />
                        </div>
                        <div className={"col-6"}>
                            <a href={"my.bluetoothprint.scheme://https://api.totalcarpet.ro/api/print-pv/" + company._id + "/" + currentOrder._id}>
                                <button
                                    type={"button"}
                                    className={"btn btn-secondary "}>
                                    <FontAwesomeIcon icon={faPrint}/> Printare PV termic
                                </button>
                            </a>
                        </div>
                    </div>


                    <button type={"button"} className={"btn btn-primary w-100 mt-5 small-modal-close-btn"}
                            onClick={() => setSecondModalIsOpen(false)}>
                        Finalizare
                    </button>

                </div>


                <div style={{display: 'none'}}>
                    <PvPrint
                        ref={pvPrintRef}
                        order={bufferOrder}
                        company={company}
                    />
                </div>

            </Modal>
            <EditDriver
                currentOrder={currentOrder}
                modalIsOpen={showModalDriver}
                setShowModal={setShowModalDriver}
                onFinish={onFinish}
            />
            <EditAddress
                currentOrder={currentOrder}
                modalIsOpen={showModalAddress}
                setShowModal={setShowModalAddress}
                onFinish={onFinish}
            />

        </>
    );
}
