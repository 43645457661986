import React, {useEffect, useState} from "react";
import {server} from "../../const";
import {toast, ToastContainer} from "react-toastify";
import LoadingComp from "../../components/loaders/LoadingComp";
import { useParams } from "react-router-dom";
import moment from "moment";

export default function ChangeDeliveryDate() {

    const { companyId, orderId } = useParams();

    const [loading, setLoading] = useState(true)

    const [deliveryDate, setDeliveryDate] = useState('')
    const [datePickup, setDatePickup] = useState('')
    const [deliveryInterval, setDeliveryInterval] = useState('08:00 - 10:00')

    const getOrder = () => {
        fetch(server + '/api/getOrder/'+companyId+'/'+orderId, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            if (json) {
                setDeliveryDate(json.dateDelivery ? moment(new Date(json.dateDelivery)).format('YYYY-MM-DD'): '')
                setDatePickup(json.datePickup ? moment(new Date(json.datePickup)).add(1, 'days').format('YYYY-MM-DD'): '')
                setDeliveryInterval(json.deliveryInterval)
                setLoading(false)
            } else {
                toast.warning('A aparut o eroare!');
            }

        }).catch(e => {
            //toast.warning('A aparut o eroare!');
        });

    }

    useEffect(() => {
        getOrder()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const updateDeliveryDate = () => {

        fetch(server + '/api/updateOrder/'+companyId+'/'+orderId, {
            method: 'PUT',
            body: JSON.stringify({
                dateDelivery: deliveryDate,
                deliveryInterval: deliveryInterval
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            if (json) {
                toast.success('Modificarea a fost salvată!')

            } else {
                toast.warning('A aparut o eroare!');
            }

        }).catch(e => {
            //toast.warning('A aparut o eroare!');
        });
    }

    return (
        <div className={"container pb-5"}>

            {
                loading ?
                    <LoadingComp/>
                    :
                    <> <ToastContainer></ToastContainer>
                        <h4 className={"mt-5 mb-4"}>Vă rugăm alegeți data de livrare a comenzii dumneavoastră.</h4>
                        <div className={"row"}>
                            <div className={"col-12"}>
                                <label>Data livrare
                                    <input type="date" className="form-control no-width"
                                           value={deliveryDate}
                                           min={datePickup}
                                           onChange={(e) => {
                                               setDeliveryDate(e.target.value)
                                               updateDeliveryDate()
                                           }}/>
                                </label>
                            </div>
                            {
                                deliveryDate ?
                                    <div className={"col-12 mt-3"}>
                                        <label>Interval livrare
                                            <select value={deliveryInterval} className={"form-control"}
                                                    onChange={(e) => {
                                                        setDeliveryInterval(e.target.value)
                                                        updateDeliveryDate()
                                                    }}>
                                                <option value={"08:00 - 10:00"}>08:00 - 10:00</option>
                                                <option value={"10:00 - 12:00"}>10:00 - 12:00</option>
                                                <option value={"12:00 - 15:00"}>12:00 - 15:00</option>
                                                <option value={"15:00 - 18:00"}>15:00 - 18:00</option>
                                                <option value={"18:00 - 20:00"}>18:00 - 20:00</option>
                                            </select>
                                        </label>
                                    </div>
                                    : null
                            }
                        </div>
                    </>
            }


        </div>
    );
}
