import {server} from "../../const";
import {toast} from "react-toastify";
import React, {useEffect, useState} from "react";
import LoadingComp from "../../components/loaders/LoadingComp";
import SmallLoadingComp from "../../components/loaders/SmallLoadingComp";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker";
import moment from "moment/moment";


export default function CompanyEdit() {
    const token = localStorage.getItem('token')
    const [name, setName] = useState('')
    const [address, setAddress] = useState('')
    //const [client, setClient] = useState({})
    const [pvMentions, setPvMentions] = useState('')
    const [dryBars, setDryBars] = useState(1)
    const [ordersReceivedColor, setOrdersReceivedColor] = useState('#F3BAF3')
    const [activateInvoicing, setActivateInvoicing] = useState(false)
    const [pricing, setPricing] = useState([])
    const [services, setServices] = useState([])
    const [zones, setZones] = useState([])

    const [templateSms1, setTemplateSms1] = useState('Comanda dvs. a fost măsurată. Prețul este de [pret] lei.')
    const [templateSms2, setTemplateSms2] = useState('Comanda dvs. a fost este pregătită pentru livrare. Vom livra comanda în data de [data]. Dacă doriți să schimbăm data de livrare accesați link-ul următor: [link]')
    const [templateSms3, setTemplateSms3] = useState('Comanda dvs. va fi livrată astăzi în intervalul [interval]')
    const [templateSms4, setTemplateSms4] = useState('Comanda dvs. va fi ridicată astăzi în intervalul [interval]')

    const [vatRates, setVatRates] = useState([])

    const [loading, setLoading] = useState(true)
    const [btnLoading, setBtnLoading] = useState(false)

    useEffect(() => {
        getClient()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getClient = () => {
        fetch(server + '/client/company', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            if (json) {
                //setClient(json)
                setAddress(json.address)
                setName(json.name)
                setPvMentions(json.pvMentions)
                setDryBars(json.dryBars)
                setOrdersReceivedColor(json.ordersReceivedColor)
                setActivateInvoicing(!!json.activateInvoicing)
                setPricing(json.pricing ? json.pricing : [])
                setServices(json.services)
                setZones(json.zones ? json.zones : [])
                setTemplateSms1(json.templateSms1)
                setTemplateSms2(json.templateSms2)
                setTemplateSms3(json.templateSms3)
                if (json.templateSms4)
                    setTemplateSms4(json.templateSms4)
                setVatRates(json.vatRates ? json.vatRates : [])
                setLoading(false)

                localStorage.setItem('color_order_source_1', json.ordersReceivedColor)
                localStorage.setItem('activateInvoicing', !!json.activateInvoicing)
            } else {
                toast.warning('A aparut o eroare!');
            }

        }).catch(e => {
            //toast.warning('A aparut o eroare!');
        });

    }

    const editClient = (e) => {

        e.preventDefault()

        setBtnLoading(true)

        fetch(server + '/client/company', {
            method: 'POST',
            body: JSON.stringify({
                'name': name,
                'address': address,
                'pvMentions': pvMentions,
                'dryBars': dryBars,
                'ordersReceivedColor': ordersReceivedColor,
                'activateInvoicing': activateInvoicing,
                'pricing': pricing,
                'services': services,
                'zones': zones,
                'templateSms1': templateSms1,
                'templateSms2': templateSms2,
                'templateSms3': templateSms3,
                'templateSms4': templateSms4,
                'vatRates': vatRates
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            if (json) {
                getClient()
            } else {
                toast.warning('A aparut o eroare!');
            }
            setBtnLoading(false)
        }).catch(e => {
            setBtnLoading(false)
            toast.warning('A aparut o eroare!');
        });

    }

    const addService = () => {

        let newServices = [...services]

        newServices.push({
            'title': '',
            'price': 0
        })

        setServices(newServices)

    }

    const updateService = (index, param, value) => {

        let newServices = [...services]
        newServices[index][param] = value

        setServices(newServices)
    }

    const deleteService = (index) => {

        let newServices = [...services]
        delete newServices[index]

        newServices = newServices.filter(function (item) {
            return item !== undefined;
        });

        setServices(newServices)

    }

    const addZone = () => {

        let newZones = [...zones]
        let newId = 1
        if (newZones && newZones.length > 0) {
            newId = newZones[newZones.length - 1].id + 1
        }

        newZones.push({
            'id': newId,
            'title': '',
            'start': '',
            'end': ''
        })

        setZones(newZones)

    }

    const updateZone = (index, param, value) => {

        let newZones = [...zones]
        newZones[index][param] = value

        setZones(newZones)
    }

    const deleteZone = (index) => {

        let newZones = [...zones]
        delete newZones[index]

        newZones = newZones.filter(function (item) {
            return item !== undefined;
        });

        setZones(newZones)

    }

    const addVatRate = () => {

        let newVatRates = [...vatRates]

        newVatRates.push({
            'rate': 0,
        })

        setVatRates(newVatRates)
    }

    const updateVatRate = (index, param, value) => {

        let newVatRates = [...vatRates]
        newVatRates[index][param] = value

        setVatRates(newVatRates)
    }

    const deleteVatRate = (index) => {

        let newVatRates = [...vatRates]
        delete newVatRates[index]

        newVatRates = newVatRates.filter(function (item) {
            return item !== undefined;
        });

        setVatRates(newVatRates)

    }

    const addPricing = () => {

        let newPricing = [...pricing]

        newPricing.push({
            'title': '',
            'price': 0,
            'default': false
        })

        setPricing(newPricing)

    }

    const updatePricing = (index, param, value) => {

        let newPricing = [...pricing]
        newPricing[index][param] = value

        setPricing(newPricing)
    }

    const deletePricing = (index) => {

        let newPricing = [...pricing]
        delete newPricing[index]

        newPricing = newPricing.filter(function (item) {
            return item !== undefined;
        });

        setPricing(newPricing)

    }

    return (
        <div className={"container"}>

            {loading ?
                <LoadingComp/>
                :
                <>
                    <h2 className={"mt-5"}>Companie</h2>
                    <form onSubmit={(e) => editClient(e)}>
                        <div className={"row mt-5"}>
                            <div className={"col-12 col-md-6"}>
                                <div className={"form-group"}>
                                    <label>Denumire
                                        <input type="text" name="name" id="inputName" className="form-control"
                                               placeholder="Name" value={name}
                                               onChange={(e) => setName(e.target.value)} required/>
                                    </label>
                                </div>
                                <div className={"form-group"}>
                                    <label>Adresa
                                        <input type="text" name="address" id="inputAddress" className="form-control"
                                               value={address}
                                               onChange={(e) => setAddress(e.target.value)} placeholder="Adresa"
                                               required/>
                                    </label>
                                </div>
                                <div className={"form-group"}>
                                    <label>Mențiuni subsol proces verbal
                                        <textarea name="pvMentions" className="form-control"
                                                  onChange={(e) => setPvMentions(e.target.value)} placeholder="Mențiuni"
                                                  value={pvMentions}/>
                                    </label>
                                </div>

                                <div className={"form-group"}>
                                    <label>Număr bare uscare
                                        <input type="number" className="form-control" value={dryBars}
                                               onChange={(e) => setDryBars(e.target.value)}
                                               placeholder="Număr bare uscare"
                                               required/>
                                    </label>
                                </div>


                                <div className={"form-group"}>
                                    <label>Culoare evidențiere comenzi primite la sediu<br/>
                                        <input type="color" value={ordersReceivedColor}
                                               onChange={(e) => setOrdersReceivedColor(e.target.value)}
                                               placeholder="Culoare evidențiere comenzi primite la sediu"
                                               required/>
                                    </label>
                                </div>

                                <div className={"form-group"}>
                                    <label>
                                        <input type={"checkbox"} checked={activateInvoicing}
                                               onChange={() => setActivateInvoicing(!activateInvoicing)}/> Activare
                                        modul de facturare</label>
                                </div>

                                <br/>
                            </div>
                            <div className={"col-12 col-md-6"}>

                                <strong>Categorii de prețuri spălare</strong>
                                <div className={"row"}>
                                    {
                                        pricing && pricing.length > 0 ? pricing.map((pricingItem, i) => (
                                                <div className={"col-12 pb-1 pt-3"} key={i}>
                                                    <div className={"row"}>
                                                        <div className={"col-1"}>
                                                            <input type={"checkbox"}
                                                                   checked={pricingItem.default}
                                                                   onChange={() => {
                                                                       pricing.forEach((pricingItemSecond, j) => {
                                                                           updatePricing(j, 'default', false)
                                                                       })
                                                                       updatePricing(i, 'default', !pricingItem.default)
                                                                   }}
                                                            />
                                                        </div>

                                                        <div className={"col-5"}>
                                                            <input type={"text"} className={"form-control"}
                                                                   value={pricingItem.title}
                                                                   onChange={(e) => updatePricing(i, 'title', e.target.value)}
                                                                   placeholder={"Denumire categorie"}
                                                            />
                                                        </div>

                                                        <div className={"col-3"}>
                                                            <input type={"number"} className={"form-control"}
                                                                   value={pricingItem.price}
                                                                   onChange={(e) => updatePricing(i, 'price', parseInt(e.target.value))}
                                                                   placeholder={"Preț/mp"}
                                                            />
                                                        </div>

                                                        <div className={"col-3"}>
                                                            <button type={"button"} className={"btn btn-danger"}
                                                                    onClick={() => deletePricing(i)}><FontAwesomeIcon
                                                                icon={faTimes}/></button>
                                                        </div>

                                                    </div>
                                                </div>
                                            ))
                                            :
                                            <div className={"col-12 text-center py-5"}>
                                                <i>Nu ați adăugat servicii adiționale</i>
                                            </div>
                                    }
                                </div>
                                <div className={"btn btn-info mt-3"} onClick={() => addPricing()}>+ Adaugă categorie
                                </div>

                                <br/><br/>

                                <strong>Servicii adiționale</strong>
                                <div className={"row"}>
                                    {
                                        services && services.length > 0 ? services.map((service, i) => (
                                                <div className={"col-12 pb-1 pt-3"} key={i}>
                                                    <div className={"row"}>

                                                        <div className={"col-6"}>
                                                            <input type={"text"} className={"form-control"}
                                                                   value={services[i].title}
                                                                   onChange={(e) => updateService(i, 'title', e.target.value)}
                                                                   placeholder={"Denumire serviciu"}
                                                            />
                                                        </div>

                                                        <div className={"col-3"}>
                                                            <input type={"text"} className={"form-control"}
                                                                   value={services[i].price}
                                                                   onChange={(e) => updateService(i, 'price', e.target.value)}
                                                                   placeholder={"Preț serviciu"}
                                                            />
                                                        </div>

                                                        <div className={"col-3"}>
                                                            <button type={"button"} className={"btn btn-danger"}
                                                                    onClick={() => deleteService(i)}><FontAwesomeIcon
                                                                icon={faTimes}/></button>
                                                        </div>

                                                    </div>
                                                </div>
                                            ))
                                            :
                                            <div className={"col-12 text-center py-5"}>
                                                <i>Nu ați adăugat servicii adiționale</i>
                                            </div>
                                    }
                                </div>
                                <div className={"btn btn-info mt-3"} onClick={() => addService()}>+ Adaugă serviciu
                                </div>

                                <div className={"row mt-5"}>
                                    <div className={"col-12"}>
                                        <label> Șablon SMS comandă măsurată</label>
                                        <textarea className={"form-control"}
                                                  onChange={(e) => setTemplateSms1(e.target.value)}
                                                  value={templateSms1}/>

                                    </div>
                                    <div className={"col-12"}>
                                        <label> Șablon SMS comandă gata de livrare</label>
                                        <textarea className={"form-control"}
                                                  onChange={(e) => setTemplateSms2(e.target.value)}
                                                  value={templateSms2}/>
                                    </div>
                                    <div className={"col-12"}>
                                        <label> Șablon SMS livrare</label>
                                        <textarea className={"form-control"}
                                                  onChange={(e) => setTemplateSms3(e.target.value)}
                                                  value={templateSms3}/>
                                        <br/>
                                    </div>
                                    <div className={"col-12"}>
                                        <label> Șablon SMS ridicare</label>
                                        <textarea className={"form-control"}
                                                  onChange={(e) => setTemplateSms4(e.target.value)}
                                                  value={templateSms4}/>
                                        <br/>
                                    </div>
                                </div>
                            </div>

                            <div className={"col-12 col-md-6"}>

                                <strong>Cote de TVA</strong>
                                <div className={"row"}>
                                    {
                                        vatRates && vatRates.length > 0 ? vatRates.map((vatRate, i) => (
                                                <div className={"col-12 pb-1 pt-3"} key={i}>
                                                    <div className={"row"}>

                                                        <div className={"col-9"}>
                                                            <input type={"number"} className={"form-control"}
                                                                   min={0}
                                                                   step={1}
                                                                   max={100}
                                                                   value={vatRate.rate}
                                                                   onChange={(e) => updateVatRate(i, 'rate', parseInt(e.target.value))}
                                                                   placeholder={"Cotă de tva"}
                                                            />
                                                        </div>

                                                        <div className={"col-3"}>
                                                            <button type={"button"} className={"btn btn-danger"}
                                                                    onClick={() => deleteVatRate(i)}><FontAwesomeIcon
                                                                icon={faTimes}/></button>
                                                        </div>

                                                    </div>
                                                </div>
                                            ))
                                            :
                                            <div className={"col-12 text-center py-5"}>
                                                <i>Nu ați adăugat scote de tva</i>
                                            </div>
                                    }
                                </div>
                                <div className={"btn btn-info mt-3"} onClick={() => addVatRate()}>+ Adaugă cotă de tva
                                </div>
                            </div>

                            <div className={"col-12 col-md-6"}>
                                <strong>Zone de livrare și intervale de ridicare</strong>
                                <div className={"row"}>
                                    {
                                        zones && zones.length > 0 ? zones.map((zone, i) => (
                                                <div className={"col-12 pb-1 pt-3"} key={i}>
                                                    <div className={"row"}>
                                                        <div className={"col-2"}>
                                                            <input type={"number"} className={"form-control"}
                                                                   value={zones[i].id}
                                                                   onChange={(e) => updateZone(i, 'id', parseInt(e.target.value))}
                                                                   placeholder={"Id"}
                                                                   min={1}
                                                                   step={1}
                                                            />
                                                        </div>
                                                        <div className={"col-5"}>
                                                            <input type={"text"} className={"form-control"}
                                                                   value={zones[i].title}
                                                                   onChange={(e) => updateZone(i, 'title', e.target.value)}
                                                                   placeholder={"Denumire zonă"}
                                                            />
                                                        </div>

                                                        <div className={"col-2"}>
                                                            <DatePicker
                                                                className={"form-control"}
                                                                selected={zones[i].start ? new Date(zones[i].start) : new Date()}
                                                                onChange={(date) => {
                                                                    updateZone(i, 'start', moment(date).toISOString())
                                                                }}
                                                                dateFormat={"HH:mm"}
                                                                timeFormat={"HH:mm"}
                                                                showTimeSelect
                                                                showTimeSelectOnly
                                                                timeIntervals={10}
                                                            />
                                                        </div>
                                                        <div className={"col-2"}>
                                                            <DatePicker
                                                                className={"form-control"}
                                                                selected={zones[i].end ? new Date(zones[i].end) : new Date()}
                                                                onChange={(date) => {
                                                                    updateZone(i, 'end', moment(date).toISOString())
                                                                }}
                                                                dateFormat={"HH:mm"}
                                                                timeFormat={"HH:mm"}
                                                                showTimeSelect
                                                                showTimeSelectOnly
                                                                timeIntervals={10}
                                                            />
                                                        </div>

                                                        <div className={"col-1"}>
                                                            <button type={"button"} className={"btn btn-danger"}
                                                                    onClick={() => deleteZone(i)}><FontAwesomeIcon
                                                                icon={faTimes}/></button>
                                                        </div>

                                                    </div>
                                                </div>
                                            ))
                                            :
                                            <div className={"col-12 text-center py-5"}>
                                                <i>Nu ați adăugat zone</i>
                                            </div>
                                    }
                                </div>
                                <div className={"btn btn-info mt-3"} onClick={() => addZone()}>+ Adaugă zonă</div>
                                <div className={"alert alert-warning mt-3"}>Comenzile deja plasate vor ține cont de
                                    numărul zonei!
                                </div>

                            </div>
                        </div>


                        <button className="btn btn-lg btn-primary mt-4 btn-block mb-5 float-end" type="submit"
                                disabled={btnLoading}>

                            {
                                btnLoading ?
                                    <SmallLoadingComp/>
                                    :
                                    'Salvare'
                            }
                        </button>
                    </form>
                </>
            }

        </div>
    );
}
