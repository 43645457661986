import React, {useState} from "react";
import {customModalStyles, server} from "../../../const"
import Modal from "react-modal"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBarcode, faSync, faTimes} from '@fortawesome/free-solid-svg-icons'
import QrReader from 'react-qr-reader'
import {toast} from "react-toastify";
import {getOrderStatusBadge} from "../../utils";
import SmallLoadingComp from "../../loaders/SmallLoadingComp";

export default function QrScanner() {
    const token = localStorage.getItem('token')

    const [qrResult, setQrResult] = useState('')
    const [modalBtnLoading, setModalBtnLoading] = useState(false)

    const previewStyle = {
        margin: 'auto',
        width: 320,
    }
    const [currentOrder, setCurrentOrder] = useState(false)

    const [modalIsOpen, setIsOpen] = useState(false);

    const getOrder = (code) => {
        fetch(server + '/client/orders/byCode/' + code, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            if (json) {
                setCurrentOrder(json)
            } else {
                toast.warning('A aparut o eroare!');
            }

        }).catch(e => {
            //toast.warning('A aparut o eroare!');
        });
    }

    const changeStatus = (statusVal) => {

        setModalBtnLoading(true)
        fetch(server + '/client/orders/' + currentOrder._id, {
            method: 'PUT',
            body: JSON.stringify({
                status: statusVal,
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            if (json) {
                setCurrentOrder(false)
                setModalBtnLoading(false)
            } else {
                toast.warning('A aparut o eroare!');
            }

        }).catch(e => {
            //toast.warning('A aparut o eroare!');
        });
    }

    return (
        <div>
                <div className={"dashboard-big-btn-cont  bg-info"}  onClick={() => {
                    setCurrentOrder(false)
                    setQrResult('')
                    setIsOpen(true)
                }}>
                    <div className={"dashboard-big-btn-cont-icon"}>
                        <FontAwesomeIcon icon={faBarcode}/>
                    </div>
                    <div className={"dashboard-big-btn-cont-title"}>Scanare QR</div>
                </div>


            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={() => {
                }}
                onRequestClose={() => setIsOpen(false)}
                shouldCloseOnOverlayClick={false}
                style={customModalStyles}
                contentLabel="Example Modal"
            >
                <button className={"btn-close-modal"} onClick={() => setIsOpen(false)}>
                    <FontAwesomeIcon icon={faTimes}/>
                </button>

                <h2>Scanare cod</h2>
                {
                    currentOrder ?
                        null :
                        <div className={"row"}>
                            <div className={"col text-center"}>
                                <QrReader
                                    key={"dsa"}
                                    delay={100}
                                    style={previewStyle}
                                    onError={(err) => console.log(err)}
                                    onScan={(data) => {
                                        if (data && data !== qrResult) {
                                            setQrResult(data)
                                            getOrder(data)
                                        }
                                    }}
                                />
                            </div>
                        </div>
                }

                {
                    currentOrder ?
                        <>
                            <div className={"row border-bottom mt-2 mb-4 pb-1"}>
                                <div className={"col text-center"}>
                                    <div className={"btn btn-default"} onClick={() => {
                                        setCurrentOrder(false)
                                        setQrResult('')
                                    }}><FontAwesomeIcon icon={faSync}/> Scanează altă comandă
                                    </div>
                                </div>
                            </div>

                            <div className={"row"}>
                                <div className={"col"}>
                                    <strong>Comanda</strong><br/>
                                    {currentOrder.number}<br/>
                                </div>
                                <div className={"col"}>
                                    <strong>Status</strong><br/>
                                    {getOrderStatusBadge(currentOrder.status)}<br/>
                                </div>
                                <div className={"col"}>
                                    <strong>Client</strong><br/>
                                    {currentOrder.name}<br/>
                                    {currentOrder.phone}
                                </div>
                                <div className={"col"}>
                                    <strong>Adresa</strong><br/>
                                    {currentOrder.address}
                                </div>
                            </div>

                            {
                                currentOrder.status >= 3 ?
                                    <>
                                        <div className={"form-group mt-4"}>
                                            <strong>Covoare</strong>
                                        </div>

                                        <div className={"row border-bottom mb-3 pb-1"}>
                                            <div className={"col-1"}>
                                                #
                                            </div>
                                            <div className={"col"}>
                                                Cod
                                            </div>
                                            <div className={"col"}>
                                                Lungime
                                            </div>
                                            <div className={"col"}>
                                                Lățime
                                            </div>
                                        </div>
                                        {
                                            currentOrder && currentOrder.carpets && currentOrder.carpets.length > 0 && currentOrder.carpets.map((carpet, i) => (
                                                <div className={"row mb-1"} key={i}>
                                                    <div className={"col-1"}>
                                                        {i + 1}.
                                                    </div>
                                                    <div className={"col"}>
                                                        {carpet.code}
                                                    </div>
                                                    <div className={"col"}>
                                                        {carpet.width}
                                                    </div>
                                                    <div className={"col"}>
                                                        {carpet.height}
                                                    </div>
                                                </div>
                                            ))
                                        }

                                        <div className={"form-group mt-4"}>
                                            <strong>Preț</strong>
                                        </div>

                                        <div className={"row"}>
                                            <div className={"col-6"}>
                                                Total măsurători
                                            </div>
                                            <div className={"col-6 text-end"}>
                                                {currentOrder.total - currentOrder.transport} RON
                                            </div>
                                        </div>

                                        <div className={"row mb-2 mt-1"}>
                                            <div className={"col-6"}>
                                                Transport
                                            </div>
                                            <div className={"col-6 text-end"}>
                                                {currentOrder.transport} RON
                                            </div>
                                        </div>

                                        <div className={"row border-top pt-2"}>
                                            <div className={"col-6"}>
                                                <strong>Total</strong>
                                            </div>
                                            <div className={"col-6 text-end"}>
                                                {currentOrder.total + currentOrder.transport} RON
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <div className={"alert alert-warning mt-3"}>Covoarele nu au fost măsurate!</div>
                            }

                            {
                                currentOrder.status >= 3 ?
                                    <>
                                        <button type={"button"} className={"btn btn-primary w-100 mt-3"}
                                                disabled={modalBtnLoading}
                                                onClick={() => changeStatus(4)}>
                                            {
                                                modalBtnLoading ?
                                                    <SmallLoadingComp/>
                                                    :
                                                    'Mută la uscat'
                                            }
                                        </button>
                                        <button type={"button"} className={"btn btn-warning w-100 mt-3"}
                                                disabled={modalBtnLoading}
                                                onClick={() => changeStatus(5)}>
                                            {
                                                modalBtnLoading ?
                                                    <SmallLoadingComp/>
                                                    :
                                                    'Mută la de livrat/ridicat'
                                            }
                                        </button>
                                        <button type={"button"} className={"btn btn-info w-100 mt-3"}
                                                disabled={modalBtnLoading}
                                                onClick={() => changeStatus(6)}>
                                            {
                                                modalBtnLoading ?
                                                    <SmallLoadingComp/>
                                                    :
                                                    'Mută la finalizate'
                                            }
                                        </button>
                                    </>
                                    : null
                            }
                        </>
                        : null
                }

            </Modal>

        </div>
    );
}
