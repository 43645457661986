

export default function ErrorParser(error) {

   if(error['status']) {

   } else {
       localStorage.clear();
       window.location.replace("/");
   }


}