import Loader from "react-loader-spinner";

export default function SmallLoadingComp() {

    return (
            <Loader
                type="Puff"
                color="#FFF"
                height={20}
                width={20}
                //timeout={3000} //3 secs
            />
    );
}