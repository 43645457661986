import {NavLink, withRouter} from "react-router-dom";
import React, {useState} from "react";
import {ToastContainer} from "react-toastify";

function HeaderClient({children}) {

    const logout = () => {
        localStorage.clear()
        window.location.href = "/"

    }


    const [menuVisible, setMenuVisible] = useState(false)

    return (
        <div>
            <nav className="navbar navbar-expand-lg navbar-dark bg-primary">

                <button className="navbar-toggler float-end" type="button" data-toggle="collapse"
                        onClick={() => setMenuVisible(!menuVisible)}
                        data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarColor01"
                     style={{display: (menuVisible ? 'block' : 'none')}}
                     onClick={() => setMenuVisible(false)}>
                    <ul className="navbar-nav me-auto">
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/" exact>Acasă</NavLink>
                        </li>

                        <li className="nav-item">
                            <NavLink className="nav-link" to="/orders-pickup" exact>Comenzi de ridicat</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/orders-measure" exact>Comenzi la măsurat</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/orders-wash" exact>Comenzi la spălat</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/orders-dry" exact>Comenzi la uscat</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/dry-room" exact>Cameră uscare</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/orders-delivery" exact>Comenzi de livrat</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/orders-completed" exact>Comenzi finalizate</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/orders-all" exact>Toate comenzile</NavLink>
                        </li>

                        {
                            localStorage.getItem('activateInvoicing') === 'true' ?
                                <>
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/orders-not-paid" exact>Comenzi
                                            neîncasate</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/drivers-balance" exact>Șoferi</NavLink>
                                    </li>
                                </>
                                : null
                        }

                        <li className="nav-item">
                            <NavLink className="nav-link" to="/expenses">Cheltuieli</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/incomes">Venituri</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/clients">Clienți</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/company">Companie</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/employees">Angajați</NavLink>
                        </li>
                    </ul>
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <div className="nav-link" onClick={() => logout()}>Ieșire</div>
                        </li>
                    </ul>
                </div>

            </nav>
            <div>
                <ToastContainer></ToastContainer>
                {children}
            </div>
        </div>
    );
}

export default withRouter(HeaderClient)


