import React, {useEffect, useState} from "react";
import {customModalStyles, expenseSubcategories, server} from "../../const"
import {toast, ToastContainer} from "react-toastify";
import LoadingComp from "../../components/loaders/LoadingComp";
import Modal from "react-modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretDown, faTimes, faTrash} from "@fortawesome/free-solid-svg-icons";
import SmallLoadingComp from "../../components/loaders/SmallLoadingComp";
import moment from "moment";
import { confirmAlert } from 'react-confirm-alert';

export default function Expenses() {


    var todayDate = new Date(), y = todayDate.getFullYear(), m = todayDate.getMonth();
    var firstDay = new Date(y, m, 1);
    var lastDay = new Date(y, m + 1, 0);

    const token = localStorage.getItem('token')
    const [expenses, setExpenses] = useState([])

    const [expenseId, setExpenseId] = useState('')

    const [filterStartDate, setFilterStartDate] = useState(moment(firstDay).format("YYYY-MM-DD"))
    const [filterEndDate, setFilterEndDate] = useState(moment(lastDay).format("YYYY-MM-DD"))


    const [date, setDate] = useState(moment().format("YYYY-MM-DD"))
    const [category, setCategory] = useState('')
    const [subcategory, setSubcategory] = useState('')
    const [amount, setAmount] = useState('')
    const [info, setInfo] = useState('')

    const [btnLoading, setBtnLoading] = useState(false)
    const [modalIsOpen, setIsOpen] = useState(false);
    const [editModalIsOpen, setEditModalIsOpen] = useState(false)
    const [employees, setEmployees] = useState([])

    const [total, setTotal] = useState(0)

    const [loading, setLoading] = useState(true)
    const [exportLoading, setExportLoading] = useState(false)

    const getEmployees = async () => {
        await fetch(server + '/client/employees', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            if (json) {
                setEmployees(json)
                setLoading(false)
            } else {
                toast.warning('A aparut o eroare!');
            }

        }).catch(e => {
            //toast.warning('A aparut o eroare!');
        });

        getExpenses()
    }
    const getExpenses = () => {
        fetch(server + '/client/expenses/list', {
            method: 'POST',
            body: JSON.stringify({
                dateStart: filterStartDate,
                dateEnd: filterEndDate
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            if (json) {

                let newExpenses = json

                let total = 0
                json.forEach((expense, i) => {

                    if(expense.userId) {
                        newExpenses[i].user = employees.find((item) => item._id === expense.userId)
                    }

                    total = total + expense.amount
                })
                setExpenses(newExpenses)
                setTotal(total)

                setLoading(false)
            } else {
                toast.warning('A aparut o eroare!');
            }

        }).catch(e => {
            //toast.warning('A aparut o eroare!');
        });
    }

    useEffect(() => {
        getEmployees()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        getExpenses()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterStartDate, filterEndDate])

    const addExpense = () => {

        if (amount <= 0 || category === '') {
            return;
        }

        setBtnLoading(true)
        fetch(server + '/client/expenses', {
            method: 'POST',
            body: JSON.stringify({
                category: category,
                subcategory: subcategory,
                date: date,
                amount: amount,
                info: info
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            if (json) {
                getExpenses()
                setCategory('')
                setSubcategory('')
                setAmount('')
                setInfo('')
                setDate(moment().format("YYYY-MM-DD"))
                setBtnLoading(false)
                setIsOpen(false)
            } else {
                //toast.warning('A aparut o eroare!');
            }

        }).catch(e => {
            //toast.warning('A aparut o eroare!');
        });
    }

    const editExpense = () => {

        if (amount <= 0 || category === '') {
            return;
        }

        setBtnLoading(true)
        fetch(server + '/client/expenses/'+expenseId, {
            method: 'PUT',
            body: JSON.stringify({
                category: category,
                subcategory: subcategory,
                date: date,
                amount: amount,
                info: info
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            if (json) {
                getExpenses()
                setCategory('')
                setSubcategory('')
                setAmount('')
                setInfo('')
                setDate(moment().format("YYYY-MM-DD"))
                setBtnLoading(false)
                setIsOpen(false)
                setEditModalIsOpen(false)
            } else {
                //toast.warning('A aparut o eroare!');
            }

        }).catch(e => {
            //toast.warning('A aparut o eroare!');
        });
    }

    const deleteExpense = (id) => {

        fetch(server + '/client/expenses/' + id, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            if (json) {
                getExpenses()
            } else {
                toast.warning('A aparut o eroare!');
            }

        }).catch(e => {
            //toast.warning('A aparut o eroare!');
        });
    }

    const exportExcel = async () => {
        setExportLoading(true)
        await fetch(server + '/client/expenses/list/export', {
            method: 'POST',
            body: JSON.stringify({
                dateStart: filterStartDate,
                dateEnd: filterEndDate
            }),
            headers: {
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'Authorization': 'Bearer ' + token,
            }})
                .then((response) => response.blob())
                .then((blob) => {
                    // Create blob link to download
                    const url = window.URL.createObjectURL(
                        new Blob([blob]),
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute(
                        'download',
                        `export-cheltuieli.xlsx`,
                    );

                    // Append to html link element page
                    document.body.appendChild(link);

                    // Start download
                    link.click();

                    // Clean up and remove the link
                    link.parentNode.removeChild(link);
                })
        setExportLoading(false)
    }


    return (
        <div className={"container"}>

            {loading ?
                <LoadingComp/>
                :
                <>

                    <div className={"float-end"}>
                        <button type={"button"} className={"btn btn-primary me-3"} onClick={() => setIsOpen(true)}>Adaugă
                            cheltuială
                        </button>
                        <button type={"button"} className={"btn btn-info"} onClick={() => exportExcel()} disabled={exportLoading}>
                            {
                                exportLoading ?
                                    <SmallLoadingComp/>:
                                    'Export excel'
                            }
                        </button>
                    </div>
                    <h2 className={"list-page-title"}>Cheltuieli</h2>

                    <div className={"expenses-input-group"}>
                        <div className={"row"}>
                            <div className="col-12 col-md-4">
                                <label>Data de început
                                    <input type="date" className="form-control"
                                           value={filterStartDate}
                                           onChange={(e) => setFilterStartDate(e.target.value)}/>
                                </label>
                            </div>
                            <div className="col-12 col-md-4">
                                <label>Data de sfârșit
                                    <input type="date" className="form-control"
                                           value={filterEndDate}
                                           onChange={(e) => setFilterEndDate(e.target.value)}/>
                                </label>
                            </div>
                            <div className="col-12 col-md-4">
                                <p>Total<br/>
                                    <span className={"h5 mt-1"}><strong>{total} RON</strong></span>
                                </p>
                            </div>
                        </div>
                    </div>


                    <div className={"row list-page-table mb-5"}>
                        <div className={"col-12"}>
                            <div className={"table-responsive"}>
                            <table className="table table-hover">
                                <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Categorie</th>
                                    <th scope="col">Valoare</th>
                                    <th scope="col">Data <FontAwesomeIcon icon={faCaretDown}/></th>
                                    <th scope="col">Mențiuni</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    expenses ? expenses.map((expense, i) => (
                                            <tr className={"tr-link"} key={i} onClick={() => {
                                                setCategory(expense.category)
                                                setSubcategory(expense.subcategory)
                                                setAmount(expense.amount)
                                                setInfo(expense.info)
                                                setDate(moment(expense.date).format("YYYY-MM-DD"))
                                                setExpenseId(expense._id)
                                                setEditModalIsOpen(true)
                                            }}>
                                                <th scope="row">{i + 1}</th>
                                                <td>{expense.category} {
                                                    expense.subcategory && expense.subcategory.length > 1 ?
                                                        <p className={"small"}>{expense.subcategory}</p>:null
                                                }</td>
                                                <td>{expense.amount} RON</td>
                                                <td>{moment(expense.date).format("DD/MM/YYYY")}</td>
                                                <td>{expense.info}
                                                    {
                                                        expense.user && expense.user.name ?
                                                            <><br/><strong>{expense.user.name}</strong></>
                                                            :null
                                                    }
                                                </td>
                                                <td>
                                                    <button type={"button"} className={"btn btn-danger"}
                                                            onClick={() => {

                                                                confirmAlert({
                                                                    title: 'Confirmare ștergere',
                                                                    message: 'Sunteți sigur că doriți să ștergeți această înregistrare?',
                                                                    buttons: [
                                                                        {
                                                                            label: 'Da',
                                                                            onClick: () => deleteExpense(expense._id)
                                                                        },
                                                                        {
                                                                            label: 'Nu',
                                                                            onClick: () => {}
                                                                        }
                                                                    ]
                                                                });
                                                            }}>
                                                        <FontAwesomeIcon icon={faTrash}/>
                                                    </button>
                                                </td>
                                            </tr>
                                        ))
                                        : null
                                }

                                </tbody>
                            </table>
                            </div>
                        </div>
                    </div>


                </>
            }

            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={() => {
                }}
                onRequestClose={() => setIsOpen(false)}
                shouldCloseOnOverlayClick={false}
                style={customModalStyles}
                contentLabel="Example Modal"
            >
                <button className={"btn-close-modal"} onClick={() => setIsOpen(false)}>
                    <FontAwesomeIcon icon={faTimes}/>
                </button>

                <h2>Adaugă cheltuială</h2>


                <div className={"row mt-4"}>
                    <div className={"col-12"}>
                        <div className="form-group">
                            <label>Data <span className={"red-tick"}>*</span>
                                <input type="date" className="form-control"
                                       value={date}
                                       onChange={(e) => setDate(e.target.value)}/>
                            </label>
                        </div>
                    </div>
                    <div className={"col-12 col-md-6"}>
                        <div className="form-group">
                            <label>Categorie <span className={"red-tick"}>*</span>
                                <select value={category} required={true}
                                        className={"form-control"}
                                        onChange={(e) => setCategory(e.target.value)}
                                >

                                    <option value={""}>Alege</option>
                                    <option value={"Salariați"}>Salariați</option>
                                    <option value={"Materii prime"}>Materii prime</option>
                                    <option value={"Utilități"}>Utilități</option>
                                    <option value={"Altele"}>Altele</option>

                                </select>
                            </label>
                        </div>
                    </div>
                    <div className={"col-12 col-md-6"}>
                        <div className="form-group">
                            <label>Subcategorie
                                <select value={subcategory}
                                        className={"form-control"}
                                        onChange={(e) => setSubcategory(e.target.value)}
                                        disabled={!category}
                                >

                                    {
                                        category ? expenseSubcategories[category].map((subcategoryItem,i) => (
                                            <option key={i} value={subcategoryItem}>{subcategoryItem}</option>
                                        ))
                                            :null
                                    }

                                </select>
                            </label>
                        </div>
                    </div>
                </div>

                <div className={"row mt-2"}>
                    <div className={"col"}>
                        <div className="form-group">
                            <label>Valoare <span className={"red-tick"}>*</span>
                                <input type="number" className="form-control"
                                       value={amount}
                                       onChange={(e) => setAmount(e.target.value)}/>
                            </label>
                        </div>
                    </div>
                </div>

                <div className={"row mt-2"}>
                    <div className={"col"}>
                        <div className="form-group">
                            <label>Mențiuni
                                <input type="text" className="form-control"
                                       value={info}
                                       onChange={(e) => setInfo(e.target.value)}/>
                            </label>
                        </div>
                    </div>
                </div>

                <button type={"submit"} className={"btn btn-success w-100 mt-5"}
                        disabled={btnLoading}
                        onClick={() => addExpense()}
                >
                    {
                        btnLoading ?
                            <SmallLoadingComp/>
                            :
                            'Adaugă cheltuială'
                    }
                </button>
                <ToastContainer/>

            </Modal>

            <Modal
                isOpen={editModalIsOpen}
                onAfterOpen={() => {
                }}
                onRequestClose={() => {
                    setEditModalIsOpen(false)
                    setCategory('')
                    setSubcategory('')
                    setAmount('')
                    setInfo('')
                    setDate(moment().format("YYYY-MM-DD"))}}
                shouldCloseOnOverlayClick={false}
                style={customModalStyles}
                contentLabel="Example Modal"
            >
                <button className={"btn-close-modal"} onClick={() => {setEditModalIsOpen(false)
                    setCategory('')
                    setAmount('')
                    setInfo('')
                    setDate(moment().format("YYYY-MM-DD"))
                }}>
                    <FontAwesomeIcon icon={faTimes}/>
                </button>

                <h2>Modificare cheltuială</h2>


                <div className={"row mt-4"}>
                    <div className={"col-12"}>
                        <div className="form-group">
                            <label>Data <span className={"red-tick"}>*</span>
                                <input type="date" className="form-control"
                                       value={date}
                                       onChange={(e) => setDate(e.target.value)}/>
                            </label>
                        </div>
                    </div>
                    <div className={"col-12 col-md-6"}>
                        <div className="form-group">
                            <label>Categorie <span className={"red-tick"}>*</span>
                                <select value={category} required={true}
                                        className={"form-control"}
                                        onChange={(e) => setCategory(e.target.value)}
                                >

                                    <option value={""}>Alege</option>
                                    <option value={"Salariați"}>Salariați</option>
                                    <option value={"Materii prime"}>Materii prime</option>
                                    <option value={"Utilități"}>Utilități</option>
                                    <option value={"Altele"}>Altele</option>

                                </select>
                            </label>
                        </div>
                    </div>
                    <div className={"col-12 col-md-6"}>
                        <div className="form-group">
                            <label>Subcategorie
                                <select value={subcategory}
                                        className={"form-control"}
                                        onChange={(e) => setSubcategory(e.target.value)}
                                        disabled={!category}
                                >

                                    {
                                        category ? expenseSubcategories[category].map((subcategoryItem,i) => (
                                                <option key={i} value={subcategoryItem}>{subcategoryItem}</option>
                                            ))
                                            :null
                                    }

                                </select>
                            </label>
                        </div>
                    </div>
                </div>

                <div className={"row mt-2"}>
                    <div className={"col"}>
                        <div className="form-group">
                            <label>Valoare <span className={"red-tick"}>*</span>
                                <input type="number" className="form-control"
                                       value={amount}
                                       onChange={(e) => setAmount(e.target.value)}/>
                            </label>
                        </div>
                    </div>
                </div>

                <div className={"row mt-2"}>
                    <div className={"col"}>
                        <div className="form-group">
                            <label>Mențiuni
                                <input type="text" className="form-control"
                                       value={info}
                                       onChange={(e) => setInfo(e.target.value)}/>
                            </label>
                        </div>
                    </div>
                </div>

                <button type={"submit"} className={"btn btn-success w-100 mt-5"}
                        disabled={btnLoading}
                        onClick={() => editExpense()}
                >
                    {
                        btnLoading ?
                            <SmallLoadingComp/>
                            :
                            'Salvează'
                    }
                </button>
                <ToastContainer/>

            </Modal>


        </div>
    );
}
