import {useEffect, useState} from "react";
import {server} from "../../const";
import {toast} from "react-toastify";
import LoadingComp from "../../components/loaders/LoadingComp";

export default function ClientsList() {
    const token = localStorage.getItem('token')
    const [clients,setClients] = useState([])

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const getClients = () => {

            fetch(server + '/admin/clients', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                }
            }).then(response => {
                return response.json()
            }).then(json => {
                if (json.clients) {
                    setLoading(false)
                    setClients(json.clients)
                }else {
                    toast.warning('A aparut o eroare!');
                }

            }).catch(e => {
                toast.warning('A aparut o eroare!');
            });

        }
        getClients()
    },[token]);


    return (
        <div className="container">

            {loading ?
                <LoadingComp/>
                :
                <>
                    <div className="row">
                        <div className="col-12">
                            <table className="table">
                                <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Nume companie</th>
                                    <th>Adresa</th>
                                    {/*preturi*/}
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    clients.map((item, i) => (
                                        <tr key={i}>
                                            <td>{item._id}</td>
                                            <td>{item.name}</td>
                                            <td>{item.email}</td>
                                            <td>{item.company.name}</td>
                                            <td>{item.company.address}</td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </>
            }
        </div>
    );
}