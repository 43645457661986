import React, {useEffect, useState} from "react";
import {customModalStyles, server} from "../../const"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCaretDown, faCaretUp, faEnvelope, faSort, faTimes} from '@fortawesome/free-solid-svg-icons'
import {toast} from "react-toastify";
import moment from "moment";
import {
    displayBadgePrefixes,
    extractCodesPrefixes,
    getMobileOperatingSystem,
    getOrderStatusBadge,
    isDatePassed, roundNumber, truncate
} from "../../components/utils"
import LoadingComp from "../../components/loaders/LoadingComp";
import Modal from "react-modal";
import OrderModal from "../../components/OrderModal/OrderModal";

export default function OrdersToday() {

    const colorOrderSource1 = localStorage.getItem('color_order_source_1')

    const token = localStorage.getItem('token')
    const [orders, setOrders] = useState([])

    const [sortParam, setSortParam] = useState('datePickup')
    const [sortOrder, setSortOrder] = useState('ascending')

    const [deliveryDate, setDeliveryDate] = useState('')
    const [deliveryInterval, setDeliveryInterval] = useState('')


    const [loading, setLoading] = useState(true)

    const [modalIsOpen, setIsOpen] = useState(false);
    const [smsModalShow, setSmsModalShow] = useState(false)
    const [currentOrder, setCurrentOrder] = useState({})

    const [company, setCompany] = useState({})

    const [search, setSearch] = useState('')

    const getClient = () => {
        fetch(server + '/client/company', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            if (json) {
                setCompany(json)
            } else {
                toast.warning('A aparut o eroare!');
            }

        }).catch(e => {
            //toast.warning('A aparut o eroare!');
        });

    }

    const getOrders = () => {
        fetch(server + '/client/orders?type=today&sortParam='+ sortParam +'&sortOrder='+ sortOrder+ (search.length > 0 ? '&search='+search : ''), {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            if (json) {
                setOrders(json['orders'])
                setLoading(false)
            } else {
                toast.warning('A aparut o eroare!');
            }

        }).catch(e => {
            //toast.warning('A aparut o eroare!');
        });
    }

    useEffect(() => {
        getClient()
        getOrders()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        getOrders()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, sortParam, sortOrder])

    useEffect(() => {
        setDeliveryDate(currentOrder.dateDelivery ? moment(new Date(currentOrder.dateDelivery)).format('YYYY-MM-DD') : '')
        setDeliveryInterval(currentOrder.deliveryInterval ? currentOrder.deliveryInterval : '08:00 - 10:00')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentOrder])

    const updateDeliveryDate = () => {

        fetch(server + '/client/orders/' + currentOrder._id, {
            method: 'PUT',
            body: JSON.stringify({
                dateDelivery: deliveryDate,
                deliveryInterval: deliveryInterval
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            getOrders()
        }).catch(e => {
            //toast.warning('A aparut o eroare!');
        });
    }

    useEffect(() => {
        if(currentOrder && currentOrder._id) {
            updateDeliveryDate()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deliveryDate, deliveryInterval])

    return (
        <div className={"container"}>

            {loading ?
                <LoadingComp/>
                :
                <>
                    <div className="float-end">
                        <input type="text"
                               className="form-control input-search"
                               placeholder="Caută comandă"
                               value={search}
                               onChange={(e) => {
                                   setSearch(e.target.value)
                               }
                               }
                        />
                    </div>
                    <h2 className={"list-page-title"}>Comenzi astăzi</h2>

                    <div className={"row list-page-table"}>
                        <div className={"col-12"}>
                            <div className={"table-responsive"}>
                            <table className="table table-hover">
                                <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col"></th>
                                    <th scope="col">Client</th>
                                    <th scope="col">Etichetă</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Telefon</th>
                                    <th scope="col">Adresa</th>
                                    <th scope="col">
                                        {
                                            sortParam === 'datePickup' ?
                                                <div className={"cursor-pointer"} onClick={() => {
                                                    setSortParam('datePickup')
                                                    if(sortOrder === 'ascending') {
                                                        setSortOrder('descending')
                                                    } else {
                                                        setSortOrder('ascending')
                                                    }
                                                }
                                                }>
                                                    <span className={"text-decoration-underline"}>Ridicare {
                                                        sortOrder === 'ascending' ?
                                                            <FontAwesomeIcon icon={faCaretUp}/>
                                                            :
                                                            <FontAwesomeIcon icon={faCaretDown}/>
                                                    }
                                                    </span>
                                                </div>
                                                :
                                                <div className={"cursor-pointer"} onClick={() => setSortParam('datePickup')}>
                                                    <span>Ridicare <FontAwesomeIcon icon={faSort}/></span>
                                                </div>
                                        }
                                    </th>
                                    <th scope="col" style={{minWidth:'85px'}}>
                                        {
                                            sortParam === 'dateDelivery' ?
                                                <div className={"cursor-pointer"} onClick={() => {
                                                    setSortParam('dateDelivery')
                                                    if(sortOrder === 'ascending') {
                                                        setSortOrder('descending')
                                                    } else {
                                                        setSortOrder('ascending')
                                                    }
                                                }
                                                }>
                                                    <span className={"text-decoration-underline"}>Livrare {
                                                        sortOrder === 'ascending' ?
                                                            <FontAwesomeIcon icon={faCaretUp}/>
                                                            :
                                                            <FontAwesomeIcon icon={faCaretDown}/>
                                                    }
                                                    </span>
                                                </div>
                                                :
                                                <div className={"cursor-pointer"} onClick={() => setSortParam('dateDelivery')}>
                                                    <span>Livrare <FontAwesomeIcon icon={faSort}/></span>
                                                </div>
                                        }
                                    </th>
                                    <th scope="col">Covoare</th>
                                    <th scope="col">Mențiuni</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    orders ? orders.map((order, i) => (
                                            <tr key={i} className={"tr-link " + (isDatePassed(order.dateDelivery) ? 'order-delivery-date-passed' : '')}
                                                style={(order.source === 1 ? {backgroundColor: colorOrderSource1} : {})}
                                            >
                                                <th scope="row">{order.number}</th>
                                                <td>
                                                        <button
                                                            onClick={() => {
                                                                setCurrentOrder(order);
                                                                setSmsModalShow(true)
                                                                setIsOpen(false)
                                                            }}
                                                            type={"button"}
                                                            className={"btn btn-secondary "}>
                                                            <FontAwesomeIcon icon={faEnvelope}/> Trimite sms
                                                        </button>
                                                </td>
                                                <td onClick={() => {setCurrentOrder(order); setIsOpen(true)}}>{order.name}</td>
                                                <td onClick={() => {setCurrentOrder(order); setIsOpen(true)}}>{displayBadgePrefixes(extractCodesPrefixes(order.carpets))}</td>
                                                <td onClick={() => {setCurrentOrder(order); setIsOpen(true)}}>{getOrderStatusBadge(order.status)}</td>
                                                <td onClick={() => {setCurrentOrder(order); setIsOpen(true)}}>{order.phone}</td>
                                                <td onClick={() => {setCurrentOrder(order); setIsOpen(true)}}>{order.address}</td>
                                                <td onClick={() => {setCurrentOrder(order); setIsOpen(true)}}>{order.datePickup ? moment(order.datePickup).format("DD/MM/YYYY HH:mm") : ''}</td>
                                                <td onClick={() => {setCurrentOrder(order); setIsOpen(true)}}>{order.dateDelivery ? moment(order.dateDelivery).format("DD/MM/YYYY") + ' ' + order.deliveryInterval : ''}</td>
                                                <td onClick={() => {setCurrentOrder(order); setIsOpen(true)}}>{order.itemsCount ? order.itemsCount : ''}</td>
                                                <td onClick={() => {setCurrentOrder(order); setIsOpen(true)}}>{truncate(order.notes, 30)}</td>
                                            </tr>
                                        ))
                                        : null
                                }

                                </tbody>
                            </table>
                            </div>
                        </div>
                    </div>

                </>
            }


            <OrderModal
                currentOrder={currentOrder}
                showModal={modalIsOpen}
                setShowModal={setIsOpen}
                onFinish={getOrders}
            />

            <Modal
                isOpen={smsModalShow}
                onAfterOpen={() => {
                }}
                onRequestClose={() => setSmsModalShow(false)}
                shouldCloseOnOverlayClick={false}
                style={customModalStyles}
                contentLabel="Example Modal"
            >
                <button className={"btn-close-modal"} onClick={() => setSmsModalShow(false)}>
                    <FontAwesomeIcon icon={faTimes}/>
                </button>

                <h2>Trimite sms</h2>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <label>Data livrare
                            <input type="date" className="form-control no-width"
                                   value={deliveryDate}
                                   onChange={(e) => setDeliveryDate(e.target.value)}/>
                        </label>
                    </div>
                    {
                        deliveryDate ?
                            <div className={"col-12"}>
                                <label>Interval livrare
                                    <select value={deliveryInterval} className={"form-control"}
                                            onChange={(e) => setDeliveryInterval(e.target.value)}>
                                        <option value={"08:00 - 10:00"}>08:00 - 10:00</option>
                                        <option value={"10:00 - 12:00"}>10:00 - 12:00</option>
                                        <option value={"12:00 - 15:00"}>12:00 - 15:00</option>
                                        <option value={"15:00 - 18:00"}>15:00 - 18:00</option>
                                        <option value={"18:00 - 20:00"}>18:00 - 20:00</option>
                                    </select>
                                </label>
                            </div>
                            : null
                    }

                    <div className={"col-12"}>
                        {
                            company && company.templateSms1 && currentOrder && currentOrder.status >= 3 ?

                                <a href={
                                    getMobileOperatingSystem() === 'Android' ?
                                        encodeURI("sms:" + currentOrder.phone + "?body=" + company.templateSms1.replace('[pret]', (roundNumber(currentOrder.total - currentOrder.advance))).replace('[nr]',(currentOrder.carpets && currentOrder.carpets[0] && currentOrder.carpets[0].code ? currentOrder.carpets[0].code.split('-')[0]:'')))
                                        :
                                        encodeURI("sms:" + currentOrder.phone + "&body=" + company.templateSms1.replace('[pret]', (roundNumber(currentOrder.total - currentOrder.advance))).replace('[nr]',(currentOrder.carpets && currentOrder.carpets[0] && currentOrder.carpets[0].code ? currentOrder.carpets[0].code.split('-')[0]:'')))
                                }>
                                    <button
                                        type={"button"}
                                        className={"btn btn-secondary w-100 mt-3"}>
                                        <FontAwesomeIcon icon={faEnvelope}/> Sms - comandă măsurată
                                    </button>
                                </a>
                                : null
                        }
                        {
                            company && company.templateSms2 && currentOrder ?

                                <a href={
                                    getMobileOperatingSystem() === 'Android' ?
                                        encodeURI("sms:" + currentOrder.phone + "?body=" + company.templateSms2.replace('[data]', deliveryDate ? moment(new Date(deliveryDate)).format("DD/MM/YYYY") + ' ' + deliveryInterval : '').replace('[link]', 'https://app.totalcarpet.ro/changeDeliveryDate/'+company._id + '/'+currentOrder._id))
                                        :
                                        encodeURI("sms:" + currentOrder.phone + "&body=" + company.templateSms2.replace('[data]', deliveryDate ? moment(new Date(deliveryDate)).format("DD/MM/YYYY") + ' ' + deliveryInterval : '').replace('[link]', 'https://app.totalcarpet.ro/changeDeliveryDate/'+company._id + '/'+currentOrder._id))
                                }>
                                    <button
                                        type={"button"}
                                        className={"btn btn-secondary w-100 mt-3"}>
                                        <FontAwesomeIcon icon={faEnvelope}/> Sms - gata de livrare
                                    </button>
                                </a>
                                : null
                        }

                        {
                            company && company.templateSms3 ?

                                <a href={
                                    getMobileOperatingSystem() === 'Android' ?
                                        encodeURI("sms:" + currentOrder.phone + "?body=" + company.templateSms3.replace('[interval]', currentOrder.deliveryInterval ? currentOrder.deliveryInterval : '08-20'))
                                        :
                                        encodeURI("sms:" + currentOrder.phone + "&body=" + company.templateSms3.replace('[interval]',  currentOrder.deliveryInterval ? currentOrder.deliveryInterval : '08-20'))
                                }>
                                    <button
                                        type={"button"}
                                        className={"btn btn-secondary w-100 mt-3"}>
                                        <FontAwesomeIcon icon={faEnvelope}/> Sms - livrăm astăzi
                                    </button>
                                </a>
                                : null
                        }
                    </div>
                </div>

            </Modal>

        </div>
    );
}
