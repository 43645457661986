import React, {useState, useEffect} from 'react'
import {Chart} from 'react-charts'
import moment from "moment";
import {server} from "../../../const";
import {toast} from "react-toastify";
import MediumLoadingComp from "../../../components/loaders/MediumLoadingComp";
import {roundNumber} from "../../../components/utils";

export default function ExpensesIncomeChart() {

    var todayDate = new Date(), y = todayDate.getFullYear(), m = todayDate.getMonth();
    var firstDay = new Date(y, m, 1);
    var lastDay = new Date(y, m + 1, 0);

    const token = localStorage.getItem('token')

    const [chartData, setChartData] = useState(false)
    const [total, setTotal] = useState(0)


    const [filterStartDate, setFilterStartDate] = useState(moment(firstDay).format("YYYY-MM-DD"))
    const [filterEndDate, setFilterEndDate] = useState(moment(lastDay).format("YYYY-MM-DD"))

    const [loading, setLoading] = useState(true)

    const getData = () => {
        fetch(server + '/client/charts/incomeExpenses', {
            method: 'POST',
            body: JSON.stringify({
                dateStart: filterStartDate,
                dateEnd: filterEndDate
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            if (json) {
                setChartData(json.data)
                setTotal(roundNumber(json.total))
                setLoading(false)
            } else {
                toast.warning('A aparut o eroare!');
            }

        }).catch(e => {
            //toast.warning('A aparut o eroare!');
        });
    }

    useEffect(() => {
        getData()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterStartDate, filterEndDate])

    const axes = React.useMemo(
        () => [
            {primary: true, type: 'ordinal', position: 'bottom'},
            {type: 'linear', position: 'left'}
        ],
        []
    )

    const series = React.useMemo(
        () => ({
            type: 'line'
        }),
        []
    )

    return (
        <>

            <div className={"expenses-input-group float-end"}>
                <div className={"row"}>
                    <div className="col">
                        <p>Total<br/>
                            <span className={"h5 mt-1"}><strong style={total > 0 ? {color:'green'} : {color: 'red'}}>{total} RON</strong></span>
                        </p>
                    </div>
                    <div className="col">
                        <label>Data de început
                            <input type="date" className="form-control"
                                   value={filterStartDate}
                                   onChange={(e) => setFilterStartDate(e.target.value)}/>
                        </label>
                    </div>
                    <div className="col">
                        <label>Data de sfârșit
                            <input type="date" className="form-control"
                                   value={filterEndDate}
                                   onChange={(e) => setFilterEndDate(e.target.value)}/>
                        </label>
                    </div>
                </div>
            </div>

            <h2>Cheltuieli/venituri</h2>

            <div
                style={{
                    width: '100%',
                    height: '300px'
                }}
            >
                {
                    loading || !chartData ?
                        <MediumLoadingComp/>
                        :
                        <Chart
                            data={chartData}
                            axes={axes}
                            series={series}
                            tooltip
                        />
                }
            </div>
        </>
    )
}
