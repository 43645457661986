import React from "react";
import './style.css'
import moment from "moment";

export class BonPrint extends React.PureComponent {

    nr = [0,1]

    render() {
        return (
            <div className={"bon-container"}>

                {
                    this.nr.map((obj,i) => (
                        <div className={"bon-item"} key={i}>

                            <div className={"row"}>
                                <div className={"col-5"}>
                                    <h4>{this.props.company.name}</h4>
                                    <br/>
                                    <strong>Adresa:</strong> {this.props.company.address}
                                </div>
                                <div className={"col-7"}>
                                    <h5>BON DE COMANDĂ</h5>
                                    <br/>

                                    <strong>Client:</strong> {this.props.order.name}<br/>
                                    <strong>Adresa:</strong> {this.props.order.address}<br/>
                                    <strong>Telefon:</strong> {this.props.order.phone}<br/>
                                    <strong>Ridicare:</strong> {moment(this.props.order.datePickup).format("DD/MM/YYYY")}<br/>
                                    <br/>
                                    <strong>Semnătură client:</strong> ...............................

                                </div>
                            </div>

                        </div>
                    ))
                }

            </div>
        );
    }
}