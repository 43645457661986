import React, {useEffect, useState} from "react";
import {customModalStyles, server} from "../../const"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCaretDown, faTimes, faEnvelope} from '@fortawesome/free-solid-svg-icons'
import {toast} from "react-toastify";
import {getCarpetPrice, getMobileOperatingSystem, getOrderStatusBadge, roundNumber} from "../utils"
import Modal from "react-modal";
import SmallLoadingComp from "../../components/loaders/SmallLoadingComp";
import DeleteOrderButton from "../DeleteOrderButton/DeleteOrderButton";

export default function OrderModalWash({currentOrder, showModal, setShowModal, onFinish}) {

    const token = localStorage.getItem('token')
    const [modalBtnLoading, setModalBtnLoading] = useState(false)

    const [showMoreActions, setShowMoreActions] = useState(false)

    const [company, setCompany] = useState({})

    const getClient = () => {
        fetch(server + '/client/company', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            if (json) {
                setCompany(json)
            } else {
                toast.warning('A aparut o eroare!');
            }

        }).catch(e => {
            //toast.warning('A aparut o eroare!');
        });

    }

    useEffect(() => {
        getClient()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const moveToDry = (statusVal = 4) => {

        setModalBtnLoading(true)
        fetch(server + '/client/orders/' + currentOrder._id, {
            method: 'PUT',
            body: JSON.stringify({
                status: statusVal,
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            if (json) {
                onFinish()
                setShowModal(false)
                setModalBtnLoading(false)
            } else {
                toast.warning('A aparut o eroare!');
            }

        }).catch(e => {
            //toast.warning('A aparut o eroare!');
        });
    }


    return (<>

            <Modal
                isOpen={showModal}
                onAfterOpen={() => {
                }}
                onRequestClose={() => setShowModal(false)}
                shouldCloseOnOverlayClick={false}
                style={customModalStyles}
                contentLabel="Example Modal"
            >
                <button className={"btn-close-modal"} onClick={() => setShowModal(false)}>
                    <FontAwesomeIcon icon={faTimes}/>
                </button>

                <h2>Spălare</h2>

                <div className={"row"}>
                    <div className={"col"}>
                        <strong>Client</strong><br/>
                        {currentOrder.name}<br/>
                        {currentOrder.phone}
                    </div>
                    <div className={"col"}>
                        <strong>Adresa</strong><br/>
                        {currentOrder.address}
                    </div>
                    <div className={"col"}>
                        {getOrderStatusBadge(currentOrder.status)}
                    </div>
                    {
                        currentOrder.notes ?
                            <div className={"col-12 mt-2"}>
                                <strong>Mențiuni</strong><br/>
                                {currentOrder.notes}
                            </div>
                            :null
                    }
                </div>

                <div className={"form-group mt-4"}>
                    <strong>Dimensiuni</strong>
                </div>

                <div className={"row border-bottom mb-3 pb-1"}>
                    <div className={"col-1"}>
                        #
                    </div>
                    <div className={"col"}>
                        Cod
                    </div>
                    <div className={"col"}>
                        Preț/mp
                    </div>
                    <div className={"col"}>
                        Lungime
                    </div>
                    <div className={"col"}>
                        Lățime
                    </div>
                </div>
                {
                    currentOrder && currentOrder.carpets && currentOrder.carpets.length > 0 && currentOrder.carpets.map((carpet, i) => (
                        <div className={"row mb-1"} key={i}>
                            <div className={"col-1"}>
                                {i + 1}.
                            </div>
                            <div className={"col"}>
                                {carpet.code}
                            </div>
                            <div className={"col"}>
                                {carpet.pricing && carpet.pricing.price ?
                                    carpet.pricing.title +' ('+carpet.pricing.price+' lei)' :

                                    getCarpetPrice(carpet, company)
                                } RON
                            </div>
                            <div className={"col"}>
                                {carpet.width}
                            </div>
                            <div className={"col"}>
                                {carpet.height}
                            </div>
                        </div>
                    ))
                }

                <div className={"form-group mt-4"}>
                    <strong>Preț</strong>
                </div>

                {
                    currentOrder.services && currentOrder.services.length > 0 ?
                        <>
                            <div className={"form-group mt-4"}>
                                <strong>Servicii adiționale</strong>
                            </div>


                            {
                                currentOrder.services.map((service, i) => (
                                    <div className={"row"} key={i}>
                                        <div className={"col-6"}>
                                            {service.title}
                                        </div>
                                        <div className={"col-3"}>
                                            {service.quantity} x {service.price} RON
                                        </div>
                                        <div className={"col-3 text-end"}>
                                            {roundNumber(service.quantity * service.price)} RON
                                        </div>
                                    </div>
                                ))
                            }
                            <br/>
                        </>
                        : null
                }

                <div className={"row"}>
                    <div className={"col-6"}>
                        Total măsurători
                    </div>
                    <div className={"col-6 text-end"}>
                        {roundNumber(currentOrder.total - (currentOrder.servicesPrice ? currentOrder.servicesPrice : 0) - currentOrder.transport)} RON
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-6"}>
                        Transport
                    </div>
                    <div className={"col-6 text-end"}>
                        {currentOrder.transport} RON
                    </div>
                </div>

                <div className={"row mb-2"}>
                    <div className={"col-6"}>
                        Avans
                    </div>
                    <div className={"col-6 text-end"}>
                        {roundNumber(currentOrder.advance ? -currentOrder.advance : 0)} RON
                    </div>
                </div>

                <div className={"row border-top pt-2"}>
                    <div className={"col-6"}>
                        <strong>Total</strong>
                    </div>
                    <div className={"col-6 text-end"}>
                        {roundNumber(currentOrder.total - currentOrder.advance)} RON
                    </div>
                </div>

                <button type={"button"} className={"btn btn-success w-100 mt-5"}
                        disabled={modalBtnLoading}
                        onClick={() => moveToDry()}>
                    {
                        modalBtnLoading ?
                            <SmallLoadingComp/>
                            :
                            'Mută la uscat'
                    }
                </button>


                <div className={"show-more-cont "} onClick={() => setShowMoreActions(!showMoreActions)}>
                    Vezi mai mult <FontAwesomeIcon icon={faCaretDown}/>
                </div>


                {
                    showMoreActions ?
                        <>
                            <button type={"button"} className={"btn btn-warning w-100 mt-3"}
                                    disabled={modalBtnLoading}
                                    onClick={() => moveToDry(5)}>
                                {
                                    modalBtnLoading ?
                                        <SmallLoadingComp/>
                                        :
                                        'Mută la de livrat/ridicat'
                                }
                            </button>
                            <button type={"button"} className={"btn btn-info w-100 mt-2"}
                                    disabled={modalBtnLoading}
                                    onClick={() => moveToDry(6)}>
                                {
                                    modalBtnLoading ?
                                        <SmallLoadingComp/>
                                        :
                                        'Mută la finalizate'
                                }
                            </button>

                            {
                                company && company.templateSms1 ?

                                    <a href={
                                        getMobileOperatingSystem() === 'Android' ?
                                            encodeURI("sms:" + currentOrder.phone + "?body=" + company.templateSms1.replace('[pret]', (roundNumber(currentOrder.total - currentOrder.advance))).replace('[nr]',(currentOrder.carpets[0] && currentOrder.carpets[0].code ? currentOrder.carpets[0].code.split('-')[0]:'')))
                                            :
                                            encodeURI("sms:" + currentOrder.phone + "&body=" + company.templateSms1.replace('[pret]', (roundNumber(currentOrder.total - currentOrder.advance))).replace('[nr]',(currentOrder.carpets[0] && currentOrder.carpets[0].code ? currentOrder.carpets[0].code.split('-')[0]:'')))
                                    }>
                                        <button
                                            type={"button"}
                                            className={"btn btn-secondary w-100 mt-3"}>
                                            <FontAwesomeIcon icon={faEnvelope}/> Trimite sms ( comandă măsurată )
                                        </button>
                                    </a>
                                    : null
                            }


                            <DeleteOrderButton
                                order={currentOrder}
                                afterDelete={() => {
                                    setShowModal(false)
                                    onFinish()
                                }}
                            />
                        </>
                        : null
                }


            </Modal>

        </>
    );
}
