import React, {useEffect, useState} from "react";
import {server} from "../../const"
import {toast} from "react-toastify";
import LoadingComp from "../../components/loaders/LoadingComp";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMapPin} from "@fortawesome/free-solid-svg-icons";

export default function DriversBalance() {

    const token = localStorage.getItem('token')

    const [employees, setEmployees] = useState([])
    const [loading, setLoading] = useState(true)


    const getEmployees = () => {
        fetch(server + '/client/employees?last_order=1', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            if (json) {
                setEmployees(json)
                setLoading(false)
            } else {
                toast.warning('A aparut o eroare!');
            }

        }).catch(e => {
            //toast.warning('A aparut o eroare!');
        });
    }
    useEffect(() => {

        getEmployees()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const resetBalance = async (employeeId) => {

        fetch(server + '/client/employees/' + employeeId, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify({
                balance: 0
            })
        }).then(response => {
            return response.json()
        }).then(json => {
            getEmployees()
        }).catch(e => {

        });
    }

    return (
        <div className={"container"}>

            {loading ?
                <LoadingComp/>
                :
                <>
                    <h2 className={"list-page-title"}>Șoferi</h2>

                    <div className={"row list-page-table"}>
                        <div className={"col-12"}>
                            <div className={"table-responsive"}>
                                <table className="table table-hover">
                                    <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Nume</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Sold</th>
                                        <th scope="col">Ultima comandă</th>
                                        <th scope="col"></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        employees ? employees.filter((item) => item.role === 'driver').map((employee, i) => (
                                                <tr key={i}>
                                                    <th scope="row">{i + 1}</th>
                                                    <td>{employee.name}</td>
                                                    <td>{employee.email}</td>
                                                    <td>{employee.balance} RON</td>
                                                    <td>{employee.last_order && employee.last_order.coordinates &&
                                                        <a target={"_blank"} rel={'noreferrer'}
                                                           href={"https://maps.google.com/?q=" + employee.last_order.coordinates.lat + "," + employee.last_order.coordinates.lng}>
                                                            <FontAwesomeIcon icon={faMapPin}/> Google maps
                                                        </a>}</td>
                                                    <td>
                                                        {
                                                            employee.balance > 0 ?
                                                                <button
                                                                    type={"button"}
                                                                    className={"btn btn-primary"}
                                                                    onClick={() => resetBalance(employee._id)}
                                                                >Reseteză soldul</button>
                                                                : null
                                                        }
                                                    </td>
                                                </tr>
                                            ))
                                            : null
                                    }

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </>
            }


        </div>
    );
}
