import React, {useEffect, useState} from "react";
import {customModalStyles, server} from "../../const";
import {toast} from "react-toastify";
import LoadingComp from "../loaders/LoadingComp";
import './style.css'

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Modal from "react-modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";
import QrReader from "react-qr-reader";
import OrderModal from "../OrderModal/OrderModal";
import {displayBadgePrefixes, extractCodesPrefixes} from "../utils";

export default function DryRoomComp() {

    //const colorOrderSource1 = localStorage.getItem('color_order_source_1')

    const token = localStorage.getItem('token')

    const [qrResult, setQrResult] = useState('')
    const [currentRow, setCurrentRow] = useState('')

    const previewStyle = {
        margin: 'auto',
        width: 320,
    }

    const [modalIsOpen, setIsOpen] = useState(false);
    const [rows, setRows] = useState({});
    const [selectedCarpetPrefix, setSelectedCarpetPrefix] = useState('')
    const [loading, setLoading] = useState(true)

    const [carpetsWaiting, setCarpetsWaiting] = useState([])

    const [orderModalIsOpen, setOrderModalIsOpen] = useState(false);
    const [currentOrder, setCurrentOrder] = useState({})
    const [orders, setOrders] = useState([])

    const getClient = () => {
        fetch(server + '/client/company', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            if (json) {
                if(json.bars) {
                    if(Object.values(json.bars).length !== json.dryBars) {
                        setRows(generateEmptyBars(json.dryBars))
                    } else {
                        setRows(json.bars)
                    }
                } else {
                    setRows(generateEmptyBars(json.dryBars))
                }
            } else {
                toast.warning('A aparut o eroare!');
            }
            setLoading(false)
        }).catch(e => {
            //toast.warning('A aparut o eroare!');
        });
    }

    const generateEmptyBars = (count) => {

        let newBars = {}

        for(let i=0; i<count; i++) {

            let newObject = {};
            newObject =  {
                name: ("B"+(i+1)),
                items: []
            }
            newBars[("B"+(i+1))] = newObject
        }

        return newBars
    }

    useEffect(() => {
        getClient()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {

            fetch(server + '/client/company/storeDryRoom', {
                method: 'POST',
                body: JSON.stringify({
                    bars: rows
                }),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                }
            }).then(response => {
                return response.json()
            }).then(json => {
                getOrders()
            }).catch(e => {
                toast.warning('A aparut o eroare!');
            });

        }, 1000)

        return () => clearTimeout(delayDebounceFn)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rows])

    const onDragEnd = (result, rows, setRows) => {
        if (!result.destination) return;
        const { source, destination } = result;

        if (source.droppableId !== destination.droppableId) {
            const sourceColumn = rows[source.droppableId];
            const destColumn = rows[destination.droppableId];
            const sourceItems = [...sourceColumn.items];
            const destItems = [...destColumn.items];
            const [removed] = sourceItems.splice(source.index, 1);
            destItems.splice(destination.index, 0, removed);
            setRows({
                ...rows,
                [source.droppableId]: {
                    ...sourceColumn,
                    items: sourceItems
                },
                [destination.droppableId]: {
                    ...destColumn,
                    items: destItems
                }
            });
        } else {
            const column = rows[source.droppableId];
            const copiedItems = [...column.items];
            const [removed] = copiedItems.splice(source.index, 1);
            copiedItems.splice(destination.index, 0, removed);
            setRows({
                ...rows,
                [source.droppableId]: {
                    ...column,
                    items: copiedItems
                }
            });
        }
    };

    const checkCodeAndAdd = (code, bar) => {
        fetch(server + '/client/orders/checkCarpetCode/' + code, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            if (json) {

                addCarpetToBar(bar, code)
                setIsOpen(false)
                setQrResult('')

            } else {
                toast.warning('A aparut o eroare!');
            }

        }).catch(e => {
            //toast.warning('A aparut o eroare!');
        });
    }

    const addCarpetToBar = (bar, carpetCode) => {

        let newRows = {...rows}

        removeCarpetFromWaiting(carpetCode)

        if(checkIfCarpetIsAlreadyOnBars(carpetCode)) {
            console.log('Deja exista')
        } else {
            newRows[bar]['items'].push({
                id: carpetCode,
                content: carpetCode
            })
        }

        setRows(newRows)
    }

    const checkIfCarpetIsAlreadyOnBars = (carpetCode) => {

        let exists = false

        Object.values(rows).forEach((bar,i) => {
            bar.items.forEach((item, j) => {
                if(item.id === carpetCode) {
                    exists = true;
                }
            })
        })

        return exists

    }

    const getAllOrders = () => {

        let orders = {}

        Object.values(rows).forEach((bar,i) => {
            bar['items'].forEach((item, j) => {
                let order = item.id.split('-')[0]

                if(!orders[order]) {
                    orders[order] = {
                        order: order,
                        items: []
                    }
                }

                orders[order]['items'].push({
                    code: item.id,
                    position: bar.name + '-' + (j+1)
                })
            })
        })

        return Object.values(orders)
    }

    const getOrders = () => {
        fetch(server + '/client/orders?type=dry', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            if (json) {
                setOrders(json['orders'])

                // Find all carpets that are not already on bars

                let carpetsWaitingTemp = []
                let ordersUpdated = []

                json['orders'].forEach((order, i) => {
                    order.carpets.forEach((carpet, j) => {
                        if(checkIfCarpetIsAlreadyOnBars(carpet.code)) {

                        } else {
                            carpetsWaitingTemp.push(carpet)
                        }
                    })

                    let orderTemp = order
                    orderTemp['carpetsOnBars'] = countOrdersOnBars(extractCodesPrefixes(order.carpets))
                    ordersUpdated.push(orderTemp)
                })

                setOrders(json['orders'])
                setCarpetsWaiting(carpetsWaitingTemp)

            } else {
                toast.warning('A aparut o eroare!');
            }

        }).catch(e => {
            //toast.warning('A aparut o eroare!');
        });
    }

    const countOrdersOnBars = (orderCodePrefixes) => {

        let count = 0

        Object.values(rows).forEach((bar,i) => {
            bar['items'].forEach((item, j) => {
                let order = item.id.split('-')[0]

                if(orderCodePrefixes.includes(order)) {
                    count = count + 1
                }
            })
        })

        return count
    }

    const removeCarpetFromWaiting = (carpetCode) => {

        let index = false
        let newCarpetsWaiting = [...carpetsWaiting]

        carpetsWaiting.forEach((carpet,i) => {
            if(carpet.code === carpetCode) {
                index = i
            }
        })

        if(index !== false) {

            delete carpetsWaiting[index]

            newCarpetsWaiting = newCarpetsWaiting.filter(function (item) {
                return item !== undefined;
            });

            setCarpetsWaiting(newCarpetsWaiting)
        }
    }

    return (
        <div>

            {
                loading ?
                    <LoadingComp/>
                    :
                    <div className={"mt-4"}>

                        <div style={{ display: "flex", justifyContent: "center", flexDirection: 'column', position:'relative' }}>
                            <DragDropContext
                                onDragEnd={result => onDragEnd(result, rows, setRows)}
                            >
                                {Object.entries(rows).map(([columnId, column], index) => {
                                    return (
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                //alignItems: "center",
                                                justifyContent: 'center',
                                                alignContent: 'center',
                                                paddingLeft: '5rem'
                                            }}
                                            key={columnId}
                                        >
                                            <div className={"bar-title"}>

                                                <div className={"bar-title-btn"}>
                                                    <div className={"btn btn-default"} onClick={() => {
                                                        setCurrentRow(columnId)
                                                        setQrResult('')
                                                        setIsOpen(true)
                                                    }}><FontAwesomeIcon icon={faPlus}/>
                                                    </div>
                                                </div>
                                            </div>

                                            <div style={{position:'relative', top: '1.6rem', right: '5rem'}}>Bara nr. {index+1}</div>
                                                <Droppable droppableId={columnId} key={columnId}
                                                           direction = 'horizontal'
                                                           justifyContent = 'center'
                                                           alignContent = 'center'
                                                >

                                                    {(provided, snapshot) => {
                                                        return (
                                                            <div
                                                                {...provided.droppableProps}
                                                                ref={provided.innerRef}
                                                                style={{
                                                                    background: snapshot.isDraggingOver
                                                                        ? "#f1f1f1"
                                                                        : "#f1f1f1",
                                                                    padding: 4,
                                                                    display: 'flex',
                                                                    flexDirection: 'row',
                                                                    overflowX: "auto",
                                                                    width: "100%",
                                                                    flexGrow:1,
                                                                    //justifyContent: 'space-around',
                                                                    minHeight: '2.5rem',
                                                                    alignContent : 'center',
                                                                    marginBottom:'0rem'
                                                                }}
                                                            >
                                                                {column.items.map((item, index) => {
                                                                    return (
                                                                        <Draggable
                                                                            key={item.id}
                                                                            draggableId={item.id}
                                                                            index={index}
                                                                        >
                                                                            {(provided, snapshot) => {
                                                                                return (
                                                                                    <div
                                                                                        ref={provided.innerRef}
                                                                                        {...provided.draggableProps}
                                                                                        {...provided.dragHandleProps}
                                                                                        style={{
                                                                                            userSelect: "none",
                                                                                            padding: 4,
                                                                                            height: '2rem',
                                                                                            minHeight: "2rem",
                                                                                            width: '4.5rem',
                                                                                            minWidth: "4rem",
                                                                                            marginRight: "0.5rem",
                                                                                            backgroundColor: selectedCarpetPrefix === item.id.split("-")[0]
                                                                                                ? "#dc630b"
                                                                                                : "#0d92bd",
                                                                                            color: "white",
                                                                                            border: '1px solid #ccc',
                                                                                            ...provided.draggableProps.style
                                                                                        }}
                                                                                    >
                                                                                        {item.content}
                                                                                    </div>
                                                                                );
                                                                            }}
                                                                        </Draggable>
                                                                    );
                                                                })}
                                                                {provided.placeholder}
                                                            </div>
                                                        );
                                                    }}
                                                </Droppable>

                                        </div>
                                    );
                                })}
                            </DragDropContext>
                        </div>


                        <h5 className={"mt-3"}>Etichete</h5>
                        {
                            getAllOrders().map((order,i) => (
                                <div key={i}>
                                <div className={"order-select-item " + (selectedCarpetPrefix === order.order ? 'selected' : '')}
                                     onClick={() => {
                                    setSelectedCarpetPrefix(order.order)

                                }}>
                                    {order.order}
                                </div>
                                    <div className={"order-select-item-desc"}>
                                        {
                                            order.items.map((carpet, j) => (

                                                (j>0 ? ',' : '') + ' ' + carpet.position

                                            ))
                                        }
                                    </div>

                                </div>
                            ))
                        }



                        <table className="table table-hover">
                            <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Etichetă</th>
                                <th scope="col">Covoare la uscat</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                orders ? orders.map((order, i) => (
                                        <tr key={i} className={"tr-link"}
                                            onClick={() => {
                                                setCurrentOrder(order)
                                                setOrderModalIsOpen(true)
                                            }}
                                        >
                                            <th scope="row">{order.number}</th>
                                            <td>{displayBadgePrefixes(extractCodesPrefixes(order.carpets))}</td>
                                            <td>
                                                <div className={"badge " + (order.carpetsOnBars < order.itemsCount ? 'bg-warning' : 'bg-success')}>
                                                {order.carpetsOnBars} / {order.itemsCount}
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                    : null
                            }

                            </tbody>
                        </table>

                        <Modal
                            isOpen={modalIsOpen}
                            onAfterOpen={() => {
                            }}
                            onRequestClose={() => setIsOpen(false)}
                            shouldCloseOnOverlayClick={false}
                            style={customModalStyles}
                            contentLabel="Example Modal"
                        >
                            <button className={"btn-close-modal"} onClick={() => setIsOpen(false)}>
                                <FontAwesomeIcon icon={faTimes}/>
                            </button>

                            <h2>Scanare cod</h2>

                                    <div className={"row"}>

                                        <div className={"col pt-2"}>
                                            <h6>Covoare în așteptare</h6>
                                            {
                                                carpetsWaiting.map((carpet,i) => (
                                                    <div key={i} className={"carpet-waiting-item"}
                                                         onClick={() => {
                                                             addCarpetToBar(currentRow, carpet.code)
                                                         }}>
                                                        {carpet.code}
                                                    </div>
                                                ))
                                            }
                                        </div>

                                        <div className={"col text-center"}>
                                            <QrReader
                                                key={"dsa"}
                                                delay={100}
                                                style={previewStyle}
                                                onError={(err) => console.log(err)}
                                                onScan={(data) => {
                                                    if (data && data !== qrResult) {
                                                        setQrResult(data)
                                                        checkCodeAndAdd(data, currentRow)
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>


                        </Modal>


                        <OrderModal
                            currentOrder={currentOrder}
                            showModal={orderModalIsOpen}
                            setShowModal={setOrderModalIsOpen}
                            onFinish={getClient}
                        />
                    </div>
            }
        </div>
    );
}
